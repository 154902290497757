/* magnific pop up modal */
.mfp-bg {
	&.xs-promo-popup {
		background-color: rgba(0,0,0,.87);
		padding-bottom: 100%;
		border-radius: 100%;
		overflow: hidden;
		animation: menu-animation .8s ease-out forwards;
	}
}


/* animation keyframes list */
@keyframes menu-animation {
	0% {
		opacity: 0;
		transform: scale(0.04) translateY(300%);
	}
	40% {
		transform: scale(0.04) translateY(0);
		transition: ease-out;
	}
	40% {
		transform: scale(0.04) translateY(0);
	}
	60% {
		opacity: 1;
		transform: scale(0.02) translateY(0px);
	}
	61% {
		opacity: 1;
		transform: scale(0.04) translateY(0px);
	}
	99.9% {
		opacity: 1;
		height: 0;
		padding-bottom: 100%;
		border-radius: 100%;
	}
	100% {
		opacity: 1;
		transform: scale(2) translateY(0px);
		height: 100%;
		padding-bottom: 0;
		border-radius: 0;
	}
}


/* Styles for dialog window */
.xs-promo-popup {
	.modal-content {
		background-color: transparent;
		padding: 0px;
		border: 0px;
	}
	.mfp-close {
		color: $color-white;
		opacity: 0;
		transition: all 1s ease .8s;
		transform: translateY(-500px);
	}
	&.mfp-ready {
		.mfp-close {
			opacity: 1;
			transform: translateY(0); 
		}
	}
}


/* at start */
.xs-promo-popup.my-mfp-slide-bottom .zoom-anim-dialog {
	opacity: 0;
	transition: all 1s ease .8s;
	transform: translateY(-500px);
}
/* animate in */
.xs-promo-popup.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	transform: translateY(0); 
}