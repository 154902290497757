@charset "UTF-8";
@import "variable/variable";/*----------------------------------------------------
@File: Default Styles
@Author: Md. Shahadat Hussain
@URL: https://themeforest.net/user/droitthemes

This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.
---------------------------------------------------- */
/*=====================================================================
@Template Name:
@Author: Md. Shahadat Hussain
@Developed By: Rony
@Developer URL: https://themeforest.net/user/droitthemes

@Default Styles

Table of Content:
01/ variables
02/ predefine
03/ button
04/ preloader
05/ header
06/ banner
07/ breadcrumb
08/ features
09/ service
10/ price
11/ about
12/ testimonial
13/ screenshot
14/ joblist
15/ faq
16/ portfolio
17/ contact
18/ error
19/ shop
20/ blog
21/ footer

/*====================================================*/
@import "saas/variables";
@import "saas/predefine";
@import "saas/button";
@import "saas/preloader";
//@import "saas/header";
//@import "saas/banner";
@import "saas/breadcrumb";
@import "saas/features";
@import "saas/service";
@import "saas/price";
@import "saas/about";
@import "saas/testimonial";
@import "saas/screenshot";
@import "saas/joblist";
@import "saas/faq";
@import "saas/portfolio";
@import "saas/contact";
@import "saas/error";
@import "saas/typography";
@import "saas/blog";
@import "saas/shop";
@import "saas/search";
@import "saas/split-home";
@import "saas/hosting";
@import "saas/color1";
@import "saas/color-home";
@import "saas/security";
@import "saas/footer";
