@charset "UTF-8";

@import '../variable/vars';
    /*******************************************
      Essentials File - The purpose of this file is to declare a number of potentially helpful CSS classes
      to be used in the 'className' prop of React Components or in the 'class' attribute of pure HTML elements.

      1. Display Type Helper Classes
      2. Height Helper Classes
      3. Width Helper Classes
      4. Padding Helper Classes
      5. Margin Helper Classes
      6. Text Size Helper Classes
      7. Font Weight Helper Classes
      8. Border Width Helper Classes
      9. Border Radius Helper Classes
      10. Letter Spacing Helper Classes
      11. Overlay Background Helper Classes
      12. Card Helper Classes

    *******************************************/



/*******************************************
  1. Display Type Helper Classes
*******************************************/
html, body {
  .display-none{display:none!important}
  .display-block{display:block!important}
  .display-inline{display:inline!important}
  .display-inline-block{display:inline-block!important}
  .display-flex{display:flex!important}
  .display-contents{display:contents!important}
  .display-table{display:table!important}
}


/*******************************************
  2. Height Helper Classes
*******************************************/
html, body {
  .h-0{height:$p0}
  .h-5{height:$p5}
  .h-10{height:$p10}
  .h-15{height:$p15}
  .h-20{height:$p20}
  .h-25{height:$p25}
  .h-30{height:$p30}
  .h-35{height:$p35}
  .h-40{height:$p40}
  .h-45{height:$p45}
  .h-50{height:$p50}
  .h-60{height:$p60}
  .h-70{height:$p70}
  .h-80{height:$p80}
  .h-90{height:$p90}
  .h-100{height:$p100}
  .h-150{height:$p150}
  .h-200{height:$p200}
  .h-300{height:$p300}
}


/*******************************************
  3. Width Helper Classes
*******************************************/
html, body {
  .w-0{width:$p0}
  .w-5{width:$p5}
  .w-10{width:$p10}
  .w-15{width:$p15}
  .w-20{width:$p20}
  .w-25{width:$p25}
  .w-30{width:$p30}
  .w-35{width:$p35}
  .w-40{width:$p40}
  .w-45{width:$p45}
  .w-50{width:$p50}
  .w-60{width:$p60}
  .w-70{width:$p70}
  .w-80{width:$p80}
  .w-90{width:$p90}
  .w-100{width:$p100}
  .w-150{width:$p150}
  .w-200{width:$p200}
  .w-300{width:$p300}
}


/*******************************************
  4. Padding Helper Classes
*******************************************/
html, body {
  .p-r-0{padding-right:$p0 !important}
  .p-l-0{padding-left:$p0 !important}
  .p-b-0{padding-bottom:$p0 !important}
  .p-t-0{padding-top:$p0 !important}
  .p-0{padding:$p0 !important}
  .p-r-1{padding-right:$p1 !important}
  .p-l-1{padding-left:$p1 !important}
  .p-b-1{padding-bottom:$p1 !important}
  .p-t-1{padding-top:$p1 !important}
  .p-1{padding:$p1 !important}
  .p-r-2{padding-right:$p2 !important}
  .p-l-2{padding-left:$p2 !important}
  .p-b-2{padding-bottom:$p2 !important}
  .p-t-2{padding-top:$p2 !important}
  .p-2{padding:$p2 !important}
  .p-r-3{padding-right:$p3 !important}
  .p-l-3{padding-left:$p3 !important}
  .p-b-3{padding-bottom:$p3 !important}
  .p-t-3{padding-top:$p3 !important}
  .p-3{padding:$p3 !important}
  .p-r-4{padding-right:$p4 !important}
  .p-l-4{padding-left:$p4 !important}
  .p-b-4{padding-bottom:$p4 !important}
  .p-t-4{padding-top:$p4 !important}
  .p-4{padding:$p4 !important}
  .p-r-5{padding-right:$p5 !important}
  .p-l-5{padding-left:$p5 !important}
  .p-b-5{padding-bottom:$p5 !important}
  .p-t-5{padding-top:$p5 !important}
  .p-5{padding:$p5 !important}
  .p-r-6{padding-right:$p6 !important}
  .p-l-6{padding-left:$p6 !important}
  .p-b-6{padding-bottom:$p6 !important}
  .p-t-6{padding-top:$p6 !important}
  .p-6{padding:$p6 !important}
  .p-r-8{padding-right:$p8 !important}
  .p-l-8{padding-left:$p8 !important}
  .p-b-8{padding-bottom:$p8 !important}
  .p-t-8{padding-top:$p8 !important}
  .p-8{padding:$p8 !important}
  .p-r-10{padding-right:$p10 !important}
  .p-l-10{padding-left:$p10 !important}
  .p-b-10{padding-bottom:$p10 !important}
  .p-t-10{padding-top:$p10 !important}
  .p-10{padding:$p10 !important}
  .p-r-15{padding-right:$p15 !important}
  .p-l-15{padding-left:$p15 !important}
  .p-b-15{padding-bottom:$p15 !important}
  .p-t-15{padding-top:$p15 !important}
  .p-15{padding:$p15 !important}
  .p-r-20{padding-right:$p20 !important}
  .p-l-20{padding-left:$p20 !important}
  .p-b-20{padding-bottom:$p20 !important}
  .p-t-20{padding-top:$p20 !important}
  .p-20{padding:$p20 !important}
  .p-r-25{padding-right:$p25 !important}
  .p-l-25{padding-left:$p25 !important}
  .p-b-25{padding-bottom:$p25 !important}
  .p-t-25{padding-top:$p25 !important}
  .p-25{padding:$p25 !important}
  .p-r-30{padding-right:$p30 !important}
  .p-l-30{padding-left:$p30 !important}
  .p-b-30{padding-bottom:$p30 !important}
  .p-t-30{padding-top:$p30 !important}
  .p-30{padding:$p30 !important}
  .p-r-40{padding-right:$p40 !important}
  .p-l-40{padding-left:$p40 !important}
  .p-b-40{padding-bottom:$p40 !important}
  .p-t-40{padding-top:$p40 !important}
  .p-40{padding:$p40 !important}
  .p-r-50{padding-right:$p50 !important}
  .p-l-50{padding-left:$p50 !important}
  .p-b-50{padding-bottom:$p50 !important}
  .p-t-50{padding-top:$p50 !important}
  .p-50{padding:$p50 !important}
  .p-r-60{padding-right:$p60 !important}
  .p-l-60{padding-left:$p60 !important}
  .p-b-60{padding-bottom:$p60 !important}
  .p-t-60{padding-top:$p60 !important}
  .p-60{padding:$p60 !important}
  .p-r-70{padding-right:$p70 !important}
  .p-l-70{padding-left:$p70 !important}
  .p-b-70{padding-bottom:$p70 !important}
  .p-t-70{padding-top:$p70 !important}
  .p-70{padding:$p70 !important}
  .p-r-80{padding-right:$p80 !important}
  .p-l-80{padding-left:$p80 !important}
  .p-b-80{padding-bottom:$p80 !important}
  .p-t-80{padding-top:$p80 !important}
  .p-80{padding:$p80 !important}
  .p-r-90{padding-right:$p90 !important}
  .p-l-90{padding-left:$p90 !important}
  .p-b-90{padding-bottom:$p90 !important}
  .p-t-90{padding-top:$p90 !important}
  .p-90{padding:$p90 !important}
  .p-r-100{padding-right:$p100 !important}
  .p-l-100{padding-left:$p100 !important}
  .p-b-100{padding-bottom:$p100 !important}
  .p-t-100{padding-top:$p100 !important}
  .p-100{padding:$p100 !important}
  .p-r-150{padding-right:$p150 !important}
  .p-l-150{padding-left:$p150 !important}
  .p-b-150{padding-bottom:$p150 !important}
  .p-t-150{padding-top:$p150 !important}
  .p-150{padding:$p150 !important}
  .p-r-200{padding-right:$p200 !important}
  .p-l-200{padding-left:$p200 !important}
  .p-b-200{padding-bottom:$p200 !important}
  .p-t-200{padding-top:$p200 !important}
  .p-200{padding:$p200 !important}
  .p-r-300{padding-right:$p300 !important}
  .p-l-300{padding-left:$p300 !important}
  .p-b-300{padding-bottom:$p300 !important}
  .p-t-300{padding-top:$p300 !important}
  .p-300{padding:$p300 !important}
}


/*******************************************
  5. Margin Helper Classes
*******************************************/
html, body {
  .m-r-0{margin-right:$p0 !important}
  .m-l-0{margin-left:$p0 !important}
  .m-b-0{margin-bottom:$p0 !important}
  .m-t-0{margin-top:$p0 !important}
  .m-0{margin:$p0 !important}
  .m-r-1{margin-right:$p1 !important}
  .m-l-1{margin-left:$p1 !important}
  .m-b-1{margin-bottom:$p1 !important}
  .m-t-1{margin-top:$p1 !important}
  .m-1{margin:$p1 !important}
  .m-r-2{margin-right:$p2 !important}
  .m-l-2{margin-left:$p2 !important}
  .m-b-2{margin-bottom:$p2 !important}
  .m-t-2{margin-top:$p2 !important}
  .m-2{margin:$p2 !important}
  .m-r-3{margin-right:$p3 !important}
  .m-l-3{margin-left:$p3 !important}
  .m-b-3{margin-bottom:$p3 !important}
  .m-t-3{margin-top:$p3 !important}
  .m-3{margin:$p3 !important}
  .m-r-4{margin-right:$p4 !important}
  .m-l-4{margin-left:$p4 !important}
  .m-b-4{margin-bottom:$p4 !important}
  .m-t-4{margin-top:$p4 !important}
  .m-4{margin:$p4 !important}
  .m-r-5{margin-right:$p5 !important}
  .m-l-5{margin-left:$p5 !important}
  .m-b-5{margin-bottom:$p5 !important}
  .m-t-5{margin-top:$p5 !important}
  .m-5{margin:$p5 !important}
  .m-r-6{margin-right:$p6 !important}
  .m-l-6{margin-left:$p6 !important}
  .m-b-6{margin-bottom:$p6 !important}
  .m-t-6{margin-top:$p6 !important}
  .m-6{margin:$p6 !important}
  .m-r-8{margin-right:$p8 !important}
  .m-l-8{margin-left:$p8 !important}
  .m-b-8{margin-bottom:$p8 !important}
  .m-t-8{margin-top:$p8 !important}
  .m-8{margin:$p8 !important}
  .m-r-10{margin-right:$p10 !important}
  .m-l-10{margin-left:$p10 !important}
  .m-b-10{margin-bottom:$p10 !important}
  .m-t-10{margin-top:$p10 !important}
  .m-10{margin:$p10 !important}
  .m-r-15{margin-right:$p15 !important}
  .m-l-15{margin-left:$p15 !important}
  .m-b-15{margin-bottom:$p15 !important}
  .m-t-15{margin-top:$p15 !important}
  .m-15{margin:$p15 !important}
  .m-r-20{margin-right:$p20 !important}
  .m-l-20{margin-left:$p20 !important}
  .m-b-20{margin-bottom:$p20 !important}
  .m-t-20{margin-top:$p20 !important}
  .m-20{margin:$p20 !important}
  .m-r-25{margin-right:$p25 !important}
  .m-l-25{margin-left:$p25 !important}
  .m-b-25{margin-bottom:$p25 !important}
  .m-t-25{margin-top:$p25 !important}
  .m-25{margin:$p25 !important}
  .m-r-30{margin-right:$p30 !important}
  .m-l-30{margin-left:$p30 !important}
  .m-b-30{margin-bottom:$p30 !important}
  .m-t-30{margin-top:$p30 !important}
  .m-30{margin:$p30 !important}
  .m-r-40{margin-right:$p40 !important}
  .m-l-40{margin-left:$p40 !important}
  .m-b-40{margin-bottom:$p40 !important}
  .m-t-40{margin-top:$p40 !important}
  .m-40{margin:$p40 !important}
  .m-r-50{margin-right:$p50 !important}
  .m-l-50{margin-left:$p50 !important}
  .m-b-50{margin-bottom:$p50 !important}
  .m-t-50{margin-top:$p50 !important}
  .m-50{margin:$p50 !important}
  .m-r-60{margin-right:$p60 !important}
  .m-l-60{margin-left:$p60 !important}
  .m-b-60{margin-bottom:$p60 !important}
  .m-t-60{margin-top:$p60 !important}
  .m-60{margin:$p60 !important}
  .m-r-70{margin-right:$p70 !important}
  .m-l-70{margin-left:$p70 !important}
  .m-b-70{margin-bottom:$p70 !important}
  .m-t-70{margin-top:$p70 !important}
  .m-70{margin:$p70 !important}
  .m-r-80{margin-right:$p80 !important}
  .m-l-80{margin-left:$p80 !important}
  .m-b-80{margin-bottom:$p80 !important}
  .m-t-80{margin-top:$p80 !important}
  .m-80{margin:$p80 !important}
  .m-r-90{margin-right:$p90 !important}
  .m-l-90{margin-left:$p90 !important}
  .m-b-90{margin-bottom:$p90 !important}
  .m-t-90{margin-top:$p90 !important}
  .m-90{margin:$p90 !important}
  .m-r-100{margin-right:$p100 !important}
  .m-l-100{margin-left:$p100 !important}
  .m-b-100{margin-bottom:$p100 !important}
  .m-t-100{margin-top:$p100 !important}
  .m-100{margin:$p100 !important}
  .m-r-150{margin-right:$p150 !important}
  .m-l-150{margin-left:$p150 !important}
  .m-b-150{margin-bottom:$p150 !important}
  .m-t-150{margin-top:$p150 !important}
  .m-150{margin:$p150 !important}
  .m-r-200{margin-right:$p200 !important}
  .m-l-200{margin-left:$p200 !important}
  .m-b-200{margin-bottom:$p200 !important}
  .m-t-200{margin-top:$p200 !important}
  .m-200{margin:$p200 !important}
  .m-r-300{margin-right:$p300 !important}
  .m-l-300{margin-left:$p300 !important}
  .m-b-300{margin-bottom:$p300 !important}
  .m-t-300{margin-top:$p300 !important}
  .m-300{margin:$p300 !important}
 }


/*******************************************
  6. Text Size Helper Classes
*******************************************/
html, body, a, span, div, i, p, td, th, svg, small {
  .size-6{font-size:6px!important;line-height:7px!important}
  .size-7{font-size:7px!important;line-height:8px!important}
  .size-8{font-size:8px!important;line-height:10px!important}
  .size-9{font-size:9px!important;line-height:11px!important}
  .size-10{font-size:10px!important;line-height:13px!important}
  .size-11{font-size:11px!important;line-height:14px!important}
  .size-12{font-size:12px!important;line-height:15px!important}
  .size-13{font-size:13px!important;line-height:16px!important}
  .size-14{font-size:14px!important;line-height:18px!important}
  .size-15{font-size:15px!important;line-height:19px!important}
  .size-16{font-size:16px!important;line-height:20px!important}
  .size-17{font-size:17px!important;line-height:22px!important}
  .size-18{font-size:18px!important;line-height:23px!important}
  .size-19{font-size:19px!important;line-height:24px!important}
  .size-20{font-size:20px!important;line-height:25px!important}
  .size-22{font-size:22px!important;line-height:28px!important}
  .size-24{font-size:24px!important;line-height:30px!important}
  .size-26{font-size:26px!important;line-height:32px!important}
  .size-28{font-size:28px!important;line-height:34px!important}
  .size-30{font-size:30px!important;line-height:36px!important}
  .size-35{font-size:35px!important;line-height:42px!important}
  .size-40{font-size:40px!important;line-height:47px!important}
  .size-45{font-size:45px!important;line-height:53px!important}
  .size-50{font-size:50px!important;line-height:59px!important}
  .size-60{font-size:60px!important;line-height:69px!important}
  .size-70{font-size:70px!important;line-height:80px!important}
  .size-80{font-size:80px!important;line-height:90px!important}
  .size-90{font-size:90px!important;line-height:100px!important}
  .size-100{font-size:100px!important;line-height:112px!important}
  .size-120{font-size:120px!important;line-height:134px!important}
  .size-150{font-size:150px!important;line-height:166px!important}
}


/*******************************************
  7. Font Weight Helper Classes
*******************************************/
html, body {
  .text-300,p.text-300,span.text-300,div.text-300,h1.text-300,h2.text-300,h3.text-300,h4.text-300,h5.text-300,h6.text-300,.h1.text-300,.h2.text-300,.h3.text-300,.h4.text-300,.h5.text-300,.h6.text-300,.text-thin,p.text-thin,span.text-thin,div.text-thin,h1.text-thin,h2.text-thin,h3.text-thin,h4.text-thin,h5.text-thin,h6.text-thin,.h1.text-thin,.h2.text-thin,.h3.text-thin,.h4.text-thin,.h5.text-thin,.h6.text-thin{font-weight:300!important}
  .text-normal,p.text-normal,span.text-normal,div.text-normal,h1.text-normal,h2.text-normal,h3.text-normal,h4.text-normal,h5.text-normal,h6.text-normal,.h1.text-normal,.h2.text-normal,.h3.text-normal,.h4.text-normal,.h5.text-normal,.h6.text-normal,.text-400,p.text-400,span.text-400,div.text-400,h1.text-400,h2.text-400,h3.text-400,h4.text-400,h5.text-400,h6.text-400,.h1.text-400,.h2.text-400,.h3.text-400,.h4.text-400,.h5.text-400,.h6.text-400{font-weight:400!important}
  .text-medium,p.text-medium,span.text-medium,div.text-medium,h1.text-medium,h2.text-medium,h3.text-medium,h4.text-medium,h5.text-medium,h6.text-medium,.h1.text-medium,.h2.text-medium,.h3.text-medium,.h4.text-medium,.h5.text-medium,.h6.text-medium,.text-500,p.text-500,span.text-500,div.text-500,h1.text-500,h2.text-500,h3.text-500,h4.text-500,h5.text-500,h6.text-500,.h1.text-500,.h2.text-500,.h3.text-500,.h4.text-500,.h5.text-500,.h6.text-500{font-weight:500!important}
  .text-semi-bold,p.text-semi-bold,span.text-semi-bold,div.text-semi-bold,h1.text-semi-bold,h2.text-semi-bold,h3.text-semi-bold,h4.text-semi-bold,h5.text-semi-bold,h6.text-semi-bold,.h1.text-semi-bold,.h2.text-semi-bold,.h3.text-semi-bold,.h4.text-semi-bold,.h5.text-semi-bold,.h6.text-semi-bold,.text-600,p.text-600,span.text-600,div.text-600,h1.text-600,h2.text-600,h3.text-600,h4.text-600,h5.text-600,h6.text-600,.h1.text-600,.h2.text-600,.h3.text-600,.h4.text-600,.h5.text-600,.h6.text-600{font-weight:600!important}
  .text-700,p.text-700,span.text-700,div.text-700,h1.text-700,h2.text-700,h3.text-700,h4.text-700,h5.text-700,h6.text-700,.h1.text-700,.h2.text-700,.h3.text-700,.h4.text-700,.h5.text-700,.h6.text-700,.text-bold,p.text-bold,span.text-bold,div.text-bold,h1.text-bold,h2.text-bold,h3.text-bold,h4.text-bold,h5.text-bold,h6.text-bold,.h1.text-bold,.h2.text-bold,.h3.text-bold,.h4.text-bold,.h5.text-bold,.h6.text-bold{font-weight:700!important}
  .text-800,p.text-800,span.text-800,div.text-800,h1.text-800,h2.text-800,h3.text-800,h4.text-800,h5.text-800,h6.text-800,.h1.text-800,.h2.text-800,.h3.text-800,.h4.text-800,.h5.text-800,.h6.text-800{font-weight:800!important}
  .text-900,p.text-900,span.text-900,div.text-900,h1.text-900,h2.text-900,h3.text-900,h4.text-900,h5.text-900,h6.text-900,.h1.text-900,.h2.text-900,.h3.text-900,.h4.text-900,.h5.text-900,.h6.text-900{font-weight:900!important}
}


/*******************************************
  8. Border Width Helper Classes
*******************************************/
html, body {
  .b-0{border:0!important}
  .bb-0{border-bottom:0!important}
  .bt-0{border-top:0!important}
  .bl-0{border-left:0!important}
  .br-0{border-right:0!important}
  .bw-0{border-width:0!important}
  .bw-1{border-width:1px!important}
  .bw-2{border-width:2px!important}
  .bw-3{border-width:3px!important}
  .bw-4{border-width:4px!important}
  .bw-5{border-width:5px!important}
  .bw-10{border-width:10px!important}
  .btw-0{border-top-width:0!important}
  .btw-1{border-top-width:1px!important}
  .btw-2{border-top-width:2px!important}
  .btw-3{border-top-width:3px!important}
  .btw-4{border-top-width:4px!important}
  .btw-5{border-top-width:5px!important}
  .btw-10{border-top-width:10px!important}
  .bbw-0{border-bottom-width:0!important}
  .bbw-1{border-bottom-width:1px!important}
  .bbw-2{border-bottom-width:2px!important}
  .bbw-3{border-bottom-width:3px!important}
  .bbw-4{border-bottom-width:4px!important}
  .bbw-5{border-bottom-width:5px!important}
  .bbw-10{border-bottom-width:10px!important}
  .blw-0{border-left-width:0!important}
  .blw-1{border-left-width:1px!important}
  .blw-2{border-left-width:2px!important}
  .blw-3{border-left-width:3px!important}
  .blw-4{border-left-width:4px!important}
  .blw-5{border-left-width:5px!important}
  .blw-10{border-left-width:10px!important}
  .brw-0{border-right-width:0!important}
  .brw-1{border-right-width:1px!important}
  .brw-2{border-right-width:2px!important}
  .brw-3{border-right-width:3px!important}
  .brw-4{border-right-width:4px!important}
  .brw-5{border-right-width:5px!important}
  .brw-10{border-right-width:10px!important}
}


/*******************************************
  9. Border Radius Helper Classes
*******************************************/
html, body {
  .radius-0{-webkit-border-radius:$p0;-moz-border-radius:$p0;border-radius:$p0}
  .radius-1{-webkit-border-radius:$p1;-moz-border-radius:$p1;border-radius:$p1}
  .radius-2{-webkit-border-radius:$p2;-moz-border-radius:$p2;border-radius:$p2}
  .radius-3{-webkit-border-radius:$p3;-moz-border-radius:$p3;border-radius:$p3}
  .radius-4{-webkit-border-radius:$p4;-moz-border-radius:$p4;border-radius:$p4}
  .radius-5{-webkit-border-radius:$p5;-moz-border-radius:$p5;border-radius:$p5}
  .radius-6{-webkit-border-radius:$p6;-moz-border-radius:$p6;border-radius:$p6}
  .radius-7{-webkit-border-radius:$p7;-moz-border-radius:$p7;border-radius:$p7}
  .radius-8{-webkit-border-radius:$p8;-moz-border-radius:$p8;border-radius:$p8}
  .radius-9{-webkit-border-radius:$p9;-moz-border-radius:$p9;border-radius:$p9}
  .radius-10{-webkit-border-radius:$p10;-moz-border-radius:$p10;border-radius:$p10}
  .radius-15{-webkit-border-radius:$p15;-moz-border-radius:$p15;border-radius:$p15}
  .radius-20{-webkit-border-radius:$p20;-moz-border-radius:$p20;border-radius:$p20}
  .radius-25{-webkit-border-radius:$p25;-moz-border-radius:$p25;border-radius:$p25}
  .radius-30{-webkit-border-radius:$p30;-moz-border-radius:$p30;border-radius:$p30}
  .radius-40{-webkit-border-radius:$p40;-moz-border-radius:$p40;border-radius:$p40}
  .radius-50{-webkit-border-radius:$p50;-moz-border-radius:$p50;border-radius:$p50}
}


/*******************************************
  10. Letter Spacing Helper Classes
*******************************************/
html, body {
  .letter-spacing-0{letter-spacing:0!important;word-spacing:.1em!important}
  .letter-spacing-1{letter-spacing:.1em!important;word-spacing:.2em!important}
  .letter-spacing-2{letter-spacing:.2em!important;word-spacing:.3em!important}
  .letter-spacing-3{letter-spacing:.3em!important;word-spacing:.4em!important}
  .letter-spacing-4{letter-spacing:.4em!important;word-spacing:.6em!important}
  .letter-spacing-5{letter-spacing:.5em!important;word-spacing:.7em!important}
  .letter-spacing-6{letter-spacing:.6em!important;word-spacing:.8em!important}
  .letter-spacing-7{letter-spacing:.7em!important;word-spacing:.9em!important}
  .letter-spacing-8{letter-spacing:.8em!important;word-spacing:1.1em!important}
  .letter-spacing-9{letter-spacing:.9em!important;word-spacing:1.2em!important}
  .letter-spacing-10{letter-spacing:1em!important;word-spacing:1.3em!important}
  .letter-spacing-11{letter-spacing:1.1em!important;word-spacing:1.4em!important}
  .letter-spacing-12{letter-spacing:1.2em!important;word-spacing:1.6em!important}
  .letter-spacing-13{letter-spacing:1.3em!important;word-spacing:1.7em!important}
  .letter-spacing-14{letter-spacing:1.4em!important;word-spacing:1.8em!important}
  .letter-spacing-15{letter-spacing:1.5em!important;word-spacing:2em!important}
}


/*******************************************
  11. Overlay Background Helper Classes
*******************************************/
html, body {
  .overlay{background:rgba(0,0,0,0.2);position:absolute;left:0;right:0;top:0;bottom:0;z-index:1;-webkit-transition:all 1s;-moz-transition:all 1s;-o-transition:all 1s;transition:all 1s}
  a.overlay:hover{background:rgba(255,255,255,0.2)}
  .overlay.dark-0{background-color:rgba(0,0,0,0)}
  .overlay.dark-1{background-color:rgba(0,0,0,.1)}
  .overlay.dark-2{background-color:rgba(0,0,0,.2)}
  .overlay.dark-3{background-color:rgba(0,0,0,.3)}
  .overlay.dark-4{background-color:rgba(0,0,0,.4)}
  .overlay.dark-5{background-color:rgba(0,0,0,.5)}
  .overlay.dark-6{background-color:rgba(0,0,0,.6)}
  .overlay.dark-7{background-color:rgba(0,0,0,.7)}
  .overlay.dark-8{background-color:rgba(0,0,0,.8)}
  .overlay.dark-9{background-color:rgba(0,0,0,.9)}
  .overlay.dark-10{background-color:rgba(0,0,0,1)}
  .overlay.light-0{background-color:rgba(255,255,255,0)}
  .overlay.light-1{background-color:rgba(255,255,255,.1)}
  .overlay.light-2{background-color:rgba(255,255,255,.2)}
  .overlay.light-3{background-color:rgba(255,255,255,.3)}
  .overlay.light-4{background-color:rgba(255,255,255,.4)}
  .overlay.light-5{background-color:rgba(255,255,255,.5)}
  .overlay.light-6{background-color:rgba(255,255,255,.6)}
  .overlay.light-7{background-color:rgba(255,255,255,.7)}
  .overlay.light-8{background-color:rgba(255,255,255,.8)}
  .overlay.light-9{background-color:rgba(255,255,255,.9)}
  .overlay.light-10{background-color:rgba(255,255,255,1)}

    .text-shadow {
        text-shadow: 0 0 8px rgba(30,45,60,0.16), 0 0 5px rgba(15,25,35,0.24), 1px 1px 2px rgba(0,5,10,0.32);
    }
    .text-light {
        font-weight: 300;
        font-size: 96%;
        opacity: 0.88;
    }
    .text-lighter {
        font-weight: 300;
        font-size: 92%;
        opacity: 0.72;
    }

    .text-lower, .text-lowercase {
        text-transform: lowercase !important;
    }

    .text-upper, .text-uppercase {
        text-transform: uppercase !important;
    }

    .text-capitalize {
        text-transform: capitalize !important;
    }

    .text-left {
        text-align: left !important;
    }

    .text-center {
        text-align: center !important;
    }

    .text-right {
        text-align: right !important;
    }

    .text-small {
        font-size: 95% !important;
        letter-spacing: -0.2px;
        word-spacing: -0.3px;
    }

    .text-smaller {
        font-size: 90% !important;
        letter-spacing: -0.3px;
        word-spacing: -0.6px;
    }

    .text-xsmall {
        font-size: 85% !important;
        letter-spacing: -0.4px;
        word-spacing: -0.8px;
    }

    .text-xsmaller {
        font-size: 80% !important;
        letter-spacing: -0.5px;
        word-spacing: -1px;
    }

    .text-large {
        font-size: 105% !important;
    }

    .text-larger {
        font-size: 110% !important;
    }

    .text-xlarge {
        font-size: 115% !important;
    }

    .text-xlarger {
        font-size: 120% !important;
    }

    .text-all-small-caps, .all-small-caps {
        font-variant: all-small-caps !important; // IE Fallback
        font-variant: all-petite-caps !important;
    }
    .text-small-caps, .small-caps {
        font-variant: small-caps !important; // IE Fallback
        font-variant: petite-caps !important;
    }

    h1, h2, h3, h4, h5, h6, a, p, span, div, small {
        .t-200{
            font-weight: 200 !important;
        }
        .t-300{
            font-weight: 300 !important;
        }
        .t-400{
            font-weight: 400 !important;
        }
        .t-500{
            font-weight: 500 !important;
        }
        .t-600{
            font-weight: 600 !important;
        }
        .t-700{
            font-weight: 700 !important;
        }
        .t-800{
            font-weight: 800 !important;
        }

    }
}


/*******************************************
  12. Card Helper Classes
*******************************************/
html, body {
  .card{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;background-color:#fff;border:1px solid rgba(0,0,0,0.125);border-radius:.25rem}
  .card-block{-webkit-box-flex:1;-webkit-flex:1 1 auto;-ms-flex:1 1 auto;flex:1 1 auto;padding:1.25rem}
  .card-title{margin-bottom:.75rem}
  .card-subtitle{margin-top:-.375rem;margin-bottom:0}
  .card-text:last-child{margin-bottom:0}
  .card-link:hover{text-decoration:none}
  .card-link + .card-link{margin-left:1.25rem}
  .card > .list-group:first-child .list-group-item:first-child{border-top-right-radius:.25rem;border-top-left-radius:.25rem}
  .card > .list-group:last-child .list-group-item:last-child{border-bottom-right-radius:.25rem;border-bottom-left-radius:.25rem}
  .card-header{padding:.75rem 1.25rem;margin-bottom:0;background-color:#f7f7f9;border-bottom:1px solid rgba(0,0,0,0.125)}
  .card-header:first-child{border-radius:calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0}
  .card-footer{padding:.75rem 1.25rem;background-color:#f7f7f9;border-top:1px solid rgba(0,0,0,0.125)}
  .card-footer:last-child{border-radius:0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)}
  .card-header-tabs{margin-right:-.625rem;margin-bottom:-.75rem;margin-left:-.625rem;border-bottom:0}
  .card-header-pills{margin-right:-.625rem;margin-left:-.625rem}
  .card-primary{background-color:#0275d8;border-color:#0275d8}
  .card-primary .card-header,.card-primary .card-footer{background-color:transparent}
  .card-success{background-color:#5cb85c;border-color:#5cb85c}
  .card-success .card-header,.card-success .card-footer{background-color:transparent}
  .card-info{background-color:#5bc0de;border-color:#5bc0de}
  .card-info .card-header,.card-info .card-footer{background-color:transparent}
  .card-warning{background-color:#f0ad4e;border-color:#f0ad4e}
  .card-warning .card-header,.card-warning .card-footer{background-color:transparent}
  .card-danger{background-color:#d9534f;border-color:#d9534f}
  .card-danger .card-header,.card-danger .card-footer{background-color:transparent}
  .card-outline-primary{background-color:transparent;border-color:#0275d8}
  .card-outline-secondary{background-color:transparent;border-color:#ccc}
  .card-outline-info{background-color:transparent;border-color:#5bc0de}
  .card-outline-success{background-color:transparent;border-color:#5cb85c}
  .card-outline-warning{background-color:transparent;border-color:#f0ad4e}
  .card-outline-danger{background-color:transparent;border-color:#d9534f}
  .card-inverse{color:rgba(255,255,255,0.65)}
  .card-inverse .card-header,.card-inverse .card-footer{background-color:transparent;border-color:rgba(255,255,255,0.2)}
  .card-inverse .card-header,.card-inverse .card-footer,.card-inverse .card-title,.card-inverse .card-blockquote{color:#fff}
  .card-inverse .card-link,.card-inverse .card-text,.card-inverse .card-subtitle,.card-inverse .card-blockquote .blockquote-footer{color:rgba(255,255,255,0.65)}
  .card-inverse .card-link:focus,.card-inverse .card-link:hover{color:#fff}
  .card-blockquote{padding:0;margin-bottom:0;border-left:0}
  .card-img{border-radius:calc(0.25rem - 1px)}
  .card-img-overlay{position:absolute;top:0;right:0;bottom:0;left:0;padding:1.25rem}
  .card-img-top{border-top-right-radius:calc(0.25rem - 1px);border-top-left-radius:calc(0.25rem - 1px)}
  .card-img-bottom{border-bottom-right-radius:calc(0.25rem - 1px);border-bottom-left-radius:calc(0.25rem - 1px)}
  @media (min-width: 576px) {
    .card-deck{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-flow:row wrap;-ms-flex-flow:row wrap;flex-flow:row wrap}
    .card-deck .card{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-webkit-flex:1 0 0;-ms-flex:1 0 0;flex:1 0 0;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column}
    .card-deck .card:not(:first-child){margin-left:15px}
    .card-deck .card:not(:last-child){margin-right:15px}
    .card-group{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-flow:row wrap;-ms-flex-flow:row wrap;flex-flow:row wrap}
    .card-group .card{-webkit-box-flex:1;-webkit-flex:1 0 0;-ms-flex:1 0 0;flex:1 0 0}
    .card-group .card + .card{margin-left:0;border-left:0}
    .card-group .card:first-child{border-bottom-right-radius:0;border-top-right-radius:0}
    .card-group .card:first-child .card-img-top{border-top-right-radius:0}
    .card-group .card:first-child .card-img-bottom{border-bottom-right-radius:0}
    .card-group .card:last-child{border-bottom-left-radius:0;border-top-left-radius:0}
    .card-group .card:last-child .card-img-top{border-top-left-radius:0}
    .card-group .card:last-child .card-img-bottom{border-bottom-left-radius:0}
    .card-group .card:not(:first-child):not(:last-child){border-radius:0}
    .card-group .card:not(:first-child):not(:last-child) .card-img-top,.card-group .card:not(:first-child):not(:last-child) .card-img-bottom{border-radius:0}
    .card-columns{-webkit-column-count:3;-moz-column-count:3;column-count:3;-webkit-column-gap:1.25rem;-moz-column-gap:1.25rem;column-gap:1.25rem}
    .card-columns .card{display:inline-block;width:100%;margin-bottom:.75rem}
  }
}

