.call-to-action-section + .xs-feature-section {
    margin: 0;
    margin-top: -180px;
    position: absolute;
    z-index: 1;
    padding: 0;
    width: 100%;
    height: 100%;
    max-height: 100px;
}

.xs-feature-group {
	background-color: $color-primary;
	box-shadow: 1px 5px 20px 2px rgba(0, 12, 24, 0.32);
	padding: 32px 40px 36px 24px;
	position: relative;
	overflow: hidden;
	z-index: 1;
    border-radius: 5px 5px 6px 6px;
	.xs-title,
	p {
		color: $color-white;
	}
	p {
		margin-bottom: 25px;
	}
	.feature-img {
		min-width: 138px;
		margin-right: 14px;
	}
	.xs-btn-wraper {
		text-align: right;
	}
	.btn {
		padding: 10px 30px;
	}
}

/* feature version 2 */
.feature-v2 {
	.xs-feature-group {
		box-shadow: 0px -10px 25px 0px rgba(0, 0, 0, 0.04);
		background-color: $color-white;
		.xs-title {
			color: $titleColor;
		}
		p {
			color: $bColor;
		}
		.btn {
			box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
		}
	}
}

/* feature version 3 */
.feature-v3 {
	.xs-feature-group {
		@include gradient-ten();
	}
}
