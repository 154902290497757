/* banner */

.xs-banner {
    @include gradient-one();
    min-height: 100vh;
    min-height: 99.5vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    .xs-banner-image {
        margin-left: -95px;
        margin-right: -126px;
        position: relative;
    }
    &.banner-v2 {
        background: linear-gradient(45deg, rgba(10, 159, 254, 1) 0%, rgba(10, 159, 254, 1) 35%, rgba(17, 36, 217, 1) 70%, rgba(17, 36, 217, 1) 100%);
    }
    &.banner-v3 {
        min-height: 800px; // min-height: 828px;
        >.container {
            // margin-top: 28px;
        }
    }
    &.banner-404 {
        .xs-banner-image {
            margin: {
                left: 0px;
                right: 0px;
            }
        }
    }
    .icon-bg {
        position: absolute;
        left: 0;
        top: 30%;
        width: 100%;
        height: 532px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        z-index: -1;
    }
    &.banner-v4 {
        @include gradient-ten();
        &::before {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            background-repeat: no-repeat;
            background-position: center bottom;
            width: 100%;
            background-size: cover;
            pointer-events: none;
            z-index: 1;
        }
        &::before {
            background-image: url(../images/welcome/horizontal-shape-2.png);
            height: 100%;
        }
        .banner-title {
            font-size: rem(55.52px);
            font-weight: 500;
            margin-bottom: 30px;
        }
        p {
            font-size: rem(15.99px);
        }
    }
    &.banner-v5 {
        .xs-banner-content {
            text-align: center;
            .banner-title {
                font-size: rem(48.05px);
                margin-bottom: 50px;
            }
        }
    }
    .wave_animation_wraper {
        position: absolute;
        bottom: 0;
        left: -70px;
        width: calc(100% + 200px);
        z-index: -1;
        img {
            width: 100%;
            position: relative;
        }
        .one {
            bottom: -70px;
        }
        .two {
            width: 774px;
            right: -240px;
            margin-left: auto;
            margin-top: -400px;
        }
        .three {
            right: -50px;
            width: 767px;
            margin-left: auto;
            margin-top: -300px;
        }
    }
    &.banner-v6 {
        min-height: 1110px;
        background: none;
        background-color: $color-white;
        @include background-content();
        display: block;
        overflow: visible;
        .xs-banner-image {
            margin-left: -60px;
            margin-right: -10px;
            left: 147px;
            margin-top: -190px;
        }
        .xs-domain-search {
            padding-top: 100px;
        }
    }
    &.banner-v7 {
        background: none;
        overflow: visible;
        min-height: 815px;
        +.pricing-section {
            margin-top: 90px;
        }
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgb(21, 7, 115);
            background: linear-gradient(110deg, rgba(21, 7, 115, .8) 0%, rgba(83, 51, 237, .8) 54%, rgba(44, 212, 217, .8) 100%);
        }
        .welcome-bg {
            position: absolute;
            bottom: 0;
            right: 0;
            pointer-events: none;
        }
        .welcome-bg {
            z-index: -1;
        }
        .xs-banner-image {
            pointer-events: none;
            bottom: -256px;
            z-index: 2;
        }
        .banner-6-content {
            padding-top: 0px;
        }
    }
    &.banner-v8 {
        display: block;
        background: none;
        background-color: #24146d;
        height: 100vh;
        height: 99.5vh;
        >.container {
            padding-top: 200px;
        }
        .xs-banner-content{
            margin-top: -125px;
        }
        .xs-banner-image {
            margin-left: 25px;
            margin-right: -65px;
            margin-top: -40px;
            left: 20px;
        }
        +.pricing-section {
            margin-top: -430px;
            position: relative;
            z-index: 2;
            .shuufle-letter-title {
                color: rgba($color: $color-white, $alpha: .05);
            }
        }
        .banner-6-content {
            padding-top: 0px;
        }
    }
    &.banner-tj {
        display: block;
        max-height: 90vh;
        min-height: 820px;
        height: 100%;
        >.container {
            padding-top: 200px;
        }
        .xs-banner-content{
            margin-top: -125px;
        }
        .xs-banner-image {
            margin-left: 25px;
            margin-right: -65px;
            margin-top: -40px;
            left: 20px;
        }
        +.pricing-section {
            margin-top: -430px;
            position: relative;
            z-index: 2;
            .shuufle-letter-title {
                color: rgba($color: $color-white, $alpha: .05);
            }
        }
        .banner-6-content {
            padding-top: 0px;
        }

    }
    &.skew-bg {
        position: relative;
        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            height: 200px;
            width: 100%;
            border: {
                bottom: 200px solid $color-white;
            }
            z-index: 1;
            pointer-events: none;
        }
    }
    &.curve-bg {
        position: relative;
        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            background-repeat: no-repeat;
            background-position: center bottom;
            width: 100%;
            pointer-events: none;
            z-index: 1;
            height: 87px;
            background-image: url(../images/welcome/main-shape-curve.png);
            background-size: 100% 100%;
        }
    }
}

.banner-6-content {
    &.xs-banner-content {
        .banner-sub-title {
            color: #2cd3d9;
            font-size: rem(16px);
            font-weight: 500;
            margin-bottom: 5px;
            line-height: 1.43;
            margin-top: -20px;
        }
        .banner-title {
            font-size: rem(48px);
            font-weight: 500;
            margin-bottom: 50px;
            span {
                display: inline-block;
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    bottom: -10px;
                    left: 0;
                    width: calc(100% + 18px);
                    height: 18px;
                    background-image: url(../images/welcome/text-bottom-border.png);
                    background-repeat: no-repeat;
                    background-position: bottom left;
                    background-size: cover;
                    pointer-events: none;
                }
            }
        }
        .list-groups {
            margin-bottom: 45px;
        }
        .xs-list {
            >li {
                &:not(:last-child) {
                    margin-bottom: 3px;
                }
            }
        }
    }
    .btn {
        font-weight: 500;
        color: #1633ff;
        &:hover {
            color: $color-white;
        }
    }
}

.banner-title,
.banner-sub-title,
.xs-banner-content {
    color: $color-white;
}

.xs-banner-content {
    p {
        margin-bottom: 15px;
    }
}

.banner-sub-title {
    font-size: rem(28px);
    font-weight: 500;
}

.banner-title {
    font-size: rem(64px);
    font-weight: 700;
    margin-bottom: 15px;
}

.banner-ico {
    position: absolute;
    left: 0;
    backface-visibility: hidden;
    animation: bounce 1s ease-in-out 0s infinite alternate;
    &.banner-ico-1 {
        left: 49%;
        top: 7%;
        width: 27px;
    }
    &.banner-ico-2 {
        width: 27px;
        top: 16%;
        left: 37%;
        animation-delay: .4s;
    }
    &.banner-ico-3 {
        width: 27px;
        top: 26%;
        left: 48.5%;
        animation-delay: .8s;
    }
    &.banner-ico-4 {
        width: 27px;
        top: 16%;
        left: 60.5%;
        animation-delay: 1.2s;
    }
}

@keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-30px);
    }
}

.home5-banner-image {
    text-align: right;
}

.banner-6-content {
    padding-top: calc(4% + 140px);
}

.tp-caption {
    .banner-6-content {
        .xs-list {
            line-height: 1.6 !important;
            >li {
                font-size: rem(17px) !important;
            }
        }
        .btn {
            transition: all 0.6s !important;
        }
    }
}

#rev_slider_17_1_forcefullwidth {
    +.xs-domain-search {
        margin-top: -300px;
        margin-bottom: 79px;
    }
}

#rev_slider_18_1_wrapper {
    .tp-caption {
        .icon {
            display: inline-block;
            @include equal-size(8px);
            border: 1px solid $color-primary;
            border-radius: 50%;
            position: relative;
            top: -1px;
            margin-right: 7px;
        }
    }

}

.banner-sub-title {
    color: $color-baby-blue-green;
    font-size: rem(17px);
    font-weight: 500;
    margin-bottom: 4px;
    line-height: 1.5;
    margin-top: -25px;
}
