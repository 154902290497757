/*
Author: XpeedStudio
Author URI: http://themeforest.net/user/XpeedStudio/portfolio
*/

@media (min-width: 1300px) {}

@media (min-width: 1600px) {}

/* Portrait tablets and medium desktops */

@media (min-width: 992px) and (max-width: 1199px) {
  html {
    font-size: 14px;
  }
  /* pricing table */
  .pricing-feature-group {
    padding: 20px;
  }
  .xs-menu-tools>li {
    margin-right: 15px;
  }
  /* footer */
  .footer-bottom .xs-list li {
    margin-right: 20px;
  }
  /* inner banner */
  .inner-welcome-image-group .banner-ico.banner-ico-1 {
    left: 44.5%;
  }
  .inner-welcome-image-group .banner-ico.banner-ico-2 {
    right: 12%;
  }

  .xs-menus {
    &.xs_nav-landscape {
      .nav-menu {
        padding-right: 4px;
      }
    }
  }
  /* nav */
  .xs-menus .nav-menu>li {
    margin-right: 30px;
  }
  .xs-header .xs-logo {
    margin-left: -8px;
  }
  /* index version 6 banner */
  .xs-banner.banner-v6 .xs-banner-image {
    margin-left: 0;
    margin-right: 0;
    left: 0;
    margin-top: 0;
  }
  #rev_slider_17_1_forcefullwidth+.xs-domain-search {
    margin-top: -270px;
  }
  /* index version 9 */
  /* banner */
  .xs-banner.banner-v7 .xs-banner-image {
    bottom: -306px;
    margin-right: 0;
  }
  .banner-6-content.xs-banner-content .banner-title {
    font-size: 2.32353rem;
  }
  .xs-banner.banner-tj {
    min-height: 80vh;
    max-height: 1150px;
  }

  .xs-domain-search.domainSearch-v2 .domain-search-form-group{
    width: 100%;
    padding: 26px;
  }
}

/* Portrait tablets and small desktops */

@media (max-width: 991px) {
  .xs-banner-content{
    margin-left: -20px;
  }
  html {
    font-size: 90%;
  }
  /* margin bottom list */
  .why-choose-us-block,
  .xs-single-pricing,
  .xs-feature-group,
  .hosting-info-img,
  .hosting-info-wraper-2,
  .xs-blog-post,
  .footer-widget {
    margin-bottom: 30px;
  }
  /* header */
  .xs-header{
    padding-bottom: 0;
  }
  .nav-toggle {
    padding-top: 5px;
  }
  .sticky-header.sticky {

    .xs_nav-portrait {
      height: 65px;
      .nav-header {
        margin-top: 0;
        .nav-brand {
          img{
            max-height: 65px;
            max-width: 185px;
          }
        }
      }
      .nav-toggle {
        padding-top: 5px;
      }
    }
  }
  .xs-header .xs-logo {
    display: none;
  }
  .xs-header .xs-menus .nav-menu>li>a,
  .xs-header.header-boxed .xs-menus .nav-menu>li>a,
  .xs-header.header-v3 .xs-menus .nav-menu>li>a,
  .nav-sticky.sticky-header .xs-menus .nav-menu>li>a {
    color: #1e1e1e;
    padding: 15px;
  }
  /* header */
  .xs-menu-tools {
    display: none;
  }
  .xs-menus .nav-menu li>.nav-dropdown {
    box-shadow: none;
  }
  .xs-menus .nav-menu>li>a .submenu-indicator-chevron,
  .nav-sticky.sticky-header .xs-menus .nav-menu>li>a .submenu-indicator-chevron {
    border-color: transparent #1e1e1e #1e1e1e transparent;
  }
  .nav-toggle:before {
    background-color: #FFFFFF;
    box-shadow: 0 0.5em 0 0 #FFFFFF, 0 1em 0 0 #FFFFFF;
  }
  .xs-header.header-boxed .nav-toggle:before {
    background-color: #1e1e1e;
    box-shadow: 0 0.5em 0 0 #1e1e1e, 0 1em 0 0 #1e1e1e;
  }
  .xs-header.header-boxed>.container::before {
    display: none;
  }
  /* domain search */
  .domain-search-form {
    width: calc(100% + 10px);
  }
  /* section title */
  .xs-heading .heading-title {
    font-size: 1.3rem;
  }
  /* footer */
  .footer-bottom .xs-list {
    text-align: center;
  }
  .footer-bottom .xs-list li {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  /* index version 3 */
  .pricing-feature-item>.pricing-feature:first-child {
    height: 30px;
    padding: 7px 10px;
    font-weight: 700;
  }
  .pricing-feature {
    padding: 10px 40px;
    height: 40px;
  }
  /* inner welcome */
  .inner-welcome-image-group {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    max-height: 50vh;
    margin-top: 50px;
  }
  /* inner welcome service banner */
  .xs-banner.service-banner-2 {
    min-height: 85vh;
    padding-top: 85px;
  }
  .social-list li {
    margin-bottom: 10px;
  }
  .service-banner .banner-title {
    font-size: 2.76471rem;
  }
  .xs-banner.service-banner {
    min-height: 85vh;
    padding-top: 85px;
  }
  /* inner banner */
  .inner-banner .inner-welcome-image-group {
    display: none;
  }
  /* team */
  .xs-single-team {
    margin-bottom: 30px;
  }
  .xs-single-team img {
    width: 100%;
  }
  /* contact */
  .contact-info-group {
    margin-bottom: 30px;
  }
  /* accordion */
  .xs-accordion,
  .xs-accordion:last-child {
    margin-bottom: 60px;
  }
  /* checkout */
  .checkout-form-group {
    width: calc(100% - 45px);
  }
  .checkout_info .cart_table_wraper {
    width: 100%;
  }
  .cart-checkout-wraper {
    padding: 50px;
  }
  .wc-proceed-to-checkout {
    width: 100%;
  }
  .wc-proceed-to-checkout .btn {
    padding: 15px 29px;
    font-size: .8rem;
  }
  /* vps hosting */
  .slider-content-group {
    padding: 30px;
  }
  .slider-content-group .slider-container {
    margin-bottom: 30px;
  }
  /* sticky nav */
  .nav-sticky .xs_nav-portrait .nav-menus-wrapper,
  .nav-sticky .nav-overlay-panel {
    height: 110vh;
  }
  /* index verison 6 */
  /* banner */
  .xs-banner.banner-v6 .xs-banner-image {
    display: none;
  }
  #rev_slider_17_1_forcefullwidth+.xs-domain-search {
    margin-top: 0;
    margin-bottom: 79px;
  }
  .tp-caption .banner-6-content.xs-banner-content {
    padding-top: 130px !important;
  }
  /* index version 9 */
  /* banner */
  .xs-banner.banner-v7 .xs-banner-image {
    display: none;
  }
  .xs-banner.skew-bg::after {
    border-bottom: 150px solid #FFFFFF;
  }
  /* index version 10 */
  .xs-banner.banner-tj>.container {
    padding-top: 220px;
  }
  .xs-banner.banner-tj .xs-banner-image {
    margin-left: auto;
    margin-right: -30px;
    left: 5px;
    width: 100%;
    padding: 0;
    margin-top: -100px;
  }
  .xs-footer-section.footer-v2 .footer-group {
    background-position: center center;
  }
  .xs-footer-section.footer-v2 .footer-main {
    padding-bottom: 60px;
    padding-top: 50px;
  }
  .xs-banner.curve-bg{
    padding-top: 60px;
  }
  .banner-title{
    font-size: 56px;
    letter-spacing: -1.4px;
  }

  .xs-domain-search.domainSearch-v2 .domain-search-form-group{
    width: 100%;
    padding: 20px;
  }
  .xs-domain-info{
    width: 90%;
    padding-left: 15px;
  }
}

/* Landscape phones and portrait tablets */

@media (max-width: 767px) {

  .xs-domain-info{
    width: calc(100% + 40px);
    margin-left: -15px;
  }
  .xs-banner-content{
    margin-left: 0;
    text-align: center;
    padding-bottom: 10px;
  }
  /* section padding */
  .xs-section-padding {
    padding: 50px 0;
  }
  /* heading tilte margin bottom */
  .xs-heading {
    margin-bottom: 40px;
  }
  /* section tilte */
  .xs-heading .heading-title {
    font-size: 1.4rem;
    font-weight: 700;
  }
  /* list inline */
  .list-inline>li {
    display: block;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .list-inline>li:last-child {
    margin-bottom: 0px;
  }
  /* header */
  .xs-logo-wraper {
    display: none;
  }
  .xs-top-bar-info,
  .top-menu {
    text-align: center;
    padding: 5px 0;
  }
  .xs-top-bar-info li {
    margin-right: 15px;
  }

  /* welcome */
  .banner-title {
    font-size: 42px;
    line-height: 1;
  }
  .banner-sub-title {
    font-size: 32px;
    line-height: 1.4;
  }
  .xs-banner .xs-banner-image {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
  }
  .xs-banner .xs-banner-image .banner-ico {
    width: 16px;
  }
  .xs-banner-content p {
    margin-bottom: 20px;
    line-height: 1.2;
  }
  /* domain search */
  .xs-domain-info li {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid rgba(75, 100, 225, 0.25);
    text-align: left;
    img {
      margin-right: 3px;
    }
  }
  /* domain search */
  .domain-search-form {
    height: 100%;
  }
  .domain-search-form-group {
    padding: 10px;
  }
  .domain-search-form .select-group {
    //position: static;
    //margin-top: 10px;
    //width: 100%;

    margin-top: -4px;
    margin-right: -6px;
    .select{
      right: -2px;
    }
  }

  .domain-search-form input:not([type="submit"]) {
    height: 60px;
    padding: 0 16px;
  }
  .xs-domain-info li:last-child {
    margin-bottom: 0px;
  }
  /* all buttons */
  .xs-btn-wraper .btn {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .btn {
    font-size: .9rem;
    padding: 10px 25px;
  }
  /* why choose us */
  .why-choose-us-block {
    padding: 0;
  }
  /* pricing */
  .pricing-info .xs-list li {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .pricing-info .xs-list li:last-child {
    margin-bottom: 0px;
  }
  /* service section */
  .service-section .xs-mb-8 {
    margin-bottom: 0px;
  }
  .xs-service-block {
    margin-bottom: 40px;
  }
  .xs-service-block {
    padding: 0;
  }
  /* call to action */
  .call-to-action-section {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 30px;
  }
  .call-to-action-section+.xs-feature-section {
    margin-top: 0;
  }
  /* feature group */
  .xs-feature-group .media {
    flex-wrap: wrap;
  }
  .xs-feature-group .feature-img {
    margin-bottom: 15px;
  }
  .xs-feature-group .watermark-icon {
    display: none;
  }
  .xs-feature-group {
    padding: 20px;
  }
  /* hosting info */
  .hosting-info-img .info-icon {
    width: 30px;
  }
  .hosting-info-img .info-icon.icon-2 {
    left: 0;
  }
  .hosting-info-wraper {
    padding-left: 0;
    margin-bottom: 50px;
  }
  .hosting-info-wraper-2 .content-title {
    margin-bottom: 30px;
  }
  .xs-hosting-info .row {
    margin-bottom: 45px;
  }
  /* content tilte */
  .content-title {
    font-size: 1.2rem;
  }
  /* testimonial */
  .xs-testimonial-item,
  .xs-testimonial-slider .owl-item.center .xs-testimonial-item {
    width: 100%;
    padding: 30px;
  }
  /* footer */
  .footer-bottom-info {
    margin-bottom: 20px;
  }
  .footer-group {
    background-size: cover;
  }
  /* copyright */
  .copyright-text {
    padding: 0px;
  }
  .footer-logo-wraper {
    padding: 15px 0;
  }
  .copyright-text p,
  .social-list {
    text-align: center;
  }
  /* index version 2 */
  .hosting-info-wraper-2 {
    margin-bottom: 50px;
  }
  /* index verison 3 */
  .xs-banner.banner-v3 {
    min-height: 900px;
  }
  .main-nav-tab-2 li {
    width: calc(100% / 2);
  }
  .main-nav-tab-2 li a {
    font-size: .9647rem;
  }
  /* inner banner */
  .service-banner .banner-title {
    font-size: 1.76471rem;
  }
  .xs-banner.service-banner {
    min-height: 700px;
  }
  .xs-banner.inner-banner .banner-title {
    font-size: 1.5882rem;
  }
  .breadcrumbs.list-inline>li {
    display: inline-block;
  }
  /* about */
  .gloosy-btn {
    width: 60px;
    height: 60px;
    line-height: 63px;
  }
  .join-with-us-wraper {
    margin-bottom: 30px;
    margin-top: 0;
    z-index: 2;
    padding: 20px 15px;
  }
  .office-interface-group-content.content-1 {
    margin-top: 0;
  }
  .office-interface-group-content.content-2 {
    margin-right: 0;
  }
  /* team */
  .simple-social-list li {
    display: inline-block;
    margin-right: 15px;
  }
  /* blog list */
  .post:not(.format-audio):not(.post-details) .post-media+.post-body {
    width: 100%;
    margin-top: 0;
  }
  .post-body .entry-title {
    font-size: 1.26471rem;
  }
  .post-body .post-meta>span:not(:last-child) {
    margin-bottom: 15px;
  }
  .xs-blog-list {
    margin-bottom: 50px;
  }
  .blog-lsit-group {
    margin-bottom: 50px;
  }
  .widget:not(.widget-search) {
    padding: 20px;
  }
  .widget:not(:last-child) {
    margin-bottom: 30px;
  }
  .sidebar-widget.sidebar-right {
    margin-left: 0px;
  }
  .sidebar-widget.sidebar-left {
    margin-right: 0px;
  }
  /* blog post */
  .xs-comments-area .comment:not(:last-child) .children {
    margin-left: 50px;
  }
  .simple-social-list.list-inline {
    text-align: left;
  }
  .author-info {
    padding: 30px;
    display: block;
  }
  .author-image {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .post-navigation [class^=col-]:first-child .post-navigation-title {
    margin-bottom: 20px;
  }
  .blog-post-group {
    margin-bottom: 30px;
  }
  .post-details .post-footer .tag-lists {
    margin-bottom: 20px;
  }
  /* contact */
  .xs-form-group {
    padding: 0;
  }
  /* pricing */
  .content-title-wraper {
    margin-bottom: 80px;
  }
  /* cart */
  .coupon {
    max-width: 100%;
    margin-left: 0;
    padding: 0 15px;
    margin-bottom: 20px;
  }
  /* checkout */
  .checkout-form-group {
    width: 100%;
  }
  .cart-checkout-wraper {
    padding: 20px;
  }
  .sign_in_form.xs-form .form-control:last-child,
  .sign_in_form.xs-form .select:last-child,
  .billing-form.xs-form .form-control:last-child,
  .billing-form.xs-form .select:last-child {
    margin-bottom: 30px;
  }
  .sign_in_form .simple-btn {
    margin-top: 12px;
  }
  .checkout_info .cart_table_wraper {
    width: 100%;
  }
  /* sign up */
  .xs-form>.row>[class^=col-]:first-child .form-group {
    padding-left: 0;
  }
  .xs-form>.row>[class^=col-]:last-child .form-group {
    padding-right: 0;
  }
  .xs-form>.row>[class^=col-] .form-group {
    margin-bottom: 20px;
  }
  /* 404 */
  .xs-banner.banner-404 .banner-title {
    font-size: 1.96471rem;
  }
  .xs-banner.banner-404 .xs-banner-image {
    width: 80%;
    margin: 0 auto;
  }
  .xs-banner.banner-404 {
    min-height: 750px;
  }
  /* index 5 */
  .xs-banner.banner-v4 .banner-title {
    font-size: 2rem;
  }
  .home5-banner-image {
    text-align: right;
    width: 70%;
    margin: 0 auto;
  }
  .wave_animation_wraper {
    display: none;
  }
  /* index 4 */
  .xs-banner.banner-v5 .xs-banner-content .banner-title {
    font-size: 1.82647rem;
  }
  .domain-search-form.version-2 .select-group {
    width: 120px;
    position: absolute;
    margin-top: 0;
  }
  .domain-search-form.version-2 input:not([type="submit"]) {
    padding-left: 133px;
  }
  .domain-search-form .search-btn {
    padding: 22px;
  }
  .domain-box-list li {
    margin-bottom: 12px;
  }
  /* location map */
  .location_indicator {
    display: none;
  }
  .location-groups .location {
    display: block;
    position: static;
    margin-bottom: 30px;
  }
  .location-groups .map-image {
    display: none;
  }
  /* sticky nav */
  .nav-sticky.sticky-header .xs-top-bar {
    margin-top: -90px;
  }
  /* location map */
  .location-wraper .location {
    float: left;
    width: 50%;
    display: inline-block;
  }
  /* index version 6 */
  /* banner */
  .xs-banner.banner-v6 {
    min-height: 500px;
  }
  .xs-banner.banner-v6 .xs-banner-content .banner-title {
    font-size: 1.82353rem;
  }
  .xs-banner.banner-v6 .xs-banner-content {
    padding-top: 180px;
  }
  .xs-banner.banner-v6 {
    margin-bottom: 50px;
  }
  /* blog */
  .post-image>img {
    width: 100%;
  }
  /* footer */
  .xs-footer-section.footer-v2 .social-list {
    text-align: left;
  }
  /* index version 9 */
  /* banner */
  .banner-6-content.xs-banner-content .banner-title {
    font-size: 1.82353rem;
    margin-bottom: 30px;
  }
  .xs-banner.banner-v7 {
    min-height: 650px;
  }
  /* index version 10 */
  .xs-banner.curve-bg::after {
    background-size: contain;
  }
  .xs-banner.banner-tj+.pricing-section {
    margin-top: -300px;
  }
  .xs-banner.banner-tj>.container {
    padding-top: 180px;
  }
  .xs-banner.banner-tj .xs-banner-image {
    width: 60%;
    left: 0;
    margin: 0 20%;
    padding: 0 20px;
  }
  .testimonial-v7 .owl-carousel .owl-item.center .xs-testimonial-item {
    box-shadow: 1px 6px 17px 0px rgba(0, 0, 0, 0.1);
  }
  .xs-footer-section.footer-v2 .footer-bottom .xs-list {
    text-align: left;
  }
  /* index version 11 */
  .xs-domain-search.version-primary .select-styled {
    color: #FFFFFF;
  }
  .xs-domain-search.version-primary .select-styled::after {
    color: #FFFFFF;
  }
  .xs-domain-search.version-primary .domain-search-form input[type="submit"] {
    background: #FFFFFF;
    color: #1351f4;
  }
}

/* Landscape phones and smaller */

@media (max-width: 480px) {
  .xs-domain-search.domainSearch-v2 .domain-search-form-group {
    width: calc(100% + 5px);
    padding: 0;
    .domain-search-form {
      .select-group {
        max-width: 75px;
      }
      .select-options{
        left: -150px;
      }
    }
  }
  .domain-search-form input[type="submit"]{
    display: none;
  }
  .xs-domain-info{
    display: none;
  }
  html {
    font-size: 65%;
  }
  .xs-comments-area .comment:not(:last-child) .children {
    margin-left: 20px;
  }
  /* vps hosting */
  .vps-pricing-list>li {
    max-width: 100%;
  }
  .vps-pricing-list:last-child>li:not(:last-child) {
    padding-bottom: 26px;
    margin-bottom: 26px;
    border-bottom: 1px solid #e7e7e7;
  }
  /* locationi map */
  .location-wraper .location {
    width: 100%;
  }
  .tp-caption .banner-6-content.xs-banner-content .xs-list>li:not(:last-child) {
    margin-bottom: 5px !important;
  }
  .tp-caption .banner-6-content .xs-list {
    margin-bottom: 5px !important;
  }
  .tp-caption .banner-6-content.xs-banner-content {
    padding-top: 90px !important;
  }
  /* index version 9 */
  /* banner */
  .xs-banner.skew-bg::after,
  .xs-banner.banner-v7 .xs-banner-image {
    display: none;
  }
  .xs-banner.banner-v7 {
    min-height: 600px;
  }
  /* index version 10 */
  .shuufle-letter-title {
    font-size: 4rem;
  }
  .xs-banner.banner-tj+.pricing-section {
    margin-top: -250px;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 60.5%;
  }
  .tp-caption .banner-6-content.xs-banner-content .banner-title {
    font-size: 22px !important;
    margin-bottom: 15px !important;
  }
  .tp-caption .banner-6-content.xs-banner-content .banner-title span {
    font-size: inherit !important;
  }
  .tp-caption .banner-6-content.xs-banner-content {
    padding-top: 80px !important;
  }
  .tp-caption .banner-6-content.xs-banner-content .banner-title span::before {
    display: none;
  }
  /* index version 10 */
  .xs-banner.banner-tj+.pricing-section {
    margin-top: -350px;
  }
  .shuufle-letter-title {
    font-size: 3rem;
  }
}
//18px

@media (min-width: 1200px) {
  .hidden-xlg{
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg{
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md{
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm{
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (max-width: 575px) {
  .hidden-xs{
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media  (max-width: 1199px) {
  .hidden--lg{
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (max-width: 991px) {
  .hidden--md{
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (max-width: 767px) {
  .hidden--sm{
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (max-width: 575px) {
  .hidden--xs{
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
