.domain-search-form-group {
    padding: 30px 40px 20px 20px;
    background-color: transparent;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.07);
}

.xs-domain-info {
    text-align: center;
    background: rgba(245,240,245,0.1);
    border-radius: 32px;
    padding: 12px 0;
    width: 76%;
    margin: 0 auto;
    li {
        display: inline-block;
        margin-right: 12px;
        padding-right: 12px;
        border-right: 1px solid rgba(75, 100, 225, 0.4);
        color: rgba(255,255,255,0.75);
        img {
            margin-right: 4px;
            margin-top: -3px;
        }
        &:last-child {
            margin-right: 0px;
            border-right: 0px;
            border-right: 0px;
        }
    }
}

.domain-search-form {
    width: calc(100% - 320px);
    margin: 0 auto;
    margin-bottom: 25px;
    position: relative;
    height: 70px;
    z-index: 9;
    input:not([type="submit"]) {
        border-radius: rem(37.5px);
        width: 100%;
        height: 100%;
        border: 0px;
        padding: 0 32px;
        box-shadow: 0px 10px 45px 0px rgba(0, 0, 0, 0.06);
        border: 1px solid #f5f3f3;
    }
    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #7b7b7b;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: #7b7b7b;
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        color: #7b7b7b;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        color: #7b7b7b;
    }
    .select-group {
        position: absolute;
        right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        top: 0px;
    }
    input[type="submit"] {
        border: 0px;
        background-color: $color-primary;
        border-radius: rem(27.5px);
        color: $color-white;
        padding: 14px 37px;
        cursor: pointer;
        @include transition;
        &:hover {
            background-color: darken($color: $color-primary, $amount: 10);
        }
    }
    .select-styled {
        width: 60px;
        float: right;
        position: relative;
    }
    .select {
        height: 29px;
        width: 100%;
        .select-styled {
            padding: 0px;
        }
    }
    .search-btn {
        background-color: transparent;
        border: 0px;
        color: #000000;
        font-size: rem(15.02px);
        position: absolute;
        top: 0;
        right: 0;
        padding: 28px 32px 28px 20px;
        text-align: center;
        line-height: 1;
        cursor: pointer;
    }
    &.version-2 {
        width: 100%;
        max-width: 540px;
        margin-bottom: 30px;
        .select-group {
            right: inherit;
            left: 0px;
            width: 130px;
            &::before {
                position: absolute;
                content: '';
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 1px;
                height: 44.06px;
                background-color: #e7e7e7;
                border-radius: .5px;
            }
        }
        .select {
            color: #000000;
        }
        input:not([type="submit"]) {
            padding-left: 163px;
        }
        .select-styled {
            width: 115px;
        }
    }
}

.xs-domain-search {
    position: relative;
    z-index: 9;
}

.xs-banner:not(.inner-banner) {
    +.xs-domain-search {
        top: 60vh;
        position: absolute;
        background: transparent;
        width: 99%;
        margin: 0 auto;
        margin-left: -6px;
        display: block;
    }
    &.banner-v3 {
        +.xs-domain-search {
            margin-top: 0px;
        }
    }
}

.xs-domain-search {
    &.domainSearch-v2 {
        background-color: transparent;
        .domain-search-form-group {
            box-shadow: none;
            background-color: transparent;
            width: 90%;
            float: left;
            background-color: transparent;
            margin: 0 auto;
        }
    }
}

.xs-domain-search {
    &.domainSearch-2 {
        .domain-search-form {
            margin-bottom: 0px;
        }
    }
    &.domainSearch-3 {
        .domain-search-form {
            input[type="submit"] {
                background-color: $color-ternary;
            }
        }
    }
}

.domain-box-list {
    li {
        display: inline-block;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.102);
        padding: 13px 26px;
        text-align: left;
        font-size: rem(18.02px);
        color: #fefefe;
        line-height: 1;
        &:not(:last-child) {
            margin-right: 12px;
        }
        hr {
            margin: 12px 0;
            background-color: rgb(255, 255, 255);
            opacity: 0.2;
            width: 16px;
            height: 3px;
            display: block;
        }
    }
}

/* version primary */

.xs-domain-search {
    &.version-primary {
        background: rgb(19, 81, 244);
        background: linear-gradient(90deg, rgba(19, 81, 244, 1) 0%, rgba(7, 39, 235, 1) 100%);
        .domain-search-form-group {
            box-shadow: none;
            background-color: transparent;
        }
        .domain-search-form {
            input {
                &:not([type="submit"]) {
                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        font-size: rem(17px);
                        font-family: $titleFont;
                    }
                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        font-size: rem(17px);
                        font-family: $titleFont;
                    }
                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        font-size: rem(17px);
                        font-family: $titleFont;
                    }
                    &:-moz-placeholder {
                        /* Firefox 18- */
                        font-size: rem(17px);
                        font-family: $titleFont;
                    }
                }
                &[type="submit"] {
                    background: rgb(19, 81, 244);
                    background: linear-gradient(90deg, rgba(19, 81, 244, 1) 0%, rgba(7, 39, 235, 1) 100%);
                }
            }
        }
        .select-options {
            top: calc(100% + 22px);
        }
        .select-styled {
            color: #7b7b7b;
            font-size: rem(17px);
            font-family: $titleFont;
            &::after {
                content: "\e96c";
                font-family: $iconFont2;
                color: #3482ff;
                font-size: rem(13px);
                top: 4px;
                right: 17px;
            }
        }
        .xs-domain-info {
            >li {
                border-right-color: rgba($color-white, 0.25);
                >strong {
                    color: rgba($color: $color-white, $alpha: .3);
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}
