.xs-testimonial-section {
    background: rgba(16, 69, 219, 1);
    background: linear-gradient(45deg, rgba(16, 69, 219, 1) 0%, rgba(16, 69, 219, 1) 13%, rgba(21, 9, 94, 1) 52%, rgba(21, 9, 94, 1) 100%);
    padding-top: 100px;
    padding-bottom: 70px;
    &.testimonial-v2 {
        background: rgb(10, 159, 254);
        background: linear-gradient(90deg, rgba(10, 159, 254, 1) 0%, rgba(10, 159, 254, 1) 0%, rgba(17, 36, 217, 1) 52%, rgba(17, 36, 217, 1) 100%);
        .owl-carousel {
            .owl-item:not(.center) {
                .xs-testimonial-item {
                    p {
                        color: #2547b5;
                    }
                }
                .xs-testimonial-item,
                .xs-testimonial-item::before,
                .xs-testimonial-item::after {
                    background-color: #889df0;
                }
            }
        }
    }
    &.testimonial-v3 {
        background-image: none;
        background-color: $color-white;
        padding-bottom: 0px;
        .owl-carousel {
            .owl-item {
                &.center {
                    .xs-testimonial-item,
                    .xs-testimonial-item::before,
                    .xs-testimonial-item::after {
                        @include gradient-six();
                    }
                    .testimonial-content p,
                    .commentor-title {
                        color: $color-white;
                    }
                    .commentor-bio i {
                        opacity: .2;
                    }
                }
            }
        }
        .owl-carousel {
            .owl-item:not(.center) {
                .xs-testimonial-item,
                .xs-testimonial-item:before,
                .xs-testimonial-item:after {
                    @include gradient-seven();
                }
                .xs-testimonial-item {
                    opacity: 1;
                }
                .testimonial-content p,
                .commentor-title {
                    color: $color-white;
                }
                .xs-testimonial-item {
                    .round-avatar::before {
                        background-color: #f7f7f7;
                    }
                }
            }
        }
    }
    &.testimonial-v4 {
        @include gradient-ten();
    }
    &.testimonial-v5 {
        background-image: none;
        background-color: $color-white;
        padding-bottom: 0px;
        .owl-carousel {
            .owl-item {
                &.center {
                    .xs-testimonial-item,
                    .xs-testimonial-item::before,
                    .xs-testimonial-item::after {
                        @include gradient-ten();
                    }
                    .testimonial-content p,
                    .commentor-title {
                        color: $color-white;
                    }
                    .commentor-bio i {
                        opacity: .2;
                    }
                }
            }
        }
        .owl-carousel {
            .owl-item:not(.center) {
                .xs-testimonial-item,
                .xs-testimonial-item:before,
                .xs-testimonial-item:after {
                    @include gradient-ten();
                }
                .xs-testimonial-item {
                    opacity: .5;
                }
                .testimonial-content p,
                .commentor-title {
                    color: $color-white;
                }
                .xs-testimonial-item {
                    .round-avatar::before {
                        background-color: #f7f7f7;
                    }
                }
            }
        }
    }
    &.testimonial-v6 {
        position: relative;
        @include background-content();
        z-index: 1;
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgb(21, 7, 115);
            background: linear-gradient(110deg, rgba(21, 7, 115, 1) 0%, rgba(83, 51, 237, 1) 54%, rgba(44, 212, 217, 1) 100%);
            opacity: .8;
            z-index: -1;
        }
    }
    &.testimonial-v7 {
        background: rgb(233, 231, 240);
        background: linear-gradient(0deg, rgba(233, 231, 240, 1) 0%, rgba(233, 231, 240, 0) 100%);
    }
    .owl-item.cloned {
        transform: scale(.9);
        transition: all 0.3s;
    }
    .owl-item.active {
        transform: scale(0.8);
        transition: all 0.3s;
    }
    .owl-item.active.center {
        transform: scale(1);
    }
}

.xs-testimonial-item {
    width: 100%;
    margin: 0 auto;
    padding: 30px;
    border-radius: 5px;
    position: relative;
    &::before,
    &::after {
        position: absolute;
        content: "";
        top: 0;
        height: 100%;
        width: 100%;
        opacity: .2;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
    }
}

.owl-carousel {
    .owl-item.center {
        .xs-testimonial-item {
            padding: 40px;
        }
    }
}

.testimonial-content {
    p {
        font-size: rem(12.4px);
    }
    >p {
        margin-bottom: 20px;
    }
}

.commentor-bio {
    position: relative;
    i {
        position: absolute;
        right: 0;
        top: 0;
        font-size: rem(40px);
        opacity: .1;
    }
    .round-avatar {
        margin-right: 16px;
    }
    .commentor-title {
        font-size: rem(15.5px);
        margin-bottom: 0px;
    }
    .commentor-info {
        margin-bottom: 0px;
    }
    .round-avatar {
        position: relative;
        border-radius: 100%;
        overflow: hidden;
    }
}

.owl-carousel {
    .owl-item:not(.center) {
        .xs-testimonial-item {
            margin-top: 25px; // width: 80%;
            .round-avatar {
                @include equal-size(44px);
                &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: #181953;
                    opacity: .5;
                }
            }
            p {
                color: #434483;
            }
            .commentor-bio {
                i {
                    color: #7f7eaf;
                }
            }
            &::before {
                width: calc(100% - 28px);
                top: -10px;
            }
            &::after {
                width: calc(100% - 56px);
                top: -20px;
            }
        }
    }
    .owl-item.center {
        .round-avatar {
            @include equal-size(55px);
        }
        .testimonial-content>p {
            margin-bottom: 25px;
            font-size: rem(17px);
        }
        .commentor-bio .commentor-title {
            font-size: rem(20px);
        }
        .testimonial-content .commentor-info {
            font-size: rem(15px);
        }
        .commentor-bio i {
            font-size: rem(60px);
        }
        .xs-testimonial-item {
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.3);
            &::before {
                width: calc(100% - 20px);
                top: -14px;
            }
            &::after {
                width: calc(100% - 40px);
                top: -28px;
            }
        }
    }
}

.testimonial-v7 {
    background: rgb(233, 231, 240);
    background: linear-gradient(0deg, rgba(233, 231, 240, 1) 0%, rgba(233, 231, 240, 0) 100%);
    .owl-carousel {
        .owl-item {
            &.center {
                .xs-testimonial-item {
                    box-shadow: 19.799px 19.799px 40px 0px rgba(0, 0, 0, 0.1);
                }
            }
            .xs-testimonial-item {
                &::before,
                &::after {
                    background-color: $color-black;
                }
                &::before {
                    opacity: .07;
                }
                &::after {
                    opacity: .03;
                }
            }
            &:not(.center) {
                .xs-testimonial-item {
                    background-color: $color-white;
                    opacity: .5;
                    p {
                        color: #70799f;
                    }
                    .round-avatar::before {
                        background-color: $color-white;
                    }
                    &::before,
                    &::after {
                        opacity: 0;
                    }
                }
            }
        }
    }
}

.owl-carousel .owl-item .xs-testimonial-item::before,
.owl-carousel .owl-item .xs-testimonial-item::after,
.owl-carousel .owl-item .xs-testimonial-item {
    background-color: $color-white;
}

.owl-carousel .owl-item:not(.center) .xs-testimonial-item,
.owl-carousel .owl-item:not(.center) .xs-testimonial-item::before,
.owl-carousel .owl-item:not(.center) .xs-testimonial-item::after {
    background-color: #8a84af;
}

.xs-testimonial-slider-2 {
    .owl-stage-outer {
        padding: 40px 0;
    }
}
.xs-testimonial-slider {
    .owl-stage-outer {
        padding: 30px 0;
    }
}