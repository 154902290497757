.xs-single-team {
    position: relative;
    overflow: hidden;
    transition: all .5s ease;
    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include gradient-five();
        opacity: .5;
        transition: all .3s ease;
    }
    > .team-bio {
        position: absolute;
        z-index: 1;
        padding: 30px;
        bottom: 0px;
        left: 0;
        width: 100%;
        opacity: 1;
        visibility: visible;
        transition: all .3s ease;
    }
    .team-bio {
        h4 {
            color: $color-white;
            font-size: rem(24px);
            margin-bottom: 5px;
        }
        p {
            font-size: rem(15px);
            font-style: italic;
            color: $color-white;
            margin-bottom: 0px;
        }
    }
    .team-hover-content {
        position: absolute;
        top: 40px;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
        padding: 60px 30px;
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease;
        .team-bio {
            margin-bottom: 25px;
        }
        .team-description {
            margin-bottom: 50px;
            p {
                margin-bottom: 0px;
            }
        }
    }
    .team-description {
        p {
            color: $color-white;
        }
    }
    &:hover {
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.159);
        &::before {
            opacity: 1;
        }
        > .team-bio {
            opacity: 0;
            visibility: hidden;
            bottom: -40px;
        }
        > .team-hover-content {
            opacity: 1;
            visibility: visible;
            top: 0px;
        }
    }
}