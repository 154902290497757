//.woo__wraper {
//    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.08);
//    background-color: $color-white;
//    table.shop_table {
//        border: 0px;
//        margin: 0;
//        border-radius: 0px;
//        border-collapse: collapse;
//        &.table {
//            thead {
//                th {
//                    border-bottom: 0px;
//                    width: 20%;
//                    text-align: center;
//                }
//                tr {
//                    @include gradient-four();
//                    color: $color-white;
//                }
//            }
//            td, th {
//                border-top: 0px;
//            }
//            tbody {
//                tr {
//                    td {
//                        &:not(:last-child) {
//                            text-align: center;
//                        }
//                        padding: 44px 10px;
//                        border-top: 0px;
//                        @include my-border('bottom');
//                        &:last-child {
//                            text-align: right;
//                            padding-right: 40px;
//                        }
//                    }
//                }
//            }
//            .product-name {
//                font-weight: 700;
//                color: $titleColor;
//            }
//            .product-price ,
//            .product-duration ,
//            .product-name {
//                letter-spacing: -.4px;
//            }
//            a.remove {
//                display: inline-block;
//                height: auto;
//                width: auto;
//                font-size: rem(17px);
//                color: $bColor !important;
//                position: relative;
//                top: 2px;
//                margin-left: 35px;
//                &:hover {
//                    background-color: transparent;
//                }
//            }
//        }
//    }
//}
//
//.cart-actios {
//    padding-top: 40px;
//    padding-bottom: 100px;
//}
//.coupon {
//    max-width: 330px;
//    margin-left: 50px;
//    .form-control {
//        margin-bottom: 0px;
//    }
//    .btn {
//        border-radius: 0px;
//        padding: 13px 24px;
//    }
//}
//
//.cart_table_wraper {
//    margin-bottom: 40px;
//    padding-bottom: 40px;
//    @include my-border('bottom');
//
//}
//.cart_table {
//    max-width: 350px;
//    margin-left: auto;
//    margin-bottom: 0px;
//    td, th {
//        border-top: 0px;
//        padding: 0;
//        padding-bottom: 5px;
//        font-weight: 400;
//    }
//    strong {
//        font-weight: 700;
//        color: $titleColor;
//    }
//    .highlight {
//        color: #fe1616;
//    }
//    tr:last-child {
//        td, th {
//            padding-bottom: 0px;
//        }
//    }
//}
//
//.wc-proceed-to-checkout {
//    .xs-btn-wraper {
//        text-align: left;
//    }
//    .btn {
//        padding: 15px 34px;
//    }
//}
//.cart_table_wraper ,
//.wc-proceed-to-checkout {
//    width: calc(100% - 40px);
//    margin-left: auto;
//}
//



/*
		Nav Second Main
		- search, etc
	*/
#header ul.nav-second-main {
    border-left:rgba(0,0,0,0.1) 1px solid;
    padding-left:15px;
    margin-top: 39px;

    -webkit-transition: all .300s;
    -moz-transition: all .300s;
    -o-transition: all .300s;
    transition: all .300s;
}
#header.fixed ul.nav-second-main {
    margin-top:20px;
}
#header.header-sm ul.nav-second-main {
    margin-top:20px;
}
#header.header-md ul.nav-second-main {
    margin-top:23px;
}
#header.fixed.header-sm ul.nav-second-main.nav-social,
#header.fixed.header-md ul.nav-second-main.nav-social {
    margin-top:18px;
}
#header ul.nav-second-main.nav-social a {
    margin-left:10px !important;
}
#header ul.nav-second-main.nav-social>li:first-child a {
    margin-left:10px !important;
}
#header.fixed ul.nav-second-main.nav-social a>i:hover {
    color: #fff !important;
}
#header ul.nav-second-main li {
    padding-top:33px;
    padding:0 5px 0px 5px;
}
#header ul.nav-second-main li>a {
    background-color:transparent;
    color:#666;
    padding:0 3px;
    display:block;
}
#header ul.nav-second-main li i {
    font-size:18px;
    width: 20px;
    height: 20px;
    margin:0;
    padding:0;

    opacity:0.6;
    filter: alpha(opacity=60);

    -webkit-transition: all .300s;
    -moz-transition: all .300s;
    -o-transition: all .300s;
    transition: all .300s;
}
#header ul.nav-second-main li:hover i {
    opacity:1;
    filter: alpha(opacity=100);
}
#header ul.nav-second-main li .badge {
    padding:3px 6px;
}

/* dark & color menu */
#header.transparent ul.nav-second-main li a,
#header.transparent ul.nav-second-main li a>i,
#header.color ul.nav-second-main li a,
#header.color ul.nav-second-main li a>i,
#header.dark ul.nav-second-main li a,
#header.dark ul.nav-second-main li a>i {
    color:#fff;

    opacity:1;
    filter: alpha(opacity=100);
}

@media only screen and (max-width: 992px) {
    #header ul.nav-second-main {
        margin:15px 15px 0 0;
        border:0;
    }
    #header ul.nav-second-main li {
        padding:0;
        padding-top:6px;
    }
    #header ul.nav-second-main {
        -webkit-transition: all 0s;
        -moz-transition: all 0s;
        -o-transition: all 0s;
        transition: all 0s;
    }
    #header.fixed ul.nav-second-main li {
        padding-top:0;
    }
    #header.header-md ul.nav-second-main li>a,
    #header.header-sm ul.nav-second-main li>a {
        margin-top:-6px;
    }

    /* header social */
    #header.header-md ul.nav-second-main {
        margin-top:20px;
    }
    #header.fixed.header-sm ul.nav-second-main.nav-social,
    #header.fixed.header-md ul.nav-second-main.nav-social {
        margin-top:26px;
    }

    /* header translucent fix */
    #header.header-md.translucent ul.nav-second-main {
        margin-top:23px;
    }
    #header.header-md.translucent.fixed ul.nav-second-main {
        margin-top:29px;
    }

}


/** ************************************************************* **/
/* submenu */
#topNav ul.dropdown-menu {
    text-align:left;
    margin-top: 0;
    box-shadow:none;
    border:#eee 1px solid;
    border-top:#eee 1px solid;
    list-style:none;
    background:#fff;
    background-color:#fff;
    box-shadow:rgba(0,0,0,0.2) 0 6px 12px;
    min-width:200px;
    padding:0;

    border-color: #1ABC9C #fff #fff;

    -webkit-transition: top .4s ease;
    -o-transition: top .4s ease;
    transition: top .4s ease;

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
#topNav ul.dropdown-menu ul.dropdown-menu {
    margin-top: -1px !important; /* -1px required for border-top menu */
}

#topNav ul.dropdown-menu li {
    position:relative;
    border-bottom: rgba(0,0,0,0.06) 1px solid;
}
/*
		#topNav .submenu-dark ul.dropdown-menu li {
			border-bottom-color: rgba(0,0,0,0.2);
		}
	*/
#topNav ul.dropdown-menu li:last-child {
    border-bottom:0;
}
#topNav ul.dropdown-menu li a {
    margin:0;
    padding:7px 15px;
    font-weight:400;
    line-height:23px;

    color:#666;
    font-size:12px;
    display:block;
    text-decoration:none;
}
#topNav ul.dropdown-menu>li a i {
    margin-right:6px;
    font-size:12px;
}
#topNav ul.dropdown-menu a.dropdown-toggle {
    background-position: right center;
    background-repeat: no-repeat;
}
#topNav ul.dropdown-menu li.active>a,
#topNav ul.dropdown-menu li.active:hover>a,
#topNav ul.dropdown-menu li.active:focus>a,
#topNav ul.dropdown-menu li:hover>a,
#topNav ul.dropdown-menu li:focus>a,
#topNav ul.dropdown-menu li:focus>a {
    color:#000;
    background-color:rgba(0,0,0,0.05);
}

#topNav ul.dropdown-menu li.divider {
    margin:-1px 0 0 0;
    padding:0; border:0;
    background-color:rgba(0,0,0,0.1);
}
#topNav .nav li:hover>ul.dropdown-menu {
    padding:0;
    display:block;
    z-index:100;
}
#topNav ul.dropdown-menu li .label {
    margin-top:4px;
}

/* sub-submenu */
#topNav ul.dropdown-menu>li:hover > ul.dropdown-menu {
    display:block;
    position:absolute;
    left:100%; top:0;
    padding:0; margin:0;
    border-left:0 !important;
    border-right:0 !important;
    border-bottom:0 !important;
}
/** ************************************************************* **/


/* onepage active link */
#topMain.nav-onepage>li.active>a {
    font-weight:bold;
}


/** Responsive Top Nav
	 ********************* **/
@media only screen and (max-width: 992px) {
    .navbar-collapse {
        height:100%;
    }
    form.mobile-search {
        display:block;
    }

    #topNav div.nav-main-collapse {
        padding:0; margin:0;
    }
    #topNav button.btn-mobile {
        display:block;
        float:right;
        margin-right:0;
    }
    #header.dark #topNav button.btn-mobile,
    #header.transparent #topNav button.btn-mobile,
    #header.theme-color #topNav button.btn-mobile {
        color:#fff;
    }
    #topNav nav.nav-main {
        background-color:#fff;
    }
    #topNav div.nav-main-collapse,
    #topNav div.nav-main-collapse.in {
        width: 100%;
        margin:-1px 0 0 0;
    }
    #topNav div.nav-main-collapse {
        float: none;
        overflow-x:hidden;
        max-height:350px;
    }





    /* ======================== MOBILE MENU V2 ===================== */
    html.noscroll,
    html.noscroll body {
        overflow: hidden !important;
    }

    #header.fixed #topNav div.nav-main-collapse,
    #topNav div.nav-main-collapse {
        max-height:100% !important;
        height:100%;
        width: 100%;
        position: fixed !important;
        left:0 !important; right:0; bottom:0; top:0;
        z-index:9999 !important;
        overflow-y: scroll !important;
        padding-top:58px !important;
        margin:0 !important;
    }
    #topNav div.nav-main-collapse {
        -webkit-overflow-scrolling: touch; /* iOS smooth scroll */
        -webkit-animation: fadeIn .3s !important;
        animation: fadeIn .3s !important;
    }

    #header.fixed #topNav button.btn-mobile-active,
    #topNav button.btn-mobile-active {
        position: fixed !important;
        z-index:999999 !important;
        background-color: #232323 !important;
        height: 60px !important;
        top:0 !important;
        width: 100%;
        left:0; right:0;
        margin: 0 !important;
        opacity: 0.9;

    }
    #topNav button.btn-mobile-active>i {
        float:right;
        margin-right:10px;
        margin-left:10px;
        color: #fff !important;
        font-size:30px;
    }
    #topNav button.btn-mobile-active>i:before {
        content: "\f00d" !important;
    }

    #menu-overlay {
        position: fixed;
        top:0; bottom:0;
        left:0; right:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index:10 !important;
    }

    /* dropdown background color */
    #topMain,
    #topNav ul.dropdown-menu {
        color: #fff;
        background-color: #333;
    }
    #topNav ul.dropdown-menu {
        border: 0;
    }
    #topMain>li>a {
        color: #fff !important;
    }
    #topNav ul.dropdown-menu li>a {
        color: #fbfbfb !important;
    }
    #topMain>li>a,
    #topNav ul.dropdown-menu li>a {
        font-size:15px !important;
    }
    /* ====================== END MOBILE MENU V2 ==================== */



    #topNav div.nav-main-collapse.collapse {
        display: none !important;
    }
    #topNav div.nav-main-collapse.in {
        display: block !important;
    }
    #topNav div.nav-main-collapse {
        position: relative;
    }



    #topMain>li>a>span {
        display:none !important;
    }
    #topMain li {
        display:block !important;
        float:none;
        text-align:left;

        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }
    #topMain li a {
        text-align:left;
        border:0;
        height:auto;
        line-height:15px;

        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }
    #topMain>li:hover,
    #topMain>li:hover>a {
        border-top:0 !important;
    }
    #topMain>li>a {
        height:auto;
        line-height:auto;
    }

    /* submenu */
    #topMain ul.dropdown-menu {
        position: static;
        clear: both;
        float: none;
        display: none !important;
        border-left:0 !important;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #topNav nav.nav-main li.resp-active > ul.dropdown-menu {
        display: block !important;
        margin-left:30px;
        margin-right:30px;
        padding:20px 0;
        border-right:0;
    }
    #topNav nav.nav-main li.resp-active > ul.dropdown-menu li {
        border-left:0;
    }

    #topNav ul.nav>li:hover>a:before,
    #topNav ul.nav>li.active>a:before {
        background-color:transparent;
    }

    #topNav ul.dropdown-menu>li:hover > ul.dropdown-menu {
        position:static;
    }

    #topNav div.submenu-dark ul.dropdown-menu {
        border-top:0;
    }

    /** sub menu */
    #topNav nav.nav-main li.resp-active > ul.dropdown-menu {
        margin:0; padding:0;
    }
    #topNav nav.nav-main li > ul.dropdown-menu li a {
        padding-left:40px;
    }

    #topNav .dropdown-menu.pull-right,
    #topNav .dropdown-menu.pull-left {
        float:none !important;
    }
}

@media only screen and (max-width: 500px) {
    #topNav div.nav-main-collapse {
        max-height:290px;
        overflow-y:auto;
    }
}

/** Quick Shop Cart
	 ********************** **/
#header li.quick-cart .quick-cart-box {
    display:none;
    right: 0;
    left:auto;
    top: 100%;
    padding:10px 0;
    background-color: #fff;
    position: absolute;
    box-shadow: 5px 5px rgba(91, 91, 91, 0.2);
    width: 274px;
    margin-top: 36px;
    z-index: 22;
}
#header.fixed  li.quick-cart .quick-cart-box {
    /*margin-top:18px;*/
}
#header.fixed.header-sm li.quick-cart .quick-cart-box {
    /*margin-top:18px;*/
}
#header.fixed.header-md li.quick-cart .quick-cart-box {
    /*margin-top:15px;*/
}

#header li.quick-cart .quick-cart-wrapper {
    max-height:400px;
    overflow-y:auto;
}
/*#header li.quick-cart .quick-cart-wrapper.block {*/
/*max-height:400px;*/
/*overflow-y:auto;*/
/*}*/
#header li.quick-cart .quick-cart-box h4 {
    font-size:17px;
    margin:0;
    padding:0 10px 10px 10px;
    border-bottom:rgba(0,0,0,0.1) 1px solid;
}
#header li.quick-cart .quick-cart-box a {
    display:block;
    padding:15px 10px;
    border-bottom:rgba(0,0,0,0.04) 1px solid;
}
#header li.quick-cart .quick-cart-box a:hover {
    background-color:rgba(0,0,0,0.03);
}
#header li.quick-cart .quick-cart-box a>img {
    float:left;
    margin-right:10px;
}
#header li.quick-cart .quick-cart-box a h6 {
    margin:0;
    padding:4px 0 0 0;

    text-overflow:ellipsis;
    white-space: nowrap;
    overflow:hidden;
}
#header li.quick-cart .quick-cart-box a.btn {
    background-color:#151515;
    border:0; margin:0;
    padding-top:6px;
    padding-bottom:4px;
}
#header li.quick-cart .quick-cart-box a.btn.btn-danger {
    background-color:rgba(230,90,70,0.8);
}
#header li.quick-cart .quick-cart-footer {
    padding:10px 10px 0 10px;
}
#header li.quick-cart .quick-cart-footer>span {
    display:inline-block;
    padding-top:3px;
    background-color:rgba(0,0,0,0.05);
    padding: 4px 3px;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

@media only screen and (min-width: 992px) { /* min-width */
    #header li.quick-cart .quick-cart-box {
        top:21px;
    }
    #header.header-sm li.quick-cart .quick-cart-box {
        margin-top:19px;
    }
    #header.header-md li.quick-cart .quick-cart-box {
        margin-top:26px;
    }
    #header li.quick-cart .quick-cart-wrapper {
        max-height:300px;
        overflow-y:auto;
    }
}

@media only screen and (max-width: 992px) { /* max-width */
    #header li.quick-cart .quick-cart-box {
        margin-top:18px;
    }
    #header.dark li.search .search-box {
        margin-top:38px !important;
    }

}
@media only screen and (max-width: 769px) {
    #header li.quick-cart .quick-cart-box {
        position:fixed;
        width:100%;
        left:0; right:0;
        top:60px;
        margin-top:0;
        border:rgba(0,0,0,0.08) 1px solid !important;
    }
    #header.dark li.quick-cart .quick-cart-box {
        border:rgba(255,255,255,0.08) 1px solid;
    }
    #header li.quick-cart .quick-cart-wrapper {
        max-height:200px;
        overflow-y:auto;
    }

    /**
            Quick Cart & top Search Fix (if #topBar exists).
            .has-topBar - added by Javascript
        **/
    #header ul.has-topBar>li.quick-cart .quick-cart-box,
    #header ul.has-topBar>li.search .search-box {
        top:98px !important;
    }
}

.badge.badge-corner {
    top: -8px !important;
    right: -6px !important;
    position: absolute !important;
    color: #fff !important;
}