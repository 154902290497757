.sidebar-textwidget {
	.sidebar-logo-wraper {
		margin-bottom: 33px;
	}
	> p {
		font-size: rem(15px);
		line-height: 1.5;
		margin-bottom: 40px;
	}
	.social-list {
		margin-bottom: 30px;
	}
}
.sideabr-list-widget {
	margin-bottom: 40px;
	li {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0px;
		}
	}
	img {
		margin-right: 15px;
		margin-top: 3px;
	}
	p , a {
		margin-bottom: 2px;
		color: #000000;
		margin-bottom: 8px;
		display: block;
	}
	span {
		display: block;
		font-size: rem(14px);
		color: #1768dd;
	}
	.media-body {
		line-height: 1;
	}
}

.subscribe-from {
	position: relative;
	margin-bottom: 40px;
	p {
		font-size: rem(18px);
		font-weight: 700;
		margin-bottom: 15px;
		line-height: 1;
		color: #1768dd;
	}
	label {
		position: absolute;
    bottom: -24px;
    left: 0px;
    margin-bottom: 0;
    line-height: 1;
	}
	.form-control {
		border: 1px solid #f5f5f5;
		height: 50px;
		padding-left: 25px;
	}
	.sub-btn {
		background-color: transparent;
		border: 0px;
		position: absolute;
    top: 0;
		right: 0;
		padding-right: 25px;
		cursor: pointer;
		color: #1768dd;
		height: 100%;
		&:focus {
			outline: none;
		}
	}
	.form-group {
		position: relative;
		overflow: hidden;
		box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
		border-radius: 25px;
		margin-bottom: 0px;
	}
}