/* Table of contents */

/*
1. predefine
2. nav
3. banner
4. modal
5. modal search
6. modal flag
7. offset sidebar
8. offset cart
9. offset info bar
10. domain search
11. why choose us
12. pricing
13. service
14. call to action
15. feature
16. clients
17. hosting information
18. testimonial
19. blog
20. footer
21. pricing feature
22. included feature
23. inner banner
24. contact info
25. form filed
26. intro video
27. office
28. team
29. included feature list
30. domain price
31. blog list
32. side bar
33. post single
34. comments area
35. cart
36. cart check out
37. VPS Slider
38. Dedicated Server

========================== element list start ========================
1. Element
2. Buttons
3. Social list
4. Select
5. Section title
6. nav tab
7. unorder list
8. breadCumb
9. accordion
10. map
11. custom control
12. pagination
13. prelaoder
14. Order list
15. sticky nav
16. location map
17. shuffle letter
18. owl dots
19. menu highlight badge
20. inheritance

*/

/* var list */

@font-face {
  font-family: 'themify';
  src: url("./../fonts/themify-icon/fonts/themify.woff") format("woff"), url("./../fonts/themify-icon/fonts/themify.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[class^="ti-"],
[class*=" ti-"] {
  font-family: "themify", sans-serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
  content: "\e600";
}

.ti-volume:before {
  content: "\e601";
}

.ti-user:before {
  content: "\e602";
}

.ti-unlock:before {
  content: "\e603";
}

.ti-unlink:before {
  content: "\e604";
}

.ti-trash:before {
  content: "\e605";
}

.ti-thought:before {
  content: "\e606";
}

.ti-target:before {
  content: "\e607";
}

.ti-tag:before {
  content: "\e608";
}

.ti-tablet:before {
  content: "\e609";
}

.ti-star:before {
  content: "\e60a";
}

.ti-spray:before {
  content: "\e60b";
}

.ti-signal:before {
  content: "\e60c";
}

.ti-shopping-cart:before {
  content: "\e60d";
}

.ti-shopping-cart-full:before {
  content: "\e60e";
}

.ti-settings:before {
  content: "\e60f";
}

.ti-search:before {
  content: "\e610";
}

.ti-zoom-in:before {
  content: "\e611";
}

.ti-zoom-out:before {
  content: "\e612";
}

.ti-cut:before {
  content: "\e613";
}

.ti-ruler:before {
  content: "\e614";
}

.ti-ruler-pencil:before {
  content: "\e615";
}

.ti-ruler-alt:before {
  content: "\e616";
}

.ti-bookmark:before {
  content: "\e617";
}

.ti-bookmark-alt:before {
  content: "\e618";
}

.ti-reload:before {
  content: "\e619";
}

.ti-plus:before {
  content: "\e61a";
}

.ti-pin:before {
  content: "\e61b";
}

.ti-pencil:before {
  content: "\e61c";
}

.ti-pencil-alt:before {
  content: "\e61d";
}

.ti-paint-roller:before {
  content: "\e61e";
}

.ti-paint-bucket:before {
  content: "\e61f";
}

.ti-na:before {
  content: "\e620";
}

.ti-mobile:before {
  content: "\e621";
}

.ti-minus:before {
  content: "\e622";
}

.ti-medall:before {
  content: "\e623";
}

.ti-medall-alt:before {
  content: "\e624";
}

.ti-marker:before {
  content: "\e625";
}

.ti-marker-alt:before {
  content: "\e626";
}

.ti-arrow-up:before {
  content: "\e627";
}

.ti-arrow-right:before {
  content: "\e628";
}

.ti-arrow-left:before {
  content: "\e629";
}

.ti-arrow-down:before {
  content: "\e62a";
}

.ti-lock:before {
  content: "\e62b";
}

.ti-location-arrow:before {
  content: "\e62c";
}

.ti-link:before {
  content: "\e62d";
}

.ti-layout:before {
  content: "\e62e";
}

.ti-layers:before {
  content: "\e62f";
}

.ti-layers-alt:before {
  content: "\e630";
}

.ti-key:before {
  content: "\e631";
}

.ti-import:before {
  content: "\e632";
}

.ti-image:before {
  content: "\e633";
}

.ti-heart:before {
  content: "\e634";
}

.ti-heart-broken:before {
  content: "\e635";
}

.ti-hand-stop:before {
  content: "\e636";
}

.ti-hand-open:before {
  content: "\e637";
}

.ti-hand-drag:before {
  content: "\e638";
}

.ti-folder:before {
  content: "\e639";
}

.ti-flag:before {
  content: "\e63a";
}

.ti-flag-alt:before {
  content: "\e63b";
}

.ti-flag-alt-2:before {
  content: "\e63c";
}

.ti-eye:before {
  content: "\e63d";
}

.ti-export:before {
  content: "\e63e";
}

.ti-exchange-vertical:before {
  content: "\e63f";
}

.ti-desktop:before {
  content: "\e640";
}

.ti-cup:before {
  content: "\e641";
}

.ti-crown:before {
  content: "\e642";
}

.ti-comments:before {
  content: "\e643";
}

.ti-comment:before {
  content: "\e644";
}

.ti-comment-alt:before {
  content: "\e645";
}

.ti-close:before {
  content: "\e646";
}

.ti-clip:before {
  content: "\e647";
}

.ti-angle-up:before {
  content: "\e648";
}

.ti-angle-right:before {
  content: "\e649";
}

.ti-angle-left:before {
  content: "\e64a";
}

.ti-angle-down:before {
  content: "\e64b";
}

.ti-check:before {
  content: "\e64c";
}

.ti-check-box:before {
  content: "\e64d";
}

.ti-camera:before {
  content: "\e64e";
}

.ti-announcement:before {
  content: "\e64f";
}

.ti-brush:before {
  content: "\e650";
}

.ti-briefcase:before {
  content: "\e651";
}

.ti-bolt:before {
  content: "\e652";
}

.ti-bolt-alt:before {
  content: "\e653";
}

.ti-blackboard:before {
  content: "\e654";
}

.ti-bag:before {
  content: "\e655";
}

.ti-move:before {
  content: "\e656";
}

.ti-arrows-vertical:before {
  content: "\e657";
}

.ti-arrows-horizontal:before {
  content: "\e658";
}

.ti-fullscreen:before {
  content: "\e659";
}

.ti-arrow-top-right:before {
  content: "\e65a";
}

.ti-arrow-top-left:before {
  content: "\e65b";
}

.ti-arrow-circle-up:before {
  content: "\e65c";
}

.ti-arrow-circle-right:before {
  content: "\e65d";
}

.ti-arrow-circle-left:before {
  content: "\e65e";
}

.ti-arrow-circle-down:before {
  content: "\e65f";
}

.ti-angle-double-up:before {
  content: "\e660";
}

.ti-angle-double-right:before {
  content: "\e661";
}

.ti-angle-double-left:before {
  content: "\e662";
}

.ti-angle-double-down:before {
  content: "\e663";
}

.ti-zip:before {
  content: "\e664";
}

.ti-world:before {
  content: "\e665";
}

.ti-wheelchair:before {
  content: "\e666";
}

.ti-view-list:before {
  content: "\e667";
}

.ti-view-list-alt:before {
  content: "\e668";
}

.ti-view-grid:before {
  content: "\e669";
}

.ti-uppercase:before {
  content: "\e66a";
}

.ti-upload:before {
  content: "\e66b";
}

.ti-underline:before {
  content: "\e66c";
}

.ti-truck:before {
  content: "\e66d";
}

.ti-timer:before {
  content: "\e66e";
}

.ti-ticket:before {
  content: "\e66f";
}

.ti-thumb-up:before {
  content: "\e670";
}

.ti-thumb-down:before {
  content: "\e671";
}

.ti-text:before {
  content: "\e672";
}

.ti-stats-up:before {
  content: "\e673";
}

.ti-stats-down:before {
  content: "\e674";
}

.ti-split-v:before {
  content: "\e675";
}

.ti-split-h:before {
  content: "\e676";
}

.ti-smallcap:before {
  content: "\e677";
}

.ti-shine:before {
  content: "\e678";
}

.ti-shift-right:before {
  content: "\e679";
}

.ti-shift-left:before {
  content: "\e67a";
}

.ti-shield:before {
  content: "\e67b";
}

.ti-notepad:before {
  content: "\e67c";
}

.ti-server:before {
  content: "\e67d";
}

.ti-quote-right:before {
  content: "\e67e";
}

.ti-quote-left:before {
  content: "\e67f";
}

.ti-pulse:before {
  content: "\e680";
}

.ti-printer:before {
  content: "\e681";
}

.ti-power-off:before {
  content: "\e682";
}

.ti-plug:before {
  content: "\e683";
}

.ti-pie-chart:before {
  content: "\e684";
}

.ti-paragraph:before {
  content: "\e685";
}

.ti-panel:before {
  content: "\e686";
}

.ti-package:before {
  content: "\e687";
}

.ti-music:before {
  content: "\e688";
}

.ti-music-alt:before {
  content: "\e689";
}

.ti-mouse:before {
  content: "\e68a";
}

.ti-mouse-alt:before {
  content: "\e68b";
}

.ti-money:before {
  content: "\e68c";
}

.ti-microphone:before {
  content: "\e68d";
}

.ti-menu:before {
  content: "\e68e";
}

.ti-menu-alt:before {
  content: "\e68f";
}

.ti-map:before {
  content: "\e690";
}

.ti-map-alt:before {
  content: "\e691";
}

.ti-loop:before {
  content: "\e692";
}

.ti-location-pin:before {
  content: "\e693";
}

.ti-list:before {
  content: "\e694";
}

.ti-light-bulb:before {
  content: "\e695";
}

.ti-Italic:before {
  content: "\e696";
}

.ti-info:before {
  content: "\e697";
}

.ti-infinite:before {
  content: "\e698";
}

.ti-id-badge:before {
  content: "\e699";
}

.ti-hummer:before {
  content: "\e69a";
}

.ti-home:before {
  content: "\e69b";
}

.ti-help:before {
  content: "\e69c";
}

.ti-headphone:before {
  content: "\e69d";
}

.ti-harddrives:before {
  content: "\e69e";
}

.ti-harddrive:before {
  content: "\e69f";
}

.ti-gift:before {
  content: "\e6a0";
}

.ti-game:before {
  content: "\e6a1";
}

.ti-filter:before {
  content: "\e6a2";
}

.ti-files:before {
  content: "\e6a3";
}

.ti-file:before {
  content: "\e6a4";
}

.ti-eraser:before {
  content: "\e6a5";
}

.ti-envelope:before {
  content: "\e6a6";
}

.ti-download:before {
  content: "\e6a7";
}

.ti-direction:before {
  content: "\e6a8";
}

.ti-direction-alt:before {
  content: "\e6a9";
}

.ti-dashboard:before {
  content: "\e6aa";
}

.ti-control-stop:before {
  content: "\e6ab";
}

.ti-control-shuffle:before {
  content: "\e6ac";
}

.ti-control-play:before {
  content: "\e6ad";
}

.ti-control-pause:before {
  content: "\e6ae";
}

.ti-control-forward:before {
  content: "\e6af";
}

.ti-control-backward:before {
  content: "\e6b0";
}

.ti-cloud:before {
  content: "\e6b1";
}

.ti-cloud-up:before {
  content: "\e6b2";
}

.ti-cloud-down:before {
  content: "\e6b3";
}

.ti-clipboard:before {
  content: "\e6b4";
}

.ti-car:before {
  content: "\e6b5";
}

.ti-calendar:before {
  content: "\e6b6";
}

.ti-book:before {
  content: "\e6b7";
}

.ti-bell:before {
  content: "\e6b8";
}

.ti-basketball:before {
  content: "\e6b9";
}

.ti-bar-chart:before {
  content: "\e6ba";
}

.ti-bar-chart-alt:before {
  content: "\e6bb";
}

.ti-back-right:before {
  content: "\e6bc";
}

.ti-back-left:before {
  content: "\e6bd";
}

.ti-arrows-corner:before {
  content: "\e6be";
}

.ti-archive:before {
  content: "\e6bf";
}

.ti-anchor:before {
  content: "\e6c0";
}

.ti-align-right:before {
  content: "\e6c1";
}

.ti-align-left:before {
  content: "\e6c2";
}

.ti-align-justify:before {
  content: "\e6c3";
}

.ti-align-center:before {
  content: "\e6c4";
}

.ti-alert:before {
  content: "\e6c5";
}

.ti-alarm-clock:before {
  content: "\e6c6";
}

.ti-agenda:before {
  content: "\e6c7";
}

.ti-write:before {
  content: "\e6c8";
}

.ti-window:before {
  content: "\e6c9";
}

.ti-widgetized:before {
  content: "\e6ca";
}

.ti-widget:before {
  content: "\e6cb";
}

.ti-widget-alt:before {
  content: "\e6cc";
}

.ti-wallet:before {
  content: "\e6cd";
}

.ti-video-clapper:before {
  content: "\e6ce";
}

.ti-video-camera:before {
  content: "\e6cf";
}

.ti-vector:before {
  content: "\e6d0";
}

.ti-themify-logo:before {
  content: "\e6d1";
}

.ti-themify-favicon:before {
  content: "\e6d2";
}

.ti-themify-favicon-alt:before {
  content: "\e6d3";
}

.ti-support:before {
  content: "\e6d4";
}

.ti-stamp:before {
  content: "\e6d5";
}

.ti-split-v-alt:before {
  content: "\e6d6";
}

.ti-slice:before {
  content: "\e6d7";
}

.ti-shortcode:before {
  content: "\e6d8";
}

.ti-shift-right-alt:before {
  content: "\e6d9";
}

.ti-shift-left-alt:before {
  content: "\e6da";
}

.ti-ruler-alt-2:before {
  content: "\e6db";
}

.ti-receipt:before {
  content: "\e6dc";
}

.ti-pin2:before {
  content: "\e6dd";
}

.ti-pin-alt:before {
  content: "\e6de";
}

.ti-pencil-alt2:before {
  content: "\e6df";
}

.ti-palette:before {
  content: "\e6e0";
}

.ti-more:before {
  content: "\e6e1";
}

.ti-more-alt:before {
  content: "\e6e2";
}

.ti-microphone-alt:before {
  content: "\e6e3";
}

.ti-magnet:before {
  content: "\e6e4";
}

.ti-line-double:before {
  content: "\e6e5";
}

.ti-line-dotted:before {
  content: "\e6e6";
}

.ti-line-dashed:before {
  content: "\e6e7";
}

.ti-layout-width-full:before {
  content: "\e6e8";
}

.ti-layout-width-default:before {
  content: "\e6e9";
}

.ti-layout-width-default-alt:before {
  content: "\e6ea";
}

.ti-layout-tab:before {
  content: "\e6eb";
}

.ti-layout-tab-window:before {
  content: "\e6ec";
}

.ti-layout-tab-v:before {
  content: "\e6ed";
}

.ti-layout-tab-min:before {
  content: "\e6ee";
}

.ti-layout-slider:before {
  content: "\e6ef";
}

.ti-layout-slider-alt:before {
  content: "\e6f0";
}

.ti-layout-sidebar-right:before {
  content: "\e6f1";
}

.ti-layout-sidebar-none:before {
  content: "\e6f2";
}

.ti-layout-sidebar-left:before {
  content: "\e6f3";
}

.ti-layout-placeholder:before {
  content: "\e6f4";
}

.ti-layout-menu:before {
  content: "\e6f5";
}

.ti-layout-menu-v:before {
  content: "\e6f6";
}

.ti-layout-menu-separated:before {
  content: "\e6f7";
}

.ti-layout-menu-full:before {
  content: "\e6f8";
}

.ti-layout-media-right-alt:before {
  content: "\e6f9";
}

.ti-layout-media-right:before {
  content: "\e6fa";
}

.ti-layout-media-overlay:before {
  content: "\e6fb";
}

.ti-layout-media-overlay-alt:before {
  content: "\e6fc";
}

.ti-layout-media-overlay-alt-2:before {
  content: "\e6fd";
}

.ti-layout-media-left-alt:before {
  content: "\e6fe";
}

.ti-layout-media-left:before {
  content: "\e6ff";
}

.ti-layout-media-center-alt:before {
  content: "\e700";
}

.ti-layout-media-center:before {
  content: "\e701";
}

.ti-layout-list-thumb:before {
  content: "\e702";
}

.ti-layout-list-thumb-alt:before {
  content: "\e703";
}

.ti-layout-list-post:before {
  content: "\e704";
}

.ti-layout-list-large-image:before {
  content: "\e705";
}

.ti-layout-line-solid:before {
  content: "\e706";
}

.ti-layout-grid4:before {
  content: "\e707";
}

.ti-layout-grid3:before {
  content: "\e708";
}

.ti-layout-grid2:before {
  content: "\e709";
}

.ti-layout-grid2-thumb:before {
  content: "\e70a";
}

.ti-layout-cta-right:before {
  content: "\e70b";
}

.ti-layout-cta-left:before {
  content: "\e70c";
}

.ti-layout-cta-center:before {
  content: "\e70d";
}

.ti-layout-cta-btn-right:before {
  content: "\e70e";
}

.ti-layout-cta-btn-left:before {
  content: "\e70f";
}

.ti-layout-column4:before {
  content: "\e710";
}

.ti-layout-column3:before {
  content: "\e711";
}

.ti-layout-column2:before {
  content: "\e712";
}

.ti-layout-accordion-separated:before {
  content: "\e713";
}

.ti-layout-accordion-merged:before {
  content: "\e714";
}

.ti-layout-accordion-list:before {
  content: "\e715";
}

.ti-ink-pen:before {
  content: "\e716";
}

.ti-info-alt:before {
  content: "\e717";
}

.ti-help-alt:before {
  content: "\e718";
}

.ti-headphone-alt:before {
  content: "\e719";
}

.ti-hand-point-up:before {
  content: "\e71a";
}

.ti-hand-point-right:before {
  content: "\e71b";
}

.ti-hand-point-left:before {
  content: "\e71c";
}

.ti-hand-point-down:before {
  content: "\e71d";
}

.ti-gallery:before {
  content: "\e71e";
}

.ti-face-smile:before {
  content: "\e71f";
}

.ti-face-sad:before {
  content: "\e720";
}

.ti-credit-card:before {
  content: "\e721";
}

.ti-control-skip-forward:before {
  content: "\e722";
}

.ti-control-skip-backward:before {
  content: "\e723";
}

.ti-control-record:before {
  content: "\e724";
}

.ti-control-eject:before {
  content: "\e725";
}

.ti-comments-smiley:before {
  content: "\e726";
}

.ti-brush-alt:before {
  content: "\e727";
}

.ti-youtube:before {
  content: "\e728";
}

.ti-vimeo:before {
  content: "\e729";
}

.ti-twitter:before {
  content: "\e72a";
}

.ti-time:before {
  content: "\e72b";
}

.ti-tumblr:before {
  content: "\e72c";
}

.ti-skype:before {
  content: "\e72d";
}

.ti-share:before {
  content: "\e72e";
}

.ti-share-alt:before {
  content: "\e72f";
}

.ti-rocket:before {
  content: "\e730";
}

.ti-pinterest:before {
  content: "\e731";
}

.ti-new-window:before {
  content: "\e732";
}

.ti-microsoft:before {
  content: "\e733";
}

.ti-list-ol:before {
  content: "\e734";
}

.ti-linkedin:before {
  content: "\e735";
}

.ti-layout-sidebar-2:before {
  content: "\e736";
}

.ti-layout-grid4-alt:before {
  content: "\e737";
}

.ti-layout-grid3-alt:before {
  content: "\e738";
}

.ti-layout-grid2-alt:before {
  content: "\e739";
}

.ti-layout-column4-alt:before {
  content: "\e73a";
}

.ti-layout-column3-alt:before {
  content: "\e73b";
}

.ti-layout-column2-alt:before {
  content: "\e73c";
}

.ti-instagram:before {
  content: "\e73d";
}

.ti-google:before {
  content: "\e73e";
}

.ti-github:before {
  content: "\e73f";
}

.ti-flickr:before {
  content: "\e740";
}

.ti-facebook:before {
  content: "\e741";
}

.ti-dropbox:before {
  content: "\e742";
}

.ti-dribbble:before {
  content: "\e743";
}

.ti-apple:before {
  content: "\e744";
}

.ti-android:before {
  content: "\e745";
}

.ti-save:before {
  content: "\e746";
}

.ti-save-alt:before {
  content: "\e747";
}

.ti-yahoo:before {
  content: "\e748";
}

.ti-wordpress:before {
  content: "\e749";
}

.ti-vimeo-alt:before {
  content: "\e74a";
}

.ti-twitter-alt:before {
  content: "\e74b";
}

.ti-tumblr-alt:before {
  content: "\e74c";
}

.ti-trello:before {
  content: "\e74d";
}

.ti-stack-overflow:before {
  content: "\e74e";
}

.ti-soundcloud:before {
  content: "\e74f";
}

.ti-sharethis:before {
  content: "\e750";
}

.ti-sharethis-alt:before {
  content: "\e751";
}

.ti-reddit:before {
  content: "\e752";
}

.ti-pinterest-alt:before {
  content: "\e753";
}

.ti-microsoft-alt:before {
  content: "\e754";
}

.ti-linux:before {
  content: "\e755";
}

.ti-jsfiddle:before {
  content: "\e756";
}

.ti-joomla:before {
  content: "\e757";
}

.ti-html5:before {
  content: "\e758";
}

.ti-flickr-alt:before {
  content: "\e759";
}

.ti-email:before {
  content: "\e75a";
}

.ti-drupal:before {
  content: "\e75b";
}

.ti-dropbox-alt:before {
  content: "\e75c";
}

.ti-css3:before {
  content: "\e75d";
}

.ti-rss:before {
  content: "\e75e";
}

.ti-rss-alt:before {
  content: "\e75f";
}

/* mixing */

/* function */

/*--------------------------------------------------============================================================
										1. predefine
=================================================--------------------------------------------------------------*/

/*
Author: XpeedStudio
Author URI: http://themeforest.net/user/XpeedStudio/portfolio
*/

/* ==========================================================================
   Author's custom styles
========================================================================== */

:root {
  font-size: 15px;
}

body {
  font-family: "Poppins", Roboto, Helvetica Neue, Calibri, sans-serif;
  font-weight: 400;
  line-height: 1.48;
  color: #1A2A3A;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

iframe {
  border: none;
}

a,
b,
div,
ul,
li {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -moz-outline-: none;
}

a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  -moz-outline: none;
  outline: none;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  outline: 0;
}

a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0;
}

img {
  border: none;
  max-width: 100%;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clear-both:before,
.clear-both:after {
  display: table;
  content: "";
  clear: both;
}

/*--------------------------------------------------============================================================
										2. nav
=================================================--------------------------------------------------------------*/

/* top bar */

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.header-transparent .xs-top-bar {
  background-color: rgba(0, 0, 0, 0.25);
}

.header-transparent .xs-menus .nav-menu > li > a {
  color: #FFFFFF;
}

.header-transparent .xs-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  background-color: rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: 0 -1px 1px rgba(55, 95, 155, 0.16);
}

.header-transparent .xs-menu-tools > li > a {
  color: #FFFFFF;
}

.header-transparent .xs-menus .nav-menu > li > a::before {
  background-color: #fefefe;
}

.xs-top-bar {
  background-color: black;
  padding: 15px 0;
  line-height: normal;
}

.xs-top-bar.topbar-v2 {
  background-color: rgba(0, 0, 0, 0.102);
}

.xs-top-bar-info li {
  display: inline-block;
  font-size: 1rem;
  margin-right: 58px;
}

.xs-top-bar-info li:last-child {
  margin-right: 0px;
}

.xs-top-bar-info li p,
.xs-top-bar-info li a {
  color: #FFFFFF;
}

.xs-top-bar-info li p {
  margin-bottom: 0px;
}

.xs-top-bar-info li i {
  padding-right: 8px;
}

.top-menu {
  text-align: right;
}

.top-menu li {
  display: inline-block;
  margin-right: 30px;
}

.top-menu li:last-child {
  margin-right: 0px;
}

.top-menu li a {
  color: #FFFFFF;
  font-size: 1rem;
}

.top-menu li a i {
  font-size: 0.93333333rem;
  position: relative;
  top: 2px;
  padding-right: 5px;
}

.xs-menus .nav-menu > li {
  margin-right: 53px;
}

.xs-menus .nav-menu > li:last-child {
  margin-right: 0px;
}

.xs-menus .nav-menu > li > a {
  color: #23293A;
  font-size: 1.13333333rem;
  font-size: 1.2rem;
  letter-spacing: -0.7px;
  word-spacing: -0.4px;
  padding: 30px 0px;
  position: relative;
}

.xs-menus .nav-menu > li > a::before {
  position: absolute;
  content: "";
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #23293A;
  transition: all 0.4s ease;
  transform: scale(0, 0);
  transform-origin: center;
}

.xs-menus .nav-menu > li > a .submenu-indicator-chevron {
  border-color: transparent #FFFFFF #FFFFFF transparent;
}

.xs-menus .nav-menu .xs-icon-menu .single-menu-item a {
  display: block;
  color: #141414;
  font-size: 1.13333333rem;
}

.xs-menus .nav-menu .xs-icon-menu .single-menu-item a:hover {
  color: #1169DA;
}

.xs-menus .nav-menu .xs-icon-menu .single-menu-item a i {
  font-size: 1.13333333rem;
  position: relative;
  top: 3px;
  padding-right: 18px;
}

.xs-menus .nav-menu .xs-icon-menu .single-menu-item:not(:last-child) a {
  padding-bottom: 20px;
  border-bottom: 1px solid #f7f7f7;
}

.xs-menus .nav-menu .xs-icon-menu .single-menu-item:not(:first-child) a {
  padding-top: 20px;
}

.xs-menus .nav-menu .xs-service-menu a {
  transition: all 0.4s ease;
  transform: scale(1);
  display: block;
  padding: 15px;
}

.xs-menus .nav-menu .xs-service-menu a:hover {
  transform: scale(1.05);
  border-color: rgba(3, 27, 78, 0.1);
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
}

.xs-menus .nav-menu .xs-service-menu a:hover .media h4 {
  color: #1169DA;
}

.xs-menus .nav-menu .xs-service-menu a .media img {
  margin-right: 30px;
}

.xs-menus .nav-menu .xs-service-menu a .media h4 {
  color: #000000;
  font-size: 1.13333333rem;
  margin-bottom: 6px;
  transition: all 0.4s ease;
}

.xs-menus .nav-menu .xs-service-menu a .media p {
  color: #707070;
  font-size: 0.93333333rem;
  margin-bottom: 0px;
}

.xs-menus .nav-menu .xs-service-menu [class*=col-]:first-child::before {
  position: absolute;
  content: "";
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 280px;
  background-color: #f7f7f7;
}

.xs-menus .nav-menu .home-menu-panel a img {
  box-shadow: 0px 5px 15px 0px rgba(23, 104, 221, 0.1);
  margin-bottom: 10px;
}

.xs-menus .nav-menu .home-menu-panel a h4 {
  font-size: 1.13333333rem;
  margin-bottom: 0px;
}

.xs-menus .nav-menu .home-menu-panel > .row:not(:last-child) {
  margin-bottom: 20px;
}

.xs-menus .nav-submenu {
  box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
}

.xs-menus .nav-submenu::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  transform: translateX(-50%) rotate(45deg);
  width: 30px;
  height: 30px;
}

.xs-menus .megamenu-panel.xs-icon-menu {
  padding: 50px;
}

.xs-menus .megamenu-panel.xs-service-menu {
  padding: 35px;
}

.xs-menus .nav-menu > li > a:hover::before,
.xs-menus .nav-menu > li:hover > a::before {
  transform: scale(1, 1);
}

.xs-header {
  border-bottom: 1px solid rgba(35, 41, 58, 0.2);
}

.xs-header .xs-logo {
  display: block;
  padding: 0;
  margin-left: -2px;
}

.xs-header .xs-logo img {
  width: 100%;
  max-width: 220px;
  max-height: 80px;
}

.xs-header.header-boxed {
  border-bottom: 0px;
}

.xs-header.header-boxed > .container {
  position: relative;
}

.xs-header.header-boxed > .container::before {
  position: absolute;
  content: "";
  top: 0;
  left: -32px;
  height: 100%;
  width: calc(100% + 64px);
  box-shadow: -4px 6.928px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 5px 5px;
  background-color: #FFFFFF;
  transition: all 0.4s ease;
}

.xs-header.header-boxed .xs-menu-tools > li > a {
  color: #141414;
}

.xs-header.header-boxed .xs-logo {
  padding: 30px 0;
}

.xs-header.header-boxed .xs-menu-tools {
  padding: 36px 0;
}

.xs-header.header-boxed .xs-menus .nav-menu > li > a {
  color: #141414;
  padding: 40px 0px;
}

.xs-header.header-boxed .xs-menus .nav-menu > li > a .submenu-indicator-chevron {
  border-color: transparent #777777 #777777 transparent;
}

.xs-header.header-v3 {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: -4px 6.928px 25px 0px rgba(0, 0, 0, 0.1);
  border-bottom: 0px;
}

.xs-header.header-v3 .xs-logo {
  padding: 30px 0;
}

.xs-header.header-v3 .xs-menu-tools {
  padding: 36px 0;
}

.xs-header.header-v3 .xs-menus .nav-menu > li > a {
  padding: 40px 0px;
}

.xs-header.header-minimal .xs-logo {
  padding: 30px 0;
}

.xs-header.header-minimal .xs-menus .nav-menu > li:not(:last-child) {
  margin-right: 35px;
}

.xs-header.header-minimal .xs-menus .nav-menu > li > a {
  padding: 39px 0px;
}

.xs-header.header-minimal .xs-menu-tools {
  padding: 38px 0;
}

.xs_nav-landscape .nav-brand {
  display: none;
}

.xs-menu-tools {
  text-align: right;
  padding: 26px 0;
}

.xs-menu-tools > li {
  display: inline-block;
  margin-right: 25px;
}

.xs-menu-tools > li:last-child {
  margin-right: 0px;
}

.xs-menu-tools > li > a {
  color: #23293A;
  display: block;
  position: relative;
}

.xs-menu-tools > li > a .item-count {
  position: absolute;
  top: 0;
  right: -5px;
  display: block;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background-color: #FFFFFF;
  color: #4169ff;
  font-size: 0.53333333rem;
  border-radius: 100%;
  text-align: center;
}

.home-menu-panel a {
  display: block;
  text-align: center;
}

.home-menu-panel a img {
  margin-bottom: 20px;
}

.home-menu-panel a h4 {
  font-size: 1.13333333rem;
  margin-bottom: 0px;
}

.xs-menus.xs_nav-landscape .nav-menu {
  padding-right: 12px;
}

.xs-menus.xs_nav-portrait .nav-submenu {
  box-shadow: none;
}

.xs-menus.xs_nav-portrait .nav-menu .xs-icon-menu .single-menu-item:not(:first-child) a {
  padding-bottom: 20px;
  border-bottom: 1px solid #f7f7f7;
}

.xs-menus.xs_nav-portrait .nav-menu .xs-icon-menu .single-menu-item:not(:last-child) a {
  padding-top: 20px;
}

.xs-menus.xs_nav-portrait .megamenu-panel.xs-icon-menu {
  padding: 30px;
}

.xs-menus.xs_nav-portrait .home-menu-panel a {
  margin-bottom: 25px;
}

.xs-menus.xs_nav-portrait .nav-menu .home-menu-panel > .row:not(:last-child) {
  margin-bottom: 0px;
}

.xs_nav-portrait {
  height: 85px;
  transition-duration: 0.3s;
}

.xs_nav-portrait .nav-header {
  margin-top: 1px;
  transition-duration: 0.3s;
}

.xs_nav-portrait .nav-brand {
  line-height: 0;
  max-width: 250px;
  max-height: 85px;
  display: block;
  padding: 0;
  transition-duration: 0.3s;
}

.xs_nav-portrait .nav-brand img {
  max-height: 85px;
  max-width: 230px;
  transition-duration: 0.3s;
}

.sticky-header .xs_nav-portrait {
  transition-duration: 0.3s;
}

.sticky-header .xs_nav-portrait .nav-brand {
  max-width: 185px;
  max-height: 50px;
  transition-duration: 0.3s;
}

.sticky-header .xs_nav-portrait .nav-brand img {
  max-height: 65px;
  max-width: 175px;
  transition-duration: 0.3s;
}

.submenu-indicator {
  margin-top: 8px;
}

/* header dark */

.header-dark {
  background-color: rgba(255, 255, 255, 0.7);
}

.header-dark .xs-header .xs-logo {
  padding: 30px 0;
}

.header-dark .xs-menus .nav-menu > li:not(:last-child) {
  margin-right: 35px;
}

.header-dark .xs-menus .nav-menu > li > a {
  color: #000000;
  font-size: 1.06666667rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  padding: 41px 0;
}

.header-dark .xs-menus .nav-menu > li > a .submenu-indicator {
  margin-top: 3px;
}

.header-dark .xs-menus .nav-menu > li > a .submenu-indicator-chevron {
  border-color: transparent #000000 #000000 transparent;
}

.header-dark .xs-menus .nav-menu > li > a::before {
  background-color: #000000;
}

.header-dark .xs-menu-tools {
  padding: 36px 0;
  transition: all 0.4s ease;
}

.header-dark .xs-menu-tools > li:not(:last-child) {
  margin-right: 18px;
}

.header-dark .xs-menu-tools > li > a {
  color: #000000;
  font-size: 1.2rem;
}

.header-dark .xs-menu-tools > li > a .item-count {
  background-color: #000000;
  color: #FFFFFF;
  transition: all 0.4s ease;
}

.header-dark.nav-sticky.sticky-header .xs-menu-tools > li > a .item-count {
  background-color: #FFFFFF;
  color: #1169DA;
}

.xs-menus .megamenu-panel.xs-service-menu.megamenu-small {
  background-color: transparent;
  border-top: solid 1px transparent;
  box-shadow: none;
  padding: 0px;
}

.xs-menus .megamenu-panel.xs-service-menu.megamenu-small .megamenu-container {
  border-top: solid 1px #f0f0f0;
  background-color: #fdfdfd;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
  box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
  padding: 35px;
}

/*--------------------------------------------------============================================================
										3. banner
=================================================--------------------------------------------------------------*/

/* banner */

.xs-banner {
  background: #1045db;
  background: -webkit-linear-gradient(135deg, #1045db 0%, #15095e 60%, #15095e 99%);
  background: linear-gradient(-45deg, #1045db 0%, #15095e 60%, #15095e 99%);
  min-height: 100vh;
  min-height: 99.5vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.xs-banner .xs-banner-image {
  margin-left: -95px;
  margin-right: -126px;
  position: relative;
}

.xs-banner.banner-v2 {
  background: linear-gradient(45deg, #0a9ffe 0%, #0a9ffe 35%, #1124d9 70%, #1124d9 100%);
}

.xs-banner.banner-v3 {
  min-height: 800px;
}

.xs-banner.banner-404 .xs-banner-image {
  margin-left: 0px;
  margin-right: 0px;
}

.xs-banner .icon-bg {
  position: absolute;
  left: 0;
  top: 30%;
  width: 100%;
  height: 532px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  z-index: -1;
}

.xs-banner.banner-v4 {
  background: #1633ff;
  background: -webkit-linear-gradient(bottom, #1633ff 0%, #0069ff 100%);
  background: linear-gradient(to top, #1633ff 0%, #0069ff 100%);
}

.xs-banner.banner-v4::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  background-size: cover;
  pointer-events: none;
  z-index: 1;
}

.xs-banner.banner-v4::before {
  background-image: url(./../images/welcome/horizontal-shape-2.png);
  height: 100%;
}

.xs-banner.banner-v4 .banner-title {
  font-size: 3.70133333rem;
  font-weight: 500;
  margin-bottom: 30px;
}

.xs-banner.banner-v4 p {
  font-size: 1.066rem;
}

.xs-banner.banner-v5 .xs-banner-content {
  text-align: center;
}

.xs-banner.banner-v5 .xs-banner-content .banner-title {
  font-size: 3.20333333rem;
  margin-bottom: 50px;
}

.xs-banner .wave_animation_wraper {
  position: absolute;
  bottom: 0;
  left: -70px;
  width: calc(100% + 200px);
  z-index: -1;
}

.xs-banner .wave_animation_wraper img {
  width: 100%;
  position: relative;
}

.xs-banner .wave_animation_wraper .one {
  bottom: -70px;
}

.xs-banner .wave_animation_wraper .two {
  width: 774px;
  right: -240px;
  margin-left: auto;
  margin-top: -400px;
}

.xs-banner .wave_animation_wraper .three {
  right: -50px;
  width: 767px;
  margin-left: auto;
  margin-top: -300px;
}

.xs-banner.banner-v6 {
  min-height: 1110px;
  background: none;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  overflow: visible;
}

.xs-banner.banner-v6 .xs-banner-image {
  margin-left: -60px;
  margin-right: -10px;
  left: 147px;
  margin-top: -190px;
}

.xs-banner.banner-v6 .xs-domain-search {
  padding-top: 100px;
}

.xs-banner.banner-v7 {
  background: none;
  overflow: visible;
  min-height: 815px;
}

.xs-banner.banner-v7 + .pricing-section {
  margin-top: 90px;
}

.xs-banner.banner-v7::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #150773;
  background: linear-gradient(110deg, rgba(21, 7, 115, 0.8) 0%, rgba(83, 51, 237, 0.8) 54%, rgba(44, 212, 217, 0.8) 100%);
}

.xs-banner.banner-v7 .welcome-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.xs-banner.banner-v7 .welcome-bg {
  z-index: -1;
}

.xs-banner.banner-v7 .xs-banner-image {
  pointer-events: none;
  bottom: -256px;
  z-index: 2;
}

.xs-banner.banner-v7 .banner-6-content {
  padding-top: 0px;
}

.xs-banner.banner-v8 {
  display: block;
  background: none;
  background-color: #24146d;
  height: 100vh;
  height: 99.5vh;
}

.xs-banner.banner-v8 > .container {
  padding-top: 200px;
}

.xs-banner.banner-v8 .xs-banner-content {
  margin-top: -125px;
}

.xs-banner.banner-v8 .xs-banner-image {
  margin-left: 25px;
  margin-right: -65px;
  margin-top: -40px;
  left: 20px;
}

.xs-banner.banner-v8 + .pricing-section {
  margin-top: -430px;
  position: relative;
  z-index: 2;
}

.xs-banner.banner-v8 + .pricing-section .shuufle-letter-title {
  color: rgba(255, 255, 255, 0.05);
}

.xs-banner.banner-v8 .banner-6-content {
  padding-top: 0px;
}

.xs-banner.banner-tj {
  display: block;
  max-height: 90vh;
  min-height: 820px;
  height: 100%;
}

.xs-banner.banner-tj > .container {
  padding-top: 200px;
}

.xs-banner.banner-tj .xs-banner-content {
  margin-top: -125px;
}

.xs-banner.banner-tj .xs-banner-image {
  margin-left: 25px;
  margin-right: -65px;
  margin-top: -40px;
  left: 20px;
}

.xs-banner.banner-tj + .pricing-section {
  margin-top: -430px;
  position: relative;
  z-index: 2;
}

.xs-banner.banner-tj + .pricing-section .shuufle-letter-title {
  color: rgba(255, 255, 255, 0.05);
}

.xs-banner.banner-tj .banner-6-content {
  padding-top: 0px;
}

.xs-banner.skew-bg {
  position: relative;
}

.xs-banner.skew-bg::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 200px;
  width: 100%;
  border-bottom: 200px solid #FFFFFF;
  z-index: 1;
  pointer-events: none;
}

.xs-banner.curve-bg {
  position: relative;
}

.xs-banner.curve-bg::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  pointer-events: none;
  z-index: 1;
  height: 87px;
  background-image: url(./../images/welcome/main-shape-curve.png);
  background-size: 100% 100%;
}

.banner-6-content.xs-banner-content .banner-sub-title {
  color: #2cd3d9;
  font-size: 1.06666667rem;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 1.43;
  margin-top: -20px;
}

.banner-6-content.xs-banner-content .banner-title {
  font-size: 3.2rem;
  font-weight: 500;
  margin-bottom: 50px;
}

.banner-6-content.xs-banner-content .banner-title span {
  display: inline-block;
  position: relative;
}

.banner-6-content.xs-banner-content .banner-title span::before {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 0;
  width: calc(100% + 18px);
  height: 18px;
  background-image: url(./../images/welcome/text-bottom-border.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: cover;
  pointer-events: none;
}

.banner-6-content.xs-banner-content .list-groups {
  margin-bottom: 45px;
}

.banner-6-content.xs-banner-content .xs-list > li:not(:last-child) {
  margin-bottom: 3px;
}

.banner-6-content .btn {
  font-weight: 500;
  color: #1633ff;
}

.banner-6-content .btn:hover {
  color: #FFFFFF;
}

.banner-title,
.banner-sub-title,
.xs-banner-content {
  color: #FFFFFF;
}

.xs-banner-content p {
  margin-bottom: 15px;
}

.banner-sub-title {
  font-size: 1.86666667rem;
  font-weight: 500;
}

.banner-title {
  font-size: 4.26666667rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.banner-ico {
  position: absolute;
  left: 0;
  backface-visibility: hidden;
  animation: bounce 1s ease-in-out 0s infinite alternate;
}

.banner-ico.banner-ico-1 {
  left: 49%;
  top: 7%;
  width: 27px;
}

.banner-ico.banner-ico-2 {
  width: 27px;
  top: 16%;
  left: 37%;
  animation-delay: .4s;
}

.banner-ico.banner-ico-3 {
  width: 27px;
  top: 26%;
  left: 48.5%;
  animation-delay: .8s;
}

.banner-ico.banner-ico-4 {
  width: 27px;
  top: 16%;
  left: 60.5%;
  animation-delay: 1.2s;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-30px);
  }
}

.home5-banner-image {
  text-align: right;
}

.banner-6-content {
  padding-top: calc(4% + 140px);
}

.tp-caption .banner-6-content .xs-list {
  line-height: 1.6 !important;
}

.tp-caption .banner-6-content .xs-list > li {
  font-size: 1.13333333rem !important;
}

.tp-caption .banner-6-content .btn {
  transition: all 0.6s !important;
}

#rev_slider_17_1_forcefullwidth + .xs-domain-search {
  margin-top: -300px;
  margin-bottom: 79px;
}

#rev_slider_18_1_wrapper .tp-caption .icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #1169DA;
  border-radius: 50%;
  position: relative;
  top: -1px;
  margin-right: 7px;
}

.banner-sub-title {
  color: #4AAFFA;
  font-size: 1.13333333rem;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: 1.5;
  margin-top: -25px;
}

/*--------------------------------------------------============================================================
										4. modal
=================================================--------------------------------------------------------------*/

/* magnific pop up modal */

.mfp-bg.xs-promo-popup {
  background-color: rgba(0, 0, 0, 0.87);
  padding-bottom: 100%;
  border-radius: 100%;
  overflow: hidden;
  animation: menu-animation .8s ease-out forwards;
}

/* animation keyframes list */

@keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04) translateY(300%);
  }

  40% {
    transform: scale(0.04) translateY(0);
    transition: ease-out;
  }

  40% {
    transform: scale(0.04) translateY(0);
  }

  60% {
    opacity: 1;
    transform: scale(0.02) translateY(0px);
  }

  61% {
    opacity: 1;
    transform: scale(0.04) translateY(0px);
  }

  99.9% {
    opacity: 1;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }

  100% {
    opacity: 1;
    transform: scale(2) translateY(0px);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

/* Styles for dialog window */

.xs-promo-popup .modal-content {
  background-color: transparent;
  padding: 0px;
  border: 0px;
}

.xs-promo-popup .mfp-close {
  color: #FFFFFF;
  opacity: 0;
  transition: all 1s ease .8s;
  transform: translateY(-500px);
}

.xs-promo-popup.mfp-ready .mfp-close {
  opacity: 1;
  transform: translateY(0);
}

/* at start */

.xs-promo-popup.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  transition: all 1s ease .8s;
  transform: translateY(-500px);
}

/* animate in */

.xs-promo-popup.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  transform: translateY(0);
}

/*--------------------------------------------------============================================================
										5. modal search
=================================================--------------------------------------------------------------*/

.modal-searchPanel .xs-search-group {
  position: relative;
}

.modal-searchPanel .xs-search-group input:not([type="submit"]) {
  height: 70px;
  background-color: transparent;
  border-radius: 50px;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  padding: 0 30px;
}

.modal-searchPanel .xs-search-group input:not([type="submit"])::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #FFFFFF;
}

.modal-searchPanel .xs-search-group input:not([type="submit"])::-moz-placeholder {
  /* Firefox 19+ */
  color: #FFFFFF;
}

.modal-searchPanel .xs-search-group input:not([type="submit"]):-ms-input-placeholder {
  /* IE 10+ */
  color: #FFFFFF;
}

.modal-searchPanel .xs-search-group input:not([type="submit"]):-moz-placeholder {
  /* Firefox 18- */
  color: #FFFFFF;
}

.modal-searchPanel .xs-search-group .search-button {
  background-color: transparent;
  border: 0px;
  padding: 0;
  color: #FFFFFF;
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 70px;
  border-radius: 0 50px 50px 0;
}

/*--------------------------------------------------============================================================
										6. modal flag
=================================================--------------------------------------------------------------*/

.language-content p {
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.33333333rem;
}

/* language modal */

.flag-lists {
  text-align: center;
}

.flag-lists li {
  display: inline-block;
  margin-right: 25px;
}

.flag-lists li:last-child {
  margin-right: 0;
}

.flag-lists li a {
  display: block;
  color: #FFFFFF;
  transition: all 0.4s ease;
  backface-visibility: hidden;
}

.flag-lists li a:hover {
  opacity: .7;
}

.flag-lists li a img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/*--------------------------------------------------============================================================
										7. offset sidebar
=================================================--------------------------------------------------------------*/

.xs-sidebar-group .xs-overlay {
  left: 100%;
  top: 0;
  position: fixed;
  z-index: 101;
  height: 100%;
  opacity: 0;
  width: 100%;
  visibility: hidden;
  transition: all .4s ease-in .8s;
  cursor: url(./../images/cross-out.png), pointer;
}

.xs-sidebar-widget {
  position: fixed;
  right: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 360px;
  z-index: 999999;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  transition: all 0.5s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
  visibility: hidden;
  opacity: 0;
}

.xs-sidebar-group.isActive .xs-overlay {
  opacity: .9;
  visibility: visible;
  transition: all .8s ease-out 0s;
  left: 0;
}

.xs-sidebar-group.isActive .xs-sidebar-widget {
  opacity: 1;
  visibility: visible;
  right: 0;
  transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.4s;
}

.sidebar-textwidget {
  padding: 40px;
  padding-top: 120px;
}

.close-side-widget {
  color: #1768dd;
  font-size: 1rem;
  display: block;
}

.sidebar-widget-container {
  position: relative;
  top: 150px;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in .3s;
  backface-visibility: hidden;
}

.xs-sidebar-group.isActive .sidebar-widget-container {
  top: 0px;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 1.2s;
  backface-visibility: hidden;
}

/*--------------------------------------------------============================================================
										8. offset cart
=================================================--------------------------------------------------------------*/

.widget-heading {
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px;
}

.mini_cart_item {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.xs-empty-content {
  text-align: center;
  padding: 120px 30px 30px;
}

.xs-empty-content .widget-title {
  font-size: 1.46666667rem;
}

.xs-empty-content .xs-title {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.xs-empty-content .woocommerce-mini-cart__empty-message {
  font-size: 1.33333333rem;
}

.xs-empty-content .empty-cart-icon {
  font-size: 3.33333333rem;
}

/*--------------------------------------------------============================================================
										9. offset info bar
=================================================--------------------------------------------------------------*/

.sidebar-textwidget .sidebar-logo-wraper {
  margin-bottom: 33px;
}

.sidebar-textwidget > p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 40px;
}

.sidebar-textwidget .social-list {
  margin-bottom: 30px;
}

.sideabr-list-widget {
  margin-bottom: 40px;
}

.sideabr-list-widget li {
  margin-bottom: 20px;
}

.sideabr-list-widget li:last-child {
  margin-bottom: 0px;
}

.sideabr-list-widget img {
  margin-right: 15px;
  margin-top: 3px;
}

.sideabr-list-widget p,
.sideabr-list-widget a {
  margin-bottom: 2px;
  color: #000000;
  margin-bottom: 8px;
  display: block;
}

.sideabr-list-widget span {
  display: block;
  font-size: 0.93333333rem;
  color: #1768dd;
}

.sideabr-list-widget .media-body {
  line-height: 1;
}

.subscribe-from {
  position: relative;
  margin-bottom: 40px;
}

.subscribe-from p {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1;
  color: #1768dd;
}

.subscribe-from label {
  position: absolute;
  bottom: -24px;
  left: 0px;
  margin-bottom: 0;
  line-height: 1;
}

.subscribe-from .form-control {
  border: 1px solid #f5f5f5;
  height: 50px;
  padding-left: 25px;
}

.subscribe-from .sub-btn {
  background-color: transparent;
  border: 0px;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 25px;
  cursor: pointer;
  color: #1768dd;
  height: 100%;
}

.subscribe-from .sub-btn:focus {
  outline: none;
}

.subscribe-from .form-group {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 10px 30px 0px rgba(23, 104, 221, 0.2);
  border-radius: 25px;
  margin-bottom: 0px;
}

/*--------------------------------------------------============================================================
										10. domain search
=================================================--------------------------------------------------------------*/

.domain-search-form-group {
  padding: 30px 40px 20px 20px;
  background-color: transparent;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.07);
}

.xs-domain-info {
  text-align: center;
  background: rgba(245, 240, 245, 0.1);
  border-radius: 32px;
  padding: 12px 0;
  width: 76%;
  margin: 0 auto;
}

.xs-domain-info li {
  display: inline-block;
  margin-right: 12px;
  padding-right: 12px;
  border-right: 1px solid rgba(75, 100, 225, 0.4);
  color: rgba(255, 255, 255, 0.75);
}

.xs-domain-info li img {
  margin-right: 4px;
  margin-top: -3px;
}

.xs-domain-info li:last-child {
  margin-right: 0px;
  border-right: 0px;
  border-right: 0px;
}

.domain-search-form {
  width: calc(100% - 320px);
  margin: 0 auto;
  margin-bottom: 25px;
  position: relative;
  height: 70px;
  z-index: 9;
}

.domain-search-form input:not([type="submit"]) {
  border-radius: 2.5rem;
  width: 100%;
  height: 100%;
  border: 0px;
  padding: 0 32px;
  box-shadow: 0px 10px 45px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid #f5f3f3;
}

.domain-search-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7b7b7b;
}

.domain-search-form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #7b7b7b;
}

.domain-search-form :-ms-input-placeholder {
  /* IE 10+ */
  color: #7b7b7b;
}

.domain-search-form :-moz-placeholder {
  /* Firefox 18- */
  color: #7b7b7b;
}

.domain-search-form .select-group {
  position: absolute;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0px;
}

.domain-search-form input[type="submit"] {
  border: 0px;
  background-color: #1169DA;
  border-radius: 1.83333333rem;
  color: #FFFFFF;
  padding: 14px 37px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.domain-search-form input[type="submit"]:hover {
  background-color: #0d52ab;
}

.domain-search-form .select-styled {
  width: 60px;
  float: right;
  position: relative;
}

.domain-search-form .select {
  height: 29px;
  width: 100%;
}

.domain-search-form .select .select-styled {
  padding: 0px;
}

.domain-search-form .search-btn {
  background-color: transparent;
  border: 0px;
  color: #000000;
  font-size: 1.00133333rem;
  position: absolute;
  top: 0;
  right: 0;
  padding: 28px 32px 28px 20px;
  text-align: center;
  line-height: 1;
  cursor: pointer;
}

.domain-search-form.version-2 {
  width: 100%;
  max-width: 540px;
  margin-bottom: 30px;
}

.domain-search-form.version-2 .select-group {
  right: inherit;
  left: 0px;
  width: 130px;
}

.domain-search-form.version-2 .select-group::before {
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  height: 44.06px;
  background-color: #e7e7e7;
  border-radius: .5px;
}

.domain-search-form.version-2 .select {
  color: #000000;
}

.domain-search-form.version-2 input:not([type="submit"]) {
  padding-left: 163px;
}

.domain-search-form.version-2 .select-styled {
  width: 115px;
}

.xs-domain-search {
  position: relative;
  z-index: 9;
}

.xs-banner:not(.inner-banner) + .xs-domain-search {
  top: 60vh;
  position: absolute;
  background: transparent;
  width: 99%;
  margin: 0 auto;
  margin-left: -6px;
  display: block;
}

.xs-banner:not(.inner-banner).banner-v3 + .xs-domain-search {
  margin-top: 0px;
}

.xs-domain-search.domainSearch-v2 {
  background-color: transparent;
}

.xs-domain-search.domainSearch-v2 .domain-search-form-group {
  box-shadow: none;
  background-color: transparent;
  width: 90%;
  float: left;
  background-color: transparent;
  margin: 0 auto;
}

.xs-domain-search.domainSearch-2 .domain-search-form {
  margin-bottom: 0px;
}

.xs-domain-search.domainSearch-3 .domain-search-form input[type="submit"] {
  background-color: #7733FF;
}

.domain-box-list li {
  display: inline-block;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.102);
  padding: 13px 26px;
  text-align: left;
  font-size: 1.20133333rem;
  color: #fefefe;
  line-height: 1;
}

.domain-box-list li:not(:last-child) {
  margin-right: 12px;
}

.domain-box-list li hr {
  margin: 12px 0;
  background-color: white;
  opacity: 0.2;
  width: 16px;
  height: 3px;
  display: block;
}

/* version primary */

.xs-domain-search.version-primary {
  background: #1351f4;
  background: linear-gradient(90deg, #1351f4 0%, #0727eb 100%);
}

.xs-domain-search.version-primary .domain-search-form-group {
  box-shadow: none;
  background-color: transparent;
}

.xs-domain-search.version-primary .domain-search-form input:not([type="submit"])::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 1.13333333rem;
  font-family: "Rubik", sans-serif;
}

.xs-domain-search.version-primary .domain-search-form input:not([type="submit"])::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 1.13333333rem;
  font-family: "Rubik", sans-serif;
}

.xs-domain-search.version-primary .domain-search-form input:not([type="submit"]):-ms-input-placeholder {
  /* IE 10+ */
  font-size: 1.13333333rem;
  font-family: "Rubik", sans-serif;
}

.xs-domain-search.version-primary .domain-search-form input:not([type="submit"]):-moz-placeholder {
  /* Firefox 18- */
  font-size: 1.13333333rem;
  font-family: "Rubik", sans-serif;
}

.xs-domain-search.version-primary .domain-search-form input[type="submit"] {
  background: #1351f4;
  background: linear-gradient(90deg, #1351f4 0%, #0727eb 100%);
}

.xs-domain-search.version-primary .select-options {
  top: calc(100% + 22px);
}

.xs-domain-search.version-primary .select-styled {
  color: #7b7b7b;
  font-size: 1.13333333rem;
  font-family: "Rubik", sans-serif;
}

.xs-domain-search.version-primary .select-styled::after {
  content: "\e96c";
  font-family: "iconfont";
  color: #3482ff;
  font-size: 0.86666667rem;
  top: 4px;
  right: 17px;
}

.xs-domain-search.version-primary .xs-domain-info > li {
  border-right-color: rgba(255, 255, 255, 0.25);
}

.xs-domain-search.version-primary .xs-domain-info > li > strong {
  color: rgba(255, 255, 255, 0.3);
  position: relative;
  top: 2px;
}

/*--------------------------------------------------============================================================
										11. why choose us
=================================================--------------------------------------------------------------*/

.why-choose-us-block {
  text-align: center;
  padding: 0 30px;
  position: relative;
}

.why-choose-us-block p {
  margin-bottom: 0px;
}

.why-choose-us-block .line-indecator {
  position: absolute;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: right center;
}

.why-choose-us-block .line-indecator.indicator-1 {
  width: 200px;
  height: 54px;
  right: -80px;
  top: 55px;
  transform: rotate(-10deg);
  transition: all 1s ease .5s;
}

.why-choose-us-block .line-indecator.indicator-2 {
  top: 105px;
  right: -130px;
  width: 200px;
  transition: all 1s ease 1s;
}

.why-choose-us-block.animated .line-indecator {
  opacity: 1;
}

.choose-us-img {
  min-height: 207px;
  margin-bottom: 22px;
}

.choose-us-v2 .choose-us-img {
  min-height: 108px;
  margin-bottom: 54px;
}

.choose-us-v3 .choose-us-img {
  min-height: 230px;
  margin-bottom: 20px;
}

/*--------------------------------------------------============================================================
										12. pricing
=================================================--------------------------------------------------------------*/

.xs-pricing-group .nav-tabs {
  margin-bottom: 60px;
}

.xs-pricing-group + [class^=col-] .pricing-info {
  margin-top: 60px;
}

.pricing-body {
  margin-bottom: 30px;
}

.pricing-body > p {
  margin-bottom: 22px;
}

.pricing-body .pricing-price > p {
  font-weight: 700;
  font-style: italic;
  margin-bottom: 15px;
}

.pricing-body h2 {
  color: #4455CC;
  font-size: 2.66666667rem;
  font-weight: 700;
  margin-bottom: 8px;
}

.pricing-body h2 sup {
  font-size: 1.46666667rem;
  left: -0.13333333rem;
  top: -0.93333333rem;
}

.pricing-body h2 sub {
  font-size: 1.06666667rem;
  font-family: "Poppins", Roboto, Helvetica Neue, Calibri, sans-serif;
  font-variant: small-caps;
  font-weight: 600;
  left: -0.26666667rem;
  letter-spacing: -0.5px;
  bottom: -1px;
}

.pricing-body h6 span {
  color: #3388EE !important;
  font-weight: 500;
  font-size: 14px;
}

.discount-price {
  color: #4455CC;
  font-weight: 700;
  margin-bottom: 0px;
  letter-spacing: -0.1px;
}

.discount-price del {
  color: rgba(26, 42, 58, 0.5);
  padding-left: 5px;
}

.xs-single-pricing {
  background-color: #FFFFFF;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.04);
  text-align: center;
  padding: 40px;
}

.xs-single-pricing .pricing-img-block {
  margin-left: 40px;
  margin-bottom: 18px;
}

.xs-single-pricing .pricing-header {
  margin-bottom: 20px;
}

.xs-single-pricing .pricing-header .xs-title {
  margin-bottom: 0px;
}

.xs-single-pricing.active {
  background: #1045db;
  background: -webkit-linear-gradient(135deg, #1045db 0%, #15095e 60%, #15095e 99%);
  background: linear-gradient(-45deg, #1045db 0%, #15095e 60%, #15095e 99%);
  box-shadow: none;
}

.xs-single-pricing.active .xs-title,
.xs-single-pricing.active .pricing-body p,
.xs-single-pricing.active .pricing-body h2,
.xs-single-pricing.active .discount-price {
  color: #FFFFFF;
}

.xs-single-pricing.active .discount-price del {
  color: rgba(255, 255, 255, 0.5);
}

.xs-single-pricing.active .btn:not([class*=btn-outline]) {
  background-color: #FFFFFF;
  color: #3b75ff;
}

.xs-single-pricing.active .btn:not([class*=btn-outline]):hover {
  color: #FFFFFF;
  background-color: #1169DA;
}

.xs-single-pricing.active .btn:not([class*=btn-outline]):hover::before {
  background-color: #1169DA;
}

.pricing-info {
  text-align: center;
}

.pricing-info h5 {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: -0.1px;
  margin-bottom: 35px;
}

.pricing-info h5 a {
  padding-right: 5px;
  text-decoration: underline;
}

.pricing-info .xs-list li {
  margin-right: 65px;
  font-size: 1.2rem;
  color: #2a549d;
}

.pricing-info .xs-list li:last-child {
  margin-right: 0px;
}

.pricing-info .xs-list li i {
  padding-right: 5px;
  position: relative;
  top: 3px;
}

.pricing-info.info-v2 .xs-list li {
  color: #000000;
}

.pricing-info.info-v2 .xs-list li i {
  color: #7733FF;
}

.pricing-info.info-v2 h5 a {
  color: #7733FF;
}

.pricing-info.info-v3 .xs-list li {
  color: #000000;
}

.pricing-info.info-v3 .xs-list li i {
  color: #24146d;
}

.pricing-info.info-v3 h5 a {
  color: #24146d;
}

/* pricing version 2 */

.pricing-v2 .xs-single-pricing .pricing-img-block {
  margin-left: 0px;
  margin-bottom: 43px;
}

.pricing-v2 .xs-single-pricing.active {
  background-image: none;
  background-color: #1169DA;
}

.pricing-v2 .pricing-body h2 {
  color: #1169DA;
}

.pricing-v2 .discount-price {
  color: #1169DA;
}

/* pricing version 3 */

.pricing-v3 .xs-single-pricing.active {
  background: #1633ff;
  background: -webkit-linear-gradient(bottom, #1633ff 0%, #0069ff 100%);
  background: linear-gradient(to top, #1633ff 0%, #0069ff 100%);
}

.pricing-v4 .xs-single-pricing.active {
  background-image: none;
  background: #7733FF;
}

.pricing-v4 .xs-single-pricing.active .btn:not([class*=btn-outline]) {
  color: #7733FF;
}

.pricing-v4 .xs-single-pricing.active .pricing-body h2,
.pricing-v4 .xs-single-pricing.active .pricing-body h6 {
  color: #FFFFFF;
}

.pricing-v4 .xs-single-pricing .pricing-body h2,
.pricing-v4 .xs-single-pricing .pricing-body h6 {
  color: #7733FF;
}

.pricing-v4 .xs-single-pricing .btn-primary {
  background-color: #7733FF;
}

.pricing-v5 .xs-single-pricing {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
}

.pricing-v5 .xs-single-pricing .pricing-header .xs-title,
.pricing-v5 .xs-single-pricing .pricing-price > p,
.pricing-v5 .xs-single-pricing .pricing-body h2,
.pricing-v5 .xs-single-pricing .discount-price {
  color: #24146d;
}

.pricing-v5 .xs-single-pricing .btn {
  background-color: #24146d;
}

.pricing-v5 .xs-single-pricing .pricing-img-block {
  margin-left: 0px;
}

.pricing-v5 .xs-single-pricing.active {
  background: #38a2bc;
  background: linear-gradient(135deg, #38a2bc 0%, #26126b 60%);
}

.pricing-v5 .xs-single-pricing.active .pricing-body h2,
.pricing-v5 .xs-single-pricing.active .pricing-header .xs-title {
  color: #00eaff;
}

.pricing-v5 .xs-single-pricing.active .pricing-price > p,
.pricing-v5 .xs-single-pricing.active .discount-price {
  color: #FFFFFF;
}

.pricing-v5 .xs-single-pricing.active .btn {
  background-color: #00eaff;
  color: #170d5b;
}

/*--------------------------------------------------============================================================
										13. service
=================================================--------------------------------------------------------------*/

.xs-service-block {
  text-align: center;
  padding: 0 30px;
  background-color: #FFFFFF;
  margin-bottom: 30px;
}

.xs-service-block .service-img {
  min-height: 96px;
  margin-bottom: 20px;
}

.xs-service-block p {
  margin-bottom: 15px;
}

.style-boxed .xs-service-block {
  padding: 30px;
  transition: all 0.4s ease;
  position: relative;
}

.style-boxed .xs-service-block:hover,
.style-boxed .xs-service-block.active {
  background-color: white;
  box-shadow: 0px 10px 15px 0px rgba(23, 104, 221, 0.1);
  z-index: 1;
}

.style-boxed .xs-service-block .service-img {
  line-height: 1;
  min-height: 0;
  margin-bottom: 20px;
  font-size: 3.66666667rem;
  color: #1169DA;
}

.style-boxed.version-2 .xs-service-block:hover,
.style-boxed.version-2 .xs-service-block.active {
  box-shadow: 0px 19.799px 60px 0px rgba(0, 0, 0, 0.1);
}

.style-boxed.version-2 .xs-service-block .service-img {
  color: #7733FF;
  margin-bottom: 30px;
}

.style-boxed.version-2 .xs-service-block .xs-title {
  margin-bottom: 20px;
}

.style-boxed.version-2 .xs-service-block p {
  margin-bottom: 20px;
}

.style-boxed.version-2 .xs-service-block .simple-btn {
  color: #7733FF;
}

.xs-service-area {
  background: #f7f9fb;
  background: linear-gradient(180deg, #f7f9fb 0%, rgba(233, 231, 240, 0) 100%);
}

.xs-service-area .xs-service-block:hover,
.xs-service-area .xs-service-block.active {
  box-shadow: 19.799px 19.799px 40px 0px rgba(0, 0, 0, 0.1);
}

/*--------------------------------------------------============================================================
										14. call to action
=================================================--------------------------------------------------------------*/

.call-to-action-section {
  background: linear-gradient(-45deg, #1045db 0%, #4426cf 6%, #33139d 20%, #25097e 48%, #15095e 92%, #0a0046 99%);
  background-color: linear-gradient(-45deg, #1045db 0%, #4426cf 6%, #33139d 20%, #25097e 48%, #15095e 92%, #0A0046 99%);
  position: relative;
  padding-top: 30px;
  padding-bottom: 160px;
  box-shadow: 0 -6px 16px 2px rgba(20, 40, 60, 0.5) !important;
  text-align: center;
  z-index: 1;
}

.call-to-action-section .icon-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(./../images/icon-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  z-index: -1;
}

.call-to-action-section.call-to-action-v2 {
  background: #0a9ffe;
  background: linear-gradient(87deg, #0a9ffe 0%, #0a9ffe 35%, #1124d9 70%, #1124d9 100%);
  padding: 100px 0;
}

.call-to-action-section.call-to-action-v2 .call-to-action-content h2 {
  margin-bottom: 60px;
}

.call-to-action-section.call-to-action-v2 .call-to-action-content .xs-btn-wraper .btn-secondary {
  box-shadow: none;
}

.call-to-action-section.call-to-action-v3 {
  background: #1633ff;
  background: -webkit-linear-gradient(bottom, #1633ff 0%, #0069ff 100%);
  background: linear-gradient(to top, #1633ff 0%, #0069ff 100%);
}

.call-to-action-section.call-to-action-v4 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  padding: 100px 0;
  z-index: 1;
}

.call-to-action-section.call-to-action-v4:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #150773;
  background: linear-gradient(110deg, #150773 0%, #5333ed 54%, #2cd4d9 100%);
  opacity: .7;
  z-index: -1;
}

.call-to-action-section.call-to-action-v4 .icon-bg {
  background-size: auto;
  background-position: top center;
}

.call-to-action-section.call-to-action-v4 .call-to-action-content h2 {
  margin-bottom: 32px;
  font-weight: 500;
}

.call-to-action-section.call-to-action-v4.style-2::before {
  background: #000000;
  opacity: .5;
}

.call-to-action-content h2 {
  color: #FFFFFF;
  margin-bottom: 0px;
  font-size: 2.4rem;
  font-weight: 700;
}

/*--------------------------------------------------============================================================
										15. feature
=================================================--------------------------------------------------------------*/

.call-to-action-section + .xs-feature-section {
  margin: 0;
  margin-top: -180px;
  position: absolute;
  z-index: 1;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100px;
}

.xs-feature-group {
  background-color: #1169DA;
  box-shadow: 1px 5px 20px 2px rgba(0, 12, 24, 0.32);
  padding: 32px 40px 36px 24px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px 5px 6px 6px;
}

.xs-feature-group .xs-title,
.xs-feature-group p {
  color: #FFFFFF;
}

.xs-feature-group p {
  margin-bottom: 25px;
}

.xs-feature-group .feature-img {
  min-width: 138px;
  margin-right: 14px;
}

.xs-feature-group .xs-btn-wraper {
  text-align: right;
}

.xs-feature-group .btn {
  padding: 10px 30px;
}

/* feature version 2 */

.feature-v2 .xs-feature-group {
  box-shadow: 0px -10px 25px 0px rgba(0, 0, 0, 0.04);
  background-color: #FFFFFF;
}

.feature-v2 .xs-feature-group .xs-title {
  color: #23293A;
}

.feature-v2 .xs-feature-group p {
  color: #1A2A3A;
}

.feature-v2 .xs-feature-group .btn {
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
}

/* feature version 3 */

.feature-v3 .xs-feature-group {
  background: #1633ff;
  background: -webkit-linear-gradient(bottom, #1633ff 0%, #0069ff 100%);
  background: linear-gradient(to top, #1633ff 0%, #0069ff 100%);
}

/*--------------------------------------------------============================================================
										16. clients
=================================================--------------------------------------------------------------*/

.xs-client-slider {
  cursor: e-resize;
  border-bottom: 1px solid #e5e5e5;
}

.single-client {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-client > img {
  filter: grayscale(100%);
  transition: all 0.4s ease;
  cursor: pointer;
}

.single-client > img:hover {
  filter: grayscale(0%);
}

/*--------------------------------------------------============================================================
										17. hosting information
=================================================--------------------------------------------------------------*/

.spinner-icon {
  position: relative;
  width: 65px;
  height: 65px;
  margin-bottom: 25px;
}

.spinner-icon::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(./../images/info/round.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: transform 1s ease-in-out;
  transform-origin: center center;
  transform: rotate(0deg);
}

.spinner-icon.animated::before {
  transform: rotate(720deg);
}

.hosting-info-img {
  position: relative;
}

.hosting-info-img .info-icon {
  position: absolute;
  animation: bounce 1s ease-in-out 0s infinite alternate;
}

.hosting-info-img .info-icon.icon-1 {
  bottom: 25%;
  left: 0%;
}

.hosting-info-img .info-icon.icon-2 {
  bottom: 50%;
  left: -5%;
  animation-delay: .3s;
}

.hosting-info-img .info-icon.icon-3 {
  left: 14%;
  bottom: 70%;
  animation-delay: .5s;
}

.hosting-info-img .info-icon.icon-4 {
  bottom: 59%;
  left: 35.5%;
  animation-delay: .7s;
}

.hosting-info-wraper {
  padding-left: 30px;
}

.hosting-info-wraper .content-title {
  margin-bottom: 30px;
}

.hosting-info-wraper p {
  margin-bottom: 30px;
}

.hosting-info-wraper .xs-list {
  margin-bottom: 40px;
}

.hosting-info-wraper .xs-list li {
  font-weight: 500;
  margin-bottom: 14px;
}

.hosting-info-wraper .xs-list li:last-child {
  margin-bottom: 0px;
}

.hosting-info-wraper-2 .content-title {
  margin-bottom: 60px;
}

.hosting-info-list {
  padding-left: 10px;
  margin-bottom: 30px;
}

.hosting-info-list .info-icon {
  min-width: 46px;
  padding-right: 9px;
}

.hosting-info-list .xs-title {
  margin-bottom: 15px;
}

.hosting-info-list p {
  margin-bottom: 0;
}

.about-us-list .hosting-info-list {
  padding-left: 6px;
  padding-right: 4px;
  margin-bottom: 32px;
  color: #cfcbc9;
}

.about-us-list .hosting-info-list .info-icon {
  min-width: 46px;
  padding-right: 9px;
}

.about-us-list .hosting-info-list .xs-title {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 23px;
}

.about-us-list .hosting-info-list p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 300;
}

.xs-hosting-info .row {
  margin-bottom: 90px;
}

.xs-hosting-info .row + .row {
  margin-bottom: 0px;
}

/*--------------------------------------------------============================================================
										18. testimonial
=================================================--------------------------------------------------------------*/

.xs-testimonial-section {
  background: #1045db;
  background: linear-gradient(45deg, #1045db 0%, #1045db 13%, #15095e 52%, #15095e 100%);
  padding-top: 100px;
  padding-bottom: 70px;
}

.xs-testimonial-section.testimonial-v2 {
  background: #0a9ffe;
  background: linear-gradient(90deg, #0a9ffe 0%, #0a9ffe 0%, #1124d9 52%, #1124d9 100%);
}

.xs-testimonial-section.testimonial-v2 .owl-carousel .owl-item:not(.center) .xs-testimonial-item p {
  color: #2547b5;
}

.xs-testimonial-section.testimonial-v2 .owl-carousel .owl-item:not(.center) .xs-testimonial-item,
.xs-testimonial-section.testimonial-v2 .owl-carousel .owl-item:not(.center) .xs-testimonial-item::before,
.xs-testimonial-section.testimonial-v2 .owl-carousel .owl-item:not(.center) .xs-testimonial-item::after {
  background-color: #889df0;
}

.xs-testimonial-section.testimonial-v3 {
  background-image: none;
  background-color: #FFFFFF;
  padding-bottom: 0px;
}

.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item.center .xs-testimonial-item,
.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item.center .xs-testimonial-item::before,
.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item.center .xs-testimonial-item::after {
  background: #15095e;
  background: -webkit-linear-gradient(0deg, #15095e 1%, #13279d 68%, #1045db 100%);
  background: linear-gradient(90deg, #15095e 1%, #13279d 68%, #1045db 100%);
}

.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item.center .testimonial-content p,
.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item.center .commentor-title {
  color: #FFFFFF;
}

.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item.center .commentor-bio i {
  opacity: .2;
}

.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item:not(.center) .xs-testimonial-item,
.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item:not(.center) .xs-testimonial-item:before,
.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item:not(.center) .xs-testimonial-item:after {
  background: #899fdf;
  background: -webkit-linear-gradient(45deg, #899fdf 0%, #8a92c7 32%, #8a84ae 99%);
  background: linear-gradient(45deg, #899fdf 0%, #8a92c7 32%, #8a84ae 99%);
}

.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item:not(.center) .xs-testimonial-item {
  opacity: 1;
}

.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item:not(.center) .testimonial-content p,
.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item:not(.center) .commentor-title {
  color: #FFFFFF;
}

.xs-testimonial-section.testimonial-v3 .owl-carousel .owl-item:not(.center) .xs-testimonial-item .round-avatar::before {
  background-color: #f7f7f7;
}

.xs-testimonial-section.testimonial-v4 {
  background: #1633ff;
  background: -webkit-linear-gradient(bottom, #1633ff 0%, #0069ff 100%);
  background: linear-gradient(to top, #1633ff 0%, #0069ff 100%);
}

.xs-testimonial-section.testimonial-v5 {
  background-image: none;
  background-color: #FFFFFF;
  padding-bottom: 0px;
}

.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item.center .xs-testimonial-item,
.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item.center .xs-testimonial-item::before,
.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item.center .xs-testimonial-item::after {
  background: #1633ff;
  background: -webkit-linear-gradient(bottom, #1633ff 0%, #0069ff 100%);
  background: linear-gradient(to top, #1633ff 0%, #0069ff 100%);
}

.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item.center .testimonial-content p,
.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item.center .commentor-title {
  color: #FFFFFF;
}

.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item.center .commentor-bio i {
  opacity: .2;
}

.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item:not(.center) .xs-testimonial-item,
.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item:not(.center) .xs-testimonial-item:before,
.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item:not(.center) .xs-testimonial-item:after {
  background: #1633ff;
  background: -webkit-linear-gradient(bottom, #1633ff 0%, #0069ff 100%);
  background: linear-gradient(to top, #1633ff 0%, #0069ff 100%);
}

.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item:not(.center) .xs-testimonial-item {
  opacity: .5;
}

.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item:not(.center) .testimonial-content p,
.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item:not(.center) .commentor-title {
  color: #FFFFFF;
}

.xs-testimonial-section.testimonial-v5 .owl-carousel .owl-item:not(.center) .xs-testimonial-item .round-avatar::before {
  background-color: #f7f7f7;
}

.xs-testimonial-section.testimonial-v6 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

.xs-testimonial-section.testimonial-v6::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #150773;
  background: linear-gradient(110deg, #150773 0%, #5333ed 54%, #2cd4d9 100%);
  opacity: .8;
  z-index: -1;
}

.xs-testimonial-section.testimonial-v7 {
  background: #e9e7f0;
  background: linear-gradient(0deg, #e9e7f0 0%, rgba(233, 231, 240, 0) 100%);
}

.xs-testimonial-section .owl-item.cloned {
  transform: scale(0.9);
  transition: all 0.3s;
}

.xs-testimonial-section .owl-item.active {
  transform: scale(0.8);
  transition: all 0.3s;
}

.xs-testimonial-section .owl-item.active.center {
  transform: scale(1);
}

.xs-testimonial-item {
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  border-radius: 5px;
  position: relative;
}

.xs-testimonial-item::before,
.xs-testimonial-item::after {
  position: absolute;
  content: "";
  top: 0;
  height: 100%;
  width: 100%;
  opacity: .2;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

.owl-carousel .owl-item.center .xs-testimonial-item {
  padding: 40px;
}

.testimonial-content p {
  font-size: 0.82666667rem;
}

.testimonial-content > p {
  margin-bottom: 20px;
}

.commentor-bio {
  position: relative;
}

.commentor-bio i {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 2.66666667rem;
  opacity: .1;
}

.commentor-bio .round-avatar {
  margin-right: 16px;
}

.commentor-bio .commentor-title {
  font-size: 1.03333333rem;
  margin-bottom: 0px;
}

.commentor-bio .commentor-info {
  margin-bottom: 0px;
}

.commentor-bio .round-avatar {
  position: relative;
  border-radius: 100%;
  overflow: hidden;
}

.owl-carousel .owl-item:not(.center) .xs-testimonial-item {
  margin-top: 25px;
}

.owl-carousel .owl-item:not(.center) .xs-testimonial-item .round-avatar {
  width: 44px;
  height: 44px;
}

.owl-carousel .owl-item:not(.center) .xs-testimonial-item .round-avatar::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #181953;
  opacity: .5;
}

.owl-carousel .owl-item:not(.center) .xs-testimonial-item p {
  color: #434483;
}

.owl-carousel .owl-item:not(.center) .xs-testimonial-item .commentor-bio i {
  color: #7f7eaf;
}

.owl-carousel .owl-item:not(.center) .xs-testimonial-item::before {
  width: calc(100% - 28px);
  top: -10px;
}

.owl-carousel .owl-item:not(.center) .xs-testimonial-item::after {
  width: calc(100% - 56px);
  top: -20px;
}

.owl-carousel .owl-item.center .round-avatar {
  width: 55px;
  height: 55px;
}

.owl-carousel .owl-item.center .testimonial-content > p {
  margin-bottom: 25px;
  font-size: 1.13333333rem;
}

.owl-carousel .owl-item.center .commentor-bio .commentor-title {
  font-size: 1.33333333rem;
}

.owl-carousel .owl-item.center .testimonial-content .commentor-info {
  font-size: 1rem;
}

.owl-carousel .owl-item.center .commentor-bio i {
  font-size: 4rem;
}

.owl-carousel .owl-item.center .xs-testimonial-item {
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.3);
}

.owl-carousel .owl-item.center .xs-testimonial-item::before {
  width: calc(100% - 20px);
  top: -14px;
}

.owl-carousel .owl-item.center .xs-testimonial-item::after {
  width: calc(100% - 40px);
  top: -28px;
}

.testimonial-v7 {
  background: #e9e7f0;
  background: linear-gradient(0deg, #e9e7f0 0%, rgba(233, 231, 240, 0) 100%);
}

.testimonial-v7 .owl-carousel .owl-item.center .xs-testimonial-item {
  box-shadow: 19.799px 19.799px 40px 0px rgba(0, 0, 0, 0.1);
}

.testimonial-v7 .owl-carousel .owl-item .xs-testimonial-item::before,
.testimonial-v7 .owl-carousel .owl-item .xs-testimonial-item::after {
  background-color: #000000;
}

.testimonial-v7 .owl-carousel .owl-item .xs-testimonial-item::before {
  opacity: .07;
}

.testimonial-v7 .owl-carousel .owl-item .xs-testimonial-item::after {
  opacity: .03;
}

.testimonial-v7 .owl-carousel .owl-item:not(.center) .xs-testimonial-item {
  background-color: #FFFFFF;
  opacity: .5;
}

.testimonial-v7 .owl-carousel .owl-item:not(.center) .xs-testimonial-item p {
  color: #70799f;
}

.testimonial-v7 .owl-carousel .owl-item:not(.center) .xs-testimonial-item .round-avatar::before {
  background-color: #FFFFFF;
}

.testimonial-v7 .owl-carousel .owl-item:not(.center) .xs-testimonial-item::before,
.testimonial-v7 .owl-carousel .owl-item:not(.center) .xs-testimonial-item::after {
  opacity: 0;
}

.owl-carousel .owl-item .xs-testimonial-item::before,
.owl-carousel .owl-item .xs-testimonial-item::after,
.owl-carousel .owl-item .xs-testimonial-item {
  background-color: #FFFFFF;
}

.owl-carousel .owl-item:not(.center) .xs-testimonial-item,
.owl-carousel .owl-item:not(.center) .xs-testimonial-item::before,
.owl-carousel .owl-item:not(.center) .xs-testimonial-item::after {
  background-color: #8a84af;
}

.xs-testimonial-slider-2 .owl-stage-outer {
  padding: 40px 0;
}

.xs-testimonial-slider .owl-stage-outer {
  padding: 30px 0;
}

/*--------------------------------------------------============================================================
										19. blog
=================================================--------------------------------------------------------------*/

/*--------------------------------------------------============================================================
										20. footer
=================================================--------------------------------------------------------------*/

.footer-group {
  background-color: #113072;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.footer-main {
  padding-top: 95px;
  padding-bottom: 80px;
}

.footer-main .footer-widget .xs-list li.single-menu-item i {
  padding-right: 5px;
  font-size: 12px;
  margin-top: 0;
  display: block;
  float: left;
  line-height: 2.15;
  margin-right: 2px;
  margin-right: 2.5px;
  font-weight: 400;
  opacity: 0.89;
}

.footer-widget img.footer-logo {
  max-width: 88%;
  margin-top: -10px;
  margin-left: -4px;
}

.footer-widget .widget-title {
  color: #FFFFFF;
  font-size: 1.6rem;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.footer-widget .widget-title::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 3px;
  width: 30px;
  background-color: #1169DA;
}

.footer-widget small.widget-title {
  margin-top: 20px;
  padding-bottom: 20px;
  display: block;
  font-size: 13px;
  color: #FFFFFF;
  font-weight: 400;
  opacity: 0.75;
}

.footer-widget .xs-list li {
  margin-bottom: 14px;
}

.footer-widget .xs-list li:last-child {
  margin-bottom: 0px;
}

.footer-widget .xs-list li a {
  transition-duration: 0.3s;
  transition: all 0.4s ease;
}

.footer-widget .xs-list li a:hover {
  color: #FFFFFF;
  opacity: 1;
}

.footer-widget .xs-list li a,
.contact-info-widget li span,
.footer-bottom-info p {
  color: #fff;
  opacity: 0.75;
  transition-duration: 0.3s;
}

.footer-widget .xs-list li:hover a,
.footer-widget .xs-list li a:hover,
.contact-info-widget li span:hover,
.footer-bottom-info p:hover {
  color: #fff;
  opacity: 1;
}

.contact-info-widget li {
  margin-bottom: 25px;
}

.contact-info-widget li:last-child {
  margin-bottom: 0px;
}

.contact-info-widget li img {
  margin-right: 18px;
}

.footer-bottom-info p {
  margin-bottom: 0px;
}

.footer-bottom-info p a {
  color: #1ba3ff;
  text-decoration: underline;
  transition: all 0.4s ease;
}

.footer-bottom-info p a:hover {
  color: #FFFFFF;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 50px 0;
}

.footer-bottom .xs-list {
  padding-top: 10px;
  text-align: right;
}

.footer-bottom .xs-list li {
  margin-right: 20px;
}

.footer-bottom .xs-list li:last-child {
  margin-right: 0px;
}

.footer-copyright {
  background-color: #0d2352;
  padding: 55px 0 45px;
  min-height: 120px;
}

.copyright-text {
  padding: 10px 0;
}

.copyright-text p {
  margin-bottom: 0px;
  color: #e7e7e7;
  font-size: 1rem;
}

.copyright-text p a {
  color: #3399FA;
}

.footer-logo-wraper {
  text-align: center;
}

.footer-logo-wraper .footer-logo {
  display: inline-block;
}

.footer-logo-wraper a.footer-logo img {
  max-height: 60px;
  margin-top: -8px;
}

.social-list-wraper {
  text-align: right;
}

.xs-footer-section > .xs-feature-section {
  margin-bottom: -101px;
}

.xs-footer-section > .xs-feature-section + .footer-group .footer-main {
  padding-top: 195px;
}

.xs-footer-section.footer-v2 {
  background-color: #0e064a;
  padding-top: 115px;
}

.xs-footer-section.footer-v2 .footer-bottom {
  padding: 0px;
  border-top: 0px solid #000;
}

.xs-footer-section.footer-v2 .footer-bottom .footer-bottom-info p {
  color: rgba(255, 255, 255, 0.7);
}

.xs-footer-section.footer-v2 .footer-bottom .footer-bottom-info p a {
  color: inherit;
}

.xs-footer-section.footer-v2 .footer-bottom .xs-list li > img {
  opacity: .5;
}

.xs-footer-section.footer-v2 .footer-group {
  background-color: inherit;
  background-size: auto;
  background-position: bottom center;
}

.xs-footer-section.footer-v2 .footer-main {
  padding-bottom: 110px;
  padding-top: 100px;
}

.xs-footer-section.footer-v2 .footer-widget.text-widget .footer-logo {
  display: inline-block;
  margin-bottom: 23px;
}

.xs-footer-section.footer-v2 .footer-widget.text-widget > p {
  margin-bottom: 25px;
  color: rgba(255, 255, 255, 0.5);
}

.xs-footer-section.footer-v2 .footer-widget.text-widget > p > a {
  transition: all 0.4s ease;
  color: inherit;
}

.xs-footer-section.footer-v2 .footer-widget.text-widget > p > a > img {
  max-height: 60px;
}

.xs-footer-section.footer-v2 .footer-widget.text-widget > p > a:hover {
  color: #FFFFFF;
}

.xs-footer-section.footer-v2 .footer-widget .widget-title {
  padding-bottom: 0;
  margin-bottom: 20px;
}

.xs-footer-section.footer-v2 .footer-widget .widget-title::before {
  display: none;
}

.xs-footer-section.footer-v2 .footer-widget .xs-list > li:not(:last-child) {
  margin-bottom: 3px;
}

.xs-footer-section.footer-v2 .footer-widget .xs-list > li > a {
  color: rgba(255, 255, 255, 0.5);
}

.xs-footer-section.footer-v2 .footer-widget .xs-list > li > a:hover {
  color: #FFFFFF;
}

.xs-footer-section.footer-v2 .footer-widget .contact-info-widget > li:not(:last-child) {
  margin-bottom: 15px;
}

.xs-footer-section.footer-v2 .footer-widget .contact-info-widget > li > a {
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.4s ease;
}

.xs-footer-section.footer-v2 .footer-widget .contact-info-widget > li > a:hover {
  color: #FFFFFF;
}

/*--------------------------------------------------============================================================
										21. pricing feature
=================================================--------------------------------------------------------------*/

.pricing-feature-group {
  padding: 55px 35px;
  text-align: center;
  background-color: #FFFFFF;
  height: 100%;
}

.pricing-feature-group .pricing-body {
  margin-bottom: 0px;
}

.pricing-image {
  padding: 55px;
  background-color: #FFFFFF;
}

.pricing-matrix {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.08);
  background-color: #FFFFFF;
}

.pricing-matrix .gap {
  height: 58px;
  clear: both;
  position: relative;
  display: block;
  background-color: #EEF0F4 !important;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.06) inset, 0 -2px 1px 0 rgba(0, 0, 0, 0.04) inset;
  border-top: 1px solid #E0E1E3;
  border-bottom: 1px solid #E0E1E3;
}

.pricing-expand {
  position: relative;
  overflow: hidden;
  box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow .5s ease 1.9s;
  max-height: 120vh;
}

.pricing-expand::before {
  position: absolute;
  content: "";
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 325px;
  background: #f8fafe;
  background: -webkit-linear-gradient(90deg, #f8fafe 8%, rgba(248, 250, 254, 0.95) 12%, rgba(252, 253, 255, 0.29) 73%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f8fafe 8%, rgba(248, 250, 254, 0.95) 12%, rgba(252, 253, 255, 0.29) 73%, rgba(255, 255, 255, 0) 100%);
  transition: background-image .5s ease 1.9s;
  opacity: 0.98;
  visibility: visible;
  z-index: 2;
  overflow: hidden;
}

.pricing-expand.expand {
  max-height: 100% !important;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.08);
}

.pricing-expand.expand::before {
  opacity: 0;
  visibility: hidden;
}

.content-collapse-wraper {
  text-align: center;
}

.content-collapse-wraper .btn {
  opacity: 1;
  visibility: visible;
  transition: all .5s ease .5s;
}

.content-collapse-wraper .btn.hide {
  opacity: 0;
  visibility: hidden;
}

.pricing-feature {
  display: block;
  padding: 20px 40px;
  line-height: 1;
  background-color: #FFFFFF;
  height: 57px;
}

.pricing-feature > i {
  color: #1169DA;
}

.pricing-matrix > .row [class^=col-] .pricing-feature {
  text-align: center;
}

.pricing-matrix > .row [class^=col-]:first-child .pricing-feature {
  text-align: left;
}

.pricing-matrix > .row [class^=col-]:first-child .pricing-feature.gap {
  white-space: nowrap;
  overflow-x: visible;
  z-index: 3;
  letter-spacing: 0.6px;
  word-spacing: 1.2px;
  text-shadow: 1px 0 2px rgba(25, 40, 75, 0.3);
}

.pricing-matrix > .row [class^=col-] .pricing-feature-item,
.pricing-matrix > .row [class^=col-] .pricing-feature-group,
.pricing-matrix > .row [class^=col-] .pricing-image {
  border-bottom: 1px solid #E0E1E3;
  border-right: 1px solid #E0E1E3;
}

.pricing-matrix > .row [class^=col-] .pricing-feature-item:last-child,
.pricing-matrix > .row [class^=col-] .pricing-feature-group:last-child,
.pricing-matrix > .row [class^=col-] .pricing-image:last-child {
  border-bottom: 0px;
}

.pricing-matrix-slider {
  position: relative;
}

.pricing-matrix-slider .last-child .pricing-matrix-item .pricing-feature-item,
.pricing-matrix-slider .last-child .pricing-matrix-item .pricing-feature-group,
.pricing-matrix-slider .last-child .pricing-matrix-item .pricing-image {
  border-right: 0px;
}

.pricing-matrix-slider .owl-nav .owl-prev,
.pricing-matrix-slider .owl-nav .owl-next {
  position: absolute;
  top: 0;
  padding: 20px;
  font-size: 1.86666667rem;
  color: #1169DA;
  background-color: transparent;
  border: 0;
}

.pricing-matrix-slider .owl-nav .owl-prev {
  left: 0px;
}

.pricing-matrix-slider .owl-nav .owl-next {
  right: 0px;
}

/*--------------------------------------------------============================================================
										22. included feature
=================================================--------------------------------------------------------------*/

.feature-image-content {
  text-align: center;
}

.feature-text-content {
  padding-right: 30px;
}

.feature-text-content > .content-title,
.feature-text-content p {
  color: #FFFFFF;
  margin-bottom: 0px;
}

.feature-text-content .content-title {
  margin-bottom: 25px;
}

/*--------------------------------------------------============================================================
										23. inner banner
=================================================--------------------------------------------------------------*/

.xs-banner.inner-banner {
  min-height: 60vh;
}

.xs-banner.service-banner {
  min-height: 80vh;
}

.xs-banner.service-banner-2 {
  min-height: 88vh;
}

.xs-banner.service-banner .xs-banner-content p,
.xs-banner.service-banner-2 .xs-banner-content p {
  margin-bottom: 0px;
}

.xs-banner.inner-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.xs-banner.inner-banner .banner-title {
  font-size: 3.46666667rem;
}

.xs-banner.inner-banner .banner-sub-title {
  font-size: 1.33333333rem;
}

.xs-banner.inner-banner .xs-banner-content {
  position: relative;
  z-index: 2;
}

.xs-banner.inner-banner .xs-overlay {
  opacity: .85;
}

.xs-banner.inner-banner .xs-overlay.bg-gradient {
  background: #15095e;
  background: linear-gradient(135deg, #15095e 0%, #15095e 32%, #1045db 100%);
}

.xs-banner.small-banner {
  min-height: 50vh !important;
}

.inner-welcome-image-group {
  text-align: right;
}

.inner-welcome-image-group .banner-ico.banner-ico-1 {
  left: 52.5%;
  top: 1%;
}

.inner-welcome-image-group .banner-ico.banner-ico-2 {
  right: 10%;
  top: 10%;
  animation-delay: 1s;
}

.inner-welcome-image-group.pricing-icon .banner-ico.banner-ico-1 {
  left: 51.5%;
}

.inner-welcome-image-group.pricing-icon .banner-ico.banner-ico-2 {
  right: 11%;
}

.inner-welcome-image-group.affiliate-icon .banner-ico.banner-ico-1 {
  left: 43.5%;
}

.inner-welcome-image-group.affiliate-icon .banner-ico.banner-ico-2 {
  right: 12%;
}

.inner-welcome-image-group.faq-icon .banner-ico.banner-ico-1 {
  left: 42.5%;
}

.inner-welcome-image-group.faq-icon .banner-ico.banner-ico-2 {
  right: 12.5%;
}

.inner-welcome-image-group.cart-icon .banner-ico.banner-ico-1 {
  left: 53.9%;
}

.inner-welcome-image-group.cart-icon .banner-ico.banner-ico-2 {
  right: 11%;
}

.inner-welcome-image-group.whois-icon .banner-ico.banner-ico-1,
.inner-welcome-image-group.signin-icon .banner-ico.banner-ico-1 {
  left: 53.5%;
}

.inner-welcome-image-group.whois-icon .banner-ico.banner-ico-2,
.inner-welcome-image-group.signin-icon .banner-ico.banner-ico-2 {
  right: 11.5%;
}

.service-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.service-banner .xs-banner-content {
  position: relative;
  z-index: 2;
}

.service-banner .xs-overlay {
  opacity: .85;
}

.service-banner .xs-overlay.bg-gradient {
  background: #15095e;
  background: linear-gradient(135deg, #15095e 0%, #15095e 32%, #1045db 100%);
}

/*--------------------------------------------------============================================================
										24. contact info
=================================================--------------------------------------------------------------*/

.contact-info-group {
  padding: 27px 35px 35px;
  min-height: 275px;
  text-align: center;
  background-color: #FFFFFF;
  transition: all 0.4s ease;
}

.contact-info-group .contact-info-icon {
  min-height: 110px;
  margin-bottom: 10px;
}

.contact-info-group > span,
.contact-info-group > a {
  display: block;
  color: #1A2A3A;
}

.contact-info-group:hover,
.contact-info-group.active {
  box-shadow: 0px 3px 33.6px 1.4px rgba(0, 0, 0, 0.1);
}

/*--------------------------------------------------============================================================
										25. form filed
=================================================--------------------------------------------------------------*/

.xs-form-group {
  padding: 0 48px;
}

.xs-form-group.form-style-2 {
  padding: 0 24px;
}

.xs-form-group.form-style-2 .xs-form .form-group {
  margin-bottom: 40px;
}

.xs-form .form-control,
.xs-form .select {
  background-color: #FAF8F5;
  border: 1px solid #E7E8EA;
  border-radius: 2PX;
  padding: 0 30px;
  margin-bottom: 20px;
  height: 57px;
  font-weight: 500;
  width: 100%;
  transition: all 0.4s ease;
}

.xs-form .form-control:last-child,
.xs-form .select:last-child {
  margin-bottom: 0px;
}

.xs-form .form-control.invaild,
.xs-form .select.invaild {
  border-color: #1169DA;
}

.xs-form .form-control::-webkit-input-placeholder,
.xs-form .select::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 1rem;
  color: #1699FF;
}

.xs-form .form-control::-moz-placeholder,
.xs-form .select::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 1rem;
  color: #1699FF;
}

.xs-form .form-control:-ms-input-placeholder,
.xs-form .select:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 1rem;
  color: #1699FF;
}

.xs-form .form-control:-moz-placeholder,
.xs-form .select:-moz-placeholder {
  /* Firefox 18- */
  font-size: 1rem;
  color: #1699FF;
}

.xs-form textarea.form-control {
  padding: 20px 30px;
  resize: none;
  height: 147px;
}

.xs-form .form-group {
  margin-bottom: 60px;
}

.xs-form .xs-btn-wraper {
  text-align: center;
}

.xs-form .select-styled {
  padding: 0 30px;
  top: 0;
  color: #1699FF;
  font-size: 1rem;
  height: 100%;
  line-height: 52px;
}

.xs-form .select-styled::after {
  right: 30px;
  color: #1699FF;
  font-size: 1.66666667rem;
  top: 2px;
}

.xs-form .select-options {
  left: 0;
  min-width: 100%;
}

.xs-form .select-options::before {
  left: inherit;
  right: 0;
  transform: translateX(30px) rotate(45deg);
}

.xs-form .select-options li {
  padding: 15px 30px;
  font-size: 1rem;
}

.xs-form input[type=number]::-webkit-inner-spin-button,
.xs-form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.xs-form > .row > [class^=col-]:first-child .form-group {
  padding-left: 25px;
}

.xs-form > .row > [class^=col-]:last-child .form-group {
  padding-right: 25px;
}

.xs-form > .row > [class^=col-] .form-group {
  margin-bottom: 80px;
}

.xs-form .media + .xs-btn-wraper {
  margin-top: 40px;
}

.xs-form .xs-btn-wraper + .form-info {
  margin-top: 30px;
  margin-bottom: 0px;
}

.xs-form .forgot-password-wraper {
  line-height: 1;
}

.xs-form .forgot-password-wraper a {
  color: #1699FF;
  font-size: 1rem;
  display: block;
}

.xs-form .form-info {
  text-align: center;
}

.xs-form .form-info a {
  color: #1169DA;
  display: inline-block;
  text-decoration: underline;
}

.xs-form .select.focus,
.xs-form .form-control:focus {
  border-color: #1169DA;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06);
}

.xpeedStudio_success_message {
  margin-bottom: 0;
  padding: 10px 0;
  transition: all .4s ease;
}

label span.req {
  color: #df3b42 !important;
  font-size: 14px !important;
  text-transform: uppercase;
  font-family: "Poppins", Roboto, Helvetica Neue, Calibri, sans-serif !important;
}

.form-group .checkbox-group > div > label,
.checkbox-group > div > label {
  font-size: 13px !important;
  color: #4F5F7F;
  font-weight: 600;
  word-spacing: 3px;
  text-transform: capitalize;
}

/*--------------------------------------------------============================================================
										26. intro video
=================================================--------------------------------------------------------------*/

.intro-video {
  position: relative;
  box-shadow: 0px 10px 30px 0px rgba(16, 61, 190, 0.159);
  overflow: hidden;
  border-radius: 5px;
}

.intro-video .xs-overlay {
  opacity: .6;
}

.video-pop-up-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 10px;
}

/*--------------------------------------------------============================================================
										27. office
=================================================--------------------------------------------------------------*/

.office-interface-group-content.content-2 {
  margin-right: 30px;
}

.office-interface-group-content.content-1 {
  margin-top: -75px;
}

.office-interface-group-content > img {
  width: 100%;
}

.office-interface-group-content,
.join-with-us-wraper {
  margin-bottom: 30px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

.join-with-us-wraper {
  min-height: 389px;
  background-color: #1169DA;
  margin-top: 45px;
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 20px 95px;
  display: flex;
  align-items: center;
}

.join-with-us-wraper .join-with-us-content h2 {
  color: #FFFFFF;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 35px;
}

/*--------------------------------------------------============================================================
										28. team
=================================================--------------------------------------------------------------*/

.xs-single-team {
  position: relative;
  overflow: hidden;
  transition: all .5s ease;
}

.xs-single-team::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  background: -webkit-linear-gradient(90deg, black 0%, rgba(37, 42, 55, 0) 100%);
  background: linear-gradient(0deg, black 0%, rgba(37, 42, 55, 0) 100%);
  opacity: .5;
  transition: all .3s ease;
}

.xs-single-team > .team-bio {
  position: absolute;
  z-index: 1;
  padding: 30px;
  bottom: 0px;
  left: 0;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition: all .3s ease;
}

.xs-single-team .team-bio h4 {
  color: #FFFFFF;
  font-size: 1.6rem;
  margin-bottom: 5px;
}

.xs-single-team .team-bio p {
  font-size: 1rem;
  font-style: italic;
  color: #FFFFFF;
  margin-bottom: 0px;
}

.xs-single-team .team-hover-content {
  position: absolute;
  top: 40px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  padding: 60px 30px;
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease;
}

.xs-single-team .team-hover-content .team-bio {
  margin-bottom: 25px;
}

.xs-single-team .team-hover-content .team-description {
  margin-bottom: 50px;
}

.xs-single-team .team-hover-content .team-description p {
  margin-bottom: 0px;
}

.xs-single-team .team-description p {
  color: #FFFFFF;
}

.xs-single-team:hover {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.159);
}

.xs-single-team:hover::before {
  opacity: 1;
}

.xs-single-team:hover > .team-bio {
  opacity: 0;
  visibility: hidden;
  bottom: -40px;
}

.xs-single-team:hover > .team-hover-content {
  opacity: 1;
  visibility: visible;
  top: 0px;
}

/*--------------------------------------------------============================================================
										29. included feature list
=================================================--------------------------------------------------------------*/

.include-feature-list .hosting-info-list {
  padding-left: 0px;
  margin-bottom: 70px;
}

.include-feature-list .hosting-info-list:last-child {
  margin-bottom: 0px;
}

.include-feature-list .info-icon {
  min-width: 52px;
  padding-right: 25px;
}

.include-feature-list .xs-title,
.include-feature-list p {
  color: #FFFFFF;
}

.include-feature-list .xs-title {
  margin-bottom: 17px;
}

.included-feature-list.feature-list-v3 {
  background: #1045db;
  background: -webkit-linear-gradient(right bottom, #1045db, #1e2aba, #022e9e, #0016b5, #123fbf);
  background: linear-gradient(to left top, #1045db, #1e2aba, #022e9e, #0016b5, #123fbf);
}

.included-feature-list .wave_animation_wraper .wave.four {
  width: 434px;
  margin-left: auto;
}

/* for domain inner page */

.xs-feature-section + .included-feature-list {
  margin-top: -120px;
  padding-top: 200px;
}

/*--------------------------------------------------============================================================
										30. domain price
=================================================--------------------------------------------------------------*/

.xs-table {
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.08);
  background-color: #FFFFFF;
  margin-bottom: 0px;
}

.xs-table .domain-pricing-header th {
  width: 25%;
  background-color: transparent;
  border: 0px;
  color: #FFFFFF;
  padding: 17px 10px;
  font-size: 1.33333333rem;
}

.xs-table .domain-pricing-header tr {
  background: #103dbe;
  background: -webkit-linear-gradient(45deg, #103dbe 0%, #13238e 32%, #15095e 99%);
  background: linear-gradient(45deg, #103dbe 0%, #13238e 32%, #15095e 99%);
}

.xs-table td,
.xs-table th {
  text-align: center;
  padding: 15px 10px;
}

.xs-table tbody tr th,
.xs-table tbody tr td {
  border-right: 1px solid #E0E1E3;
  border-top: 1px solid #E0E1E3;
}

.xs-table tbody tr td:last-child {
  border-right: 0px;
}

.xs-table tbody tr:first-child th,
.xs-table tbody tr:first-child td {
  border-top: 0px;
}

.xs-table tbody tr td {
  letter-spacing: -.4px;
}

.xs-table tbody tr td > del {
  color: #a4afdf;
  font-size: 0.93333333rem;
  padding-left: 3px;
}

.xs-table.fixed-column {
  position: absolute;
  left: 15px;
  width: 83px;
  display: inline-block;
  z-index: 1;
  box-shadow: none;
}

@media (min-width: 576px) {
  .xs-table.fixed-column {
    display: none;
  }
}

@media (max-width: 576px) {
  .xs-table:not(.fixed-column) tr th:first-child {
    display: none;
  }

  .xs-table:not(.fixed-column) {
    position: relative;
    left: 83px;
  }
}

.domain-price-v2 .xs-table .domain-pricing-header tr {
  background: #0217af;
  background: -webkit-linear-gradient(top, #0217af 0%, #0055ce 100%);
  background: linear-gradient(to bottom, #0217af 0%, #0055ce 100%);
}

/*--------------------------------------------------============================================================
										31. blog list
=================================================--------------------------------------------------------------*/

.xs-blog-list {
  margin-bottom: 80px;
}

.xs-blog-list .post {
  margin-bottom: 40px;
}

.xs-blog-list .post:last-child {
  margin-bottom: 0px;
}

.xs-blog-list.blog-lsit-style-2 .post:not(.post-details) .post-body {
  box-shadow: 19.799px 19.799px 60px 0px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
}

.xs-blog-list.blog-lsit-style-2 .post .post-body .post-meta > span,
.xs-blog-list.blog-lsit-style-2 .post .post-body .post-meta a {
  color: #000000;
}

.xs-blog-list.blog-lsit-style-2 .post .post-body .post-meta > span i {
  padding-right: 7px;
  color: #1169DA;
}

.xs-blog-list.blog-lsit-style-2 .post .post-body .post-meta > span > a:hover {
  color: #1169DA;
}

.xs-blog-list.blog-lsit-style-2 .post .post-body .post-footer {
  text-align: left;
}

.xs-blog-list.blog-lsit-style-2 .post .post-body .post-footer .simple-btn i {
  font-size: 1.13333333rem;
  margin-top: 0px;
}

.post-body .entry-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 18px;
}

.post-body .entry-title a {
  color: #23293A;
  display: inline-block;
  transition: all 0.4s ease;
}

.post-body .entry-title a:hover {
  color: #1169DA;
}

.post-body .post-meta {
  margin-bottom: 22px;
}

.post-body .post-meta > span {
  display: inline-block;
  font-size: 1rem;
  padding-right: 30px;
}

.post-body .post-meta > span:last-child {
  padding-right: 0px;
}

.post-body .post-meta .post-author img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.post-body .post-meta .post-author img + a {
  padding-left: 13px;
}

.post-body .post-meta .post-author a {
  display: inline-flex;
}

.post-body .post-meta > span,
.post-body .post-meta a {
  color: #1169DA;
  transition: all 0.4s ease;
}

.post-body .entry-content {
  margin-bottom: 20px;
}

.post-body .entry-content p {
  margin-bottom: 0px;
}

.post-body .post-footer {
  text-align: right;
}

.post-body .post-footer .simple-btn {
  font-weight: 400;
}

.post-media .xs-overlay {
  opacity: .3;
}

.post-media .gloosy-btn {
  width: 60px;
  height: 60px;
  line-height: 63px;
  font-size: 1rem;
}

.post:not(.post-details) .post-body {
  background-color: #f8fafe;
  padding: 40px 30px 30px;
}

.post:not(.format-audio):not(.post-details) .post-media + .post-body {
  width: calc(100% - 60px);
  margin: 0 auto;
  margin-top: -22px;
  z-index: 1;
  position: relative;
}

.post .post-media {
  position: relative;
}

.post .post-media iframe {
  width: 100%;
  display: block;
}

.post-gallery-slider {
  position: relative;
}

.post-gallery-slider .owl-nav .owl-prev,
.post-gallery-slider .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.post-gallery-slider .owl-nav .owl-prev {
  left: 15px;
}

.post-gallery-slider .owl-nav .owl-next {
  right: 15px;
}

.post-gallery-slider .owl-nav i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  color: #1169DA;
  background-color: #FFFFFF;
  font-size: 15px;
}

.sidebar-widget.sidebar-right {
  margin-left: 10px;
}

.sidebar-widget.sidebar-left {
  margin-right: 10px;
}

/*--------------------------------------------------============================================================
										32. side bar
=================================================--------------------------------------------------------------*/

.xs-serach {
  position: relative;
}

.xs-serach input:not([type="submit"]) {
  height: 53px;
  padding: 0 30px;
  border: 1px solid #E0E1E3;
  background-color: #FFFFFF;
  border-radius: 27.5px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.xs-serach .search-btn {
  width: 62px;
  background-color: #1169DA;
  border: 0px;
  font-size: 1.2rem;
  color: #FFFFFF;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 0 27.5px 27.5px 0;
  height: 100%;
  cursor: pointer;
  transition: all 0.4s ease;
}

.xs-serach .search-btn:hover {
  background-color: #4455CC;
}

.widget:not(:last-child) {
  margin-bottom: 40px;
}

.widget .xs-title {
  margin-bottom: 40px;
}

.widget:not(.widget-search) {
  padding: 40px;
  border: 1px solid #E0E1E3;
}

.widget-posts .widget-post:not(:last-child) {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #E0E1E3;
}

.widget-posts .widget-post img {
  padding-right: 20px;
}

.widget-posts .widget-post .post-meta-date {
  color: #626c84;
}

.widget-posts .widget-post .post-meta-date,
.widget-posts .widget-post .post-author {
  font-size: 1rem;
}

.widget-posts .widget-post .entry-title {
  font-size: 1.13333333rem;
  font-weight: 700;
  margin-bottom: 0px;
}

.widget-posts .widget-post .entry-title a {
  color: #23293A;
  transition: all 0.4s ease;
}

.widget-posts .widget-post .entry-title a:hover {
  color: #1169DA;
}

.list-group li {
  position: relative;
}

.list-group li a,
.list-group li span {
  color: #1A2A3A;
}

.list-group li span {
  position: absolute;
  right: 0px;
}

.list-group li a {
  transition: all 0.4s ease;
}

.list-group li a:hover {
  color: #1169DA;
}

.list-group li:not(:last-child) {
  border-bottom: 1px solid #E0E1E3;
  padding-bottom: 14px;
  margin-bottom: 14px;
}

.tag-lists a {
  display: inline-block;
  padding: 7px 14px;
  color: #626c84;
  border: 1px solid #E0E1E3;
  border-radius: 1.33333333rem;
  font-size: 1rem;
  margin-right: 6px;
  margin-bottom: 9px;
  transition: all 0.4s ease;
}

.tag-lists a:hover {
  color: #1169DA;
  border-color: currentColor;
}

.tag-lists .title {
  color: #23293A;
  padding-right: 15px;
  font-weight: 700;
}

/*--------------------------------------------------============================================================
										33. post single
=================================================--------------------------------------------------------------*/

.post-details .post-media {
  margin-bottom: 33px;
}

.post-details .post-body {
  margin-bottom: 40px;
}

.post-details .post-body .entry-content p {
  margin-bottom: 30px;
}

.post-details h4 {
  font-size: 1.33333333rem;
  margin-bottom: 20px;
}

.post-details .xs-list li {
  font-weight: 700;
  margin-bottom: 15px;
}

.post-details .xs-list li:last-child {
  margin-bottom: 0px;
}

.post-details .entry-header {
  padding-bottom: 35px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f1f1f1;
}

.post-details .post-footer .tag-lists {
  text-align: left;
}

.post-details .post-footer .tag-lists a {
  margin-bottom: 0px;
}

.post-details .post-footer .simple-social-list {
  margin-top: 6px;
}

.post-details .post-footer .simple-social-list .title {
  font-weight: 700;
  color: #23293A;
  padding-right: 15px;
}

blockquote {
  background-color: #2a61e5;
  padding: 40px;
  color: #FFFFFF;
  font-size: 1.33333333rem;
  font-style: italic;
  position: relative;
  margin-bottom: 35px;
}

blockquote::before {
  content: "\e937";
  font-family: "iconfont";
  display: block;
  margin-bottom: 10px;
  font-size: 2.73333333rem;
  font-style: normal;
  transform: rotateY(180deg) rotateX(180deg);
  text-align: right;
}

blockquote cite {
  display: block;
  text-align: right;
  margin-top: 30px;
  font-size: 1.13333333rem;
  font-weight: 700;
  font-style: normal;
}

.author-image {
  margin-right: 25px;
}

.author-image img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}

.author-data h5 {
  font-size: 1.33333333rem;
  font-family: "Rubik", sans-serif;
  margin-bottom: 0px;
}

.author-data a {
  color: #626c84;
  font-size: 1rem;
}

.author-info .simple-social-list li a,
.post-details .post-footer .simple-social-list li a {
  color: #252a37;
  font-size: 0.93333333rem;
}

.post-details .post-footer .simple-social-list li,
.author-info .simple-social-list li {
  margin-right: 14px;
}

.author-info .simple-social-list li:last-child,
.post-details .post-footer .simple-social-list li:last-child {
  margin-right: 0px;
}

.author-info {
  padding: 40px;
  background-color: #f8fafe;
  margin-bottom: 40px;
}

.author-info .media {
  margin-bottom: 20px;
}

.author-info p {
  margin-bottom: 0px;
}

.post-navigation {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f1f1f1;
}

.post-navigation .post-navigation-title {
  display: block;
}

.post-navigation .post-navigation-title h3 {
  font-size: 1.33333333rem;
}

.post-navigation .post-navigation-title span {
  color: #626c84;
}

.post-navigation .post-navigation-title span i {
  color: #4455CC;
}

.post-navigation [class^=col-]:first-child {
  border-right: 1px solid #f1f1f1;
}

.post-navigation [class^=col-]:first-child .post-navigation-title {
  margin-right: 20px;
}

.post-navigation [class^=col-]:first-child .post-navigation-title i {
  padding-right: 9px;
}

.post-navigation [class^=col-]:last-child .post-navigation-title {
  margin-left: 20px;
  text-align: right;
}

.post-navigation [class^=col-]:last-child .post-navigation-title i {
  padding-left: 9px;
}

/*--------------------------------------------------============================================================
										34. comments area
=================================================--------------------------------------------------------------*/

.xs-comments-area {
  margin-bottom: 50px;
}

.xs-comments-area .comments-title {
  margin-bottom: 30px;
}

.xs-comments-area .comment-body:before {
  display: table;
  content: "";
  clear: both;
}

.xs-comments-area .comment-body img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  float: left;
}

.xs-comments-area .meta-data {
  margin-left: 100px;
}

.xs-comments-area .reply {
  float: right;
}

.xs-comments-area .reply .comment-reply-link {
  color: #626c84;
  font-size: 1rem;
}

.xs-comments-area .reply .comment-reply-link i {
  color: #252a37;
  font-size: 1.06666667rem;
  padding-right: 10px;
}

.xs-comments-area .comment-author a {
  font-size: 1.2rem;
  color: #23293A;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.xs-comments-area .comment-date {
  color: #626c84;
  margin-bottom: 0px;
  font-size: 1rem;
}

.xs-comments-area .comment-content {
  margin-top: 10px;
}

.xs-comments-area .comment-content p {
  margin-bottom: 0px;
}

.xs-comments-area .comment:not(:last-child) .children {
  margin-left: 100px;
}

.xs-comments-area .comment:not(:last-child) .children .meta-data {
  background-color: #f7f9fd;
  padding: 30px;
  margin-bottom: 0px;
  border-bottom: 0px;
}

.xs-comments-area .comment:not(:last-child) .meta-data,
.xs-comments-area .comment:not(:last-child) .children {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #f1f1f1;
}

.comment-respond .comment-reply-title {
  margin-bottom: 35px;
}

.comment-respond .xs-form .form-control:last-child,
.comment-respond .xs-form .form-control + .select:last-child,
.comment-respond .xs-form .form-control,
.comment-respond .xs-form .form-control + .select {
  margin-bottom: 20px;
}

.comment-respond .xs-form textarea.form-control {
  margin-bottom: 40px;
}

.comment-respond .form-submit {
  margin-bottom: 0px;
  text-align: right;
}

/*--------------------------------------------------============================================================
										35. cart
=================================================--------------------------------------------------------------*/

/*
		Nav Second Main
		- search, etc
	*/

#header ul.nav-second-main {
  border-left: rgba(0, 0, 0, 0.1) 1px solid;
  padding-left: 15px;
  margin-top: 39px;
  -webkit-transition: all .300s;
  -moz-transition: all .300s;
  -o-transition: all .300s;
  transition: all .300s;
}

#header.fixed ul.nav-second-main {
  margin-top: 20px;
}

#header.header-sm ul.nav-second-main {
  margin-top: 20px;
}

#header.header-md ul.nav-second-main {
  margin-top: 23px;
}

#header.fixed.header-sm ul.nav-second-main.nav-social,
#header.fixed.header-md ul.nav-second-main.nav-social {
  margin-top: 18px;
}

#header ul.nav-second-main.nav-social a {
  margin-left: 10px !important;
}

#header ul.nav-second-main.nav-social > li:first-child a {
  margin-left: 10px !important;
}

#header.fixed ul.nav-second-main.nav-social a > i:hover {
  color: #fff !important;
}

#header ul.nav-second-main li {
  padding-top: 33px;
  padding: 0 5px 0px 5px;
}

#header ul.nav-second-main li > a {
  background-color: transparent;
  color: #666;
  padding: 0 3px;
  display: block;
}

#header ul.nav-second-main li i {
  font-size: 18px;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  opacity: 0.6;
  filter: alpha(opacity=60);
  -webkit-transition: all .300s;
  -moz-transition: all .300s;
  -o-transition: all .300s;
  transition: all .300s;
}

#header ul.nav-second-main li:hover i {
  opacity: 1;
  filter: alpha(opacity=100);
}

#header ul.nav-second-main li .badge {
  padding: 3px 6px;
}

/* dark & color menu */

#header.transparent ul.nav-second-main li a,
#header.transparent ul.nav-second-main li a > i,
#header.color ul.nav-second-main li a,
#header.color ul.nav-second-main li a > i,
#header.dark ul.nav-second-main li a,
#header.dark ul.nav-second-main li a > i {
  color: #fff;
  opacity: 1;
  filter: alpha(opacity=100);
}

@media only screen and (max-width: 992px) {
  #header ul.nav-second-main {
    margin: 15px 15px 0 0;
    border: 0;
  }

  #header ul.nav-second-main li {
    padding: 0;
    padding-top: 6px;
  }

  #header ul.nav-second-main {
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
  }

  #header.fixed ul.nav-second-main li {
    padding-top: 0;
  }

  #header.header-md ul.nav-second-main li > a,
  #header.header-sm ul.nav-second-main li > a {
    margin-top: -6px;
  }

  /* header social */

  #header.header-md ul.nav-second-main {
    margin-top: 20px;
  }

  #header.fixed.header-sm ul.nav-second-main.nav-social,
  #header.fixed.header-md ul.nav-second-main.nav-social {
    margin-top: 26px;
  }

  /* header translucent fix */

  #header.header-md.translucent ul.nav-second-main {
    margin-top: 23px;
  }

  #header.header-md.translucent.fixed ul.nav-second-main {
    margin-top: 29px;
  }
}

/** ************************************************************* **/

/* submenu */

#topNav ul.dropdown-menu {
  text-align: left;
  margin-top: 0;
  box-shadow: none;
  border: #eee 1px solid;
  border-top: #eee 1px solid;
  list-style: none;
  background: #fff;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0 6px 12px;
  min-width: 200px;
  padding: 0;
  border-color: #1ABC9C #fff #fff;
  -webkit-transition: top .4s ease;
  -o-transition: top .4s ease;
  transition: top .4s ease;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

#topNav ul.dropdown-menu ul.dropdown-menu {
  margin-top: -1px !important;
  /* -1px required for border-top menu */
}

#topNav ul.dropdown-menu li {
  position: relative;
  border-bottom: rgba(0, 0, 0, 0.06) 1px solid;
}

/*
		#topNav .submenu-dark ul.dropdown-menu li {
			border-bottom-color: rgba(0,0,0,0.2);
		}
	*/

#topNav ul.dropdown-menu li:last-child {
  border-bottom: 0;
}

#topNav ul.dropdown-menu li a {
  margin: 0;
  padding: 7px 15px;
  font-weight: 400;
  line-height: 23px;
  color: #666;
  font-size: 12px;
  display: block;
  text-decoration: none;
}

#topNav ul.dropdown-menu > li a i {
  margin-right: 6px;
  font-size: 12px;
}

#topNav ul.dropdown-menu a.dropdown-toggle {
  background-position: right center;
  background-repeat: no-repeat;
}

#topNav ul.dropdown-menu li.active > a,
#topNav ul.dropdown-menu li.active:hover > a,
#topNav ul.dropdown-menu li.active:focus > a,
#topNav ul.dropdown-menu li:hover > a,
#topNav ul.dropdown-menu li:focus > a,
#topNav ul.dropdown-menu li:focus > a {
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
}

#topNav ul.dropdown-menu li.divider {
  margin: -1px 0 0 0;
  padding: 0;
  border: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

#topNav .nav li:hover > ul.dropdown-menu {
  padding: 0;
  display: block;
  z-index: 100;
}

#topNav ul.dropdown-menu li .label {
  margin-top: 4px;
}

/* sub-submenu */

#topNav ul.dropdown-menu > li:hover > ul.dropdown-menu {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  padding: 0;
  margin: 0;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}

/** ************************************************************* **/

/* onepage active link */

#topMain.nav-onepage > li.active > a {
  font-weight: bold;
}

/** Responsive Top Nav
	 ********************* **/

@media only screen and (max-width: 992px) {
  .navbar-collapse {
    height: 100%;
  }

  form.mobile-search {
    display: block;
  }

  #topNav div.nav-main-collapse {
    padding: 0;
    margin: 0;
  }

  #topNav button.btn-mobile {
    display: block;
    float: right;
    margin-right: 0;
  }

  #header.dark #topNav button.btn-mobile,
  #header.transparent #topNav button.btn-mobile,
  #header.theme-color #topNav button.btn-mobile {
    color: #fff;
  }

  #topNav nav.nav-main {
    background-color: #fff;
  }

  #topNav div.nav-main-collapse,
  #topNav div.nav-main-collapse.in {
    width: 100%;
    margin: -1px 0 0 0;
  }

  #topNav div.nav-main-collapse {
    float: none;
    overflow-x: hidden;
    max-height: 350px;
  }

  /* ======================== MOBILE MENU V2 ===================== */

  html.noscroll,
  html.noscroll body {
    overflow: hidden !important;
  }

  #header.fixed #topNav div.nav-main-collapse,
  #topNav div.nav-main-collapse {
    max-height: 100% !important;
    height: 100%;
    width: 100%;
    position: fixed !important;
    left: 0 !important;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9999 !important;
    overflow-y: scroll !important;
    padding-top: 58px !important;
    margin: 0 !important;
  }

  #topNav div.nav-main-collapse {
    -webkit-overflow-scrolling: touch;
    /* iOS smooth scroll */
    -webkit-animation: fadeIn .3s !important;
    animation: fadeIn .3s !important;
  }

  #header.fixed #topNav button.btn-mobile-active,
  #topNav button.btn-mobile-active {
    position: fixed !important;
    z-index: 999999 !important;
    background-color: #232323 !important;
    height: 60px !important;
    top: 0 !important;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 !important;
    opacity: 0.9;
  }

  #topNav button.btn-mobile-active > i {
    float: right;
    margin-right: 10px;
    margin-left: 10px;
    color: #fff !important;
    font-size: 30px;
  }

  #topNav button.btn-mobile-active > i:before {
    content: "\f00d" !important;
  }

  #menu-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10 !important;
  }

  /* dropdown background color */

  #topMain,
  #topNav ul.dropdown-menu {
    color: #fff;
    background-color: #333;
  }

  #topNav ul.dropdown-menu {
    border: 0;
  }

  #topMain > li > a {
    color: #fff !important;
  }

  #topNav ul.dropdown-menu li > a {
    color: #fbfbfb !important;
  }

  #topMain > li > a,
  #topNav ul.dropdown-menu li > a {
    font-size: 15px !important;
  }

  /* ====================== END MOBILE MENU V2 ==================== */

  #topNav div.nav-main-collapse.collapse {
    display: none !important;
  }

  #topNav div.nav-main-collapse.in {
    display: block !important;
  }

  #topNav div.nav-main-collapse {
    position: relative;
  }

  #topMain > li > a > span {
    display: none !important;
  }

  #topMain li {
    display: block !important;
    float: none;
    text-align: left;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  #topMain li a {
    text-align: left;
    border: 0;
    height: auto;
    line-height: 15px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  #topMain > li:hover,
  #topMain > li:hover > a {
    border-top: 0 !important;
  }

  #topMain > li > a {
    height: auto;
    line-height: auto;
  }

  /* submenu */

  #topMain ul.dropdown-menu {
    position: static;
    clear: both;
    float: none;
    display: none !important;
    border-left: 0 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  #topNav nav.nav-main li.resp-active > ul.dropdown-menu {
    display: block !important;
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px 0;
    border-right: 0;
  }

  #topNav nav.nav-main li.resp-active > ul.dropdown-menu li {
    border-left: 0;
  }

  #topNav ul.nav > li:hover > a:before,
  #topNav ul.nav > li.active > a:before {
    background-color: transparent;
  }

  #topNav ul.dropdown-menu > li:hover > ul.dropdown-menu {
    position: static;
  }

  #topNav div.submenu-dark ul.dropdown-menu {
    border-top: 0;
  }

  /** sub menu */

  #topNav nav.nav-main li.resp-active > ul.dropdown-menu {
    margin: 0;
    padding: 0;
  }

  #topNav nav.nav-main li > ul.dropdown-menu li a {
    padding-left: 40px;
  }

  #topNav .dropdown-menu.pull-right,
  #topNav .dropdown-menu.pull-left {
    float: none !important;
  }
}

@media only screen and (max-width: 500px) {
  #topNav div.nav-main-collapse {
    max-height: 290px;
    overflow-y: auto;
  }
}

/** Quick Shop Cart
	 ********************** **/

#header li.quick-cart .quick-cart-box {
  display: none;
  right: 0;
  left: auto;
  top: 100%;
  padding: 10px 0;
  background-color: #fff;
  position: absolute;
  box-shadow: 5px 5px rgba(91, 91, 91, 0.2);
  width: 274px;
  margin-top: 36px;
  z-index: 22;
}

#header.fixed li.quick-cart .quick-cart-box {
  /*margin-top:18px;*/
}

#header.fixed.header-sm li.quick-cart .quick-cart-box {
  /*margin-top:18px;*/
}

#header.fixed.header-md li.quick-cart .quick-cart-box {
  /*margin-top:15px;*/
}

#header li.quick-cart .quick-cart-wrapper {
  max-height: 400px;
  overflow-y: auto;
}

/*#header li.quick-cart .quick-cart-wrapper.block {*/

/*max-height:400px;*/

/*overflow-y:auto;*/

/*}*/

#header li.quick-cart .quick-cart-box h4 {
  font-size: 17px;
  margin: 0;
  padding: 0 10px 10px 10px;
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
}

#header li.quick-cart .quick-cart-box a {
  display: block;
  padding: 15px 10px;
  border-bottom: rgba(0, 0, 0, 0.04) 1px solid;
}

#header li.quick-cart .quick-cart-box a:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

#header li.quick-cart .quick-cart-box a > img {
  float: left;
  margin-right: 10px;
}

#header li.quick-cart .quick-cart-box a h6 {
  margin: 0;
  padding: 4px 0 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#header li.quick-cart .quick-cart-box a.btn {
  background-color: #151515;
  border: 0;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 4px;
}

#header li.quick-cart .quick-cart-box a.btn.btn-danger {
  background-color: rgba(230, 90, 70, 0.8);
}

#header li.quick-cart .quick-cart-footer {
  padding: 10px 10px 0 10px;
}

#header li.quick-cart .quick-cart-footer > span {
  display: inline-block;
  padding-top: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 4px 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

@media only screen and (min-width: 992px) {
  /* min-width */

  #header li.quick-cart .quick-cart-box {
    top: 21px;
  }

  #header.header-sm li.quick-cart .quick-cart-box {
    margin-top: 19px;
  }

  #header.header-md li.quick-cart .quick-cart-box {
    margin-top: 26px;
  }

  #header li.quick-cart .quick-cart-wrapper {
    max-height: 300px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 992px) {
  /* max-width */

  #header li.quick-cart .quick-cart-box {
    margin-top: 18px;
  }

  #header.dark li.search .search-box {
    margin-top: 38px !important;
  }
}

@media only screen and (max-width: 769px) {
  #header li.quick-cart .quick-cart-box {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 60px;
    margin-top: 0;
    border: rgba(0, 0, 0, 0.08) 1px solid !important;
  }

  #header.dark li.quick-cart .quick-cart-box {
    border: rgba(255, 255, 255, 0.08) 1px solid;
  }

  #header li.quick-cart .quick-cart-wrapper {
    max-height: 200px;
    overflow-y: auto;
  }

  /**
            Quick Cart & top Search Fix (if #topBar exists).
            .has-topBar - added by Javascript
        **/

  #header ul.has-topBar > li.quick-cart .quick-cart-box,
  #header ul.has-topBar > li.search .search-box {
    top: 98px !important;
  }
}

.badge.badge-corner {
  top: -8px !important;
  right: -6px !important;
  position: absolute !important;
  color: #fff !important;
}

/*--------------------------------------------------============================================================
										36. cart check out
=================================================--------------------------------------------------------------*/

.cart-checkout-wraper {
  padding: 80px 60px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.08);
  background-color: #FFFFFF;
}

.checkout-form-group {
  width: calc(100% - 90px);
}

.sign_in_form {
  padding-bottom: 30px;
  margin-bottom: 60px;
  border-bottom: 1px solid #E0E1E3;
}

.sign_in_form .simple-btn {
  color: #70799f;
  font-weight: 400;
  text-decoration: underline;
  margin-top: 16px;
}

.billing-form .xs-title,
.sign_in_form .xs-title {
  margin-bottom: 30px;
}

.billing-form .form-group {
  margin-bottom: 30px;
}

.billing-form .form-group:last-child {
  margin-bottom: 0px;
}

.checkout_info .xs-title {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #E0E1E3;
}

.checkout_info .gap {
  height: 35px;
}

.checkout_info tbody tr td {
  text-align: right;
}

.checkout_info .wc-proceed-to-checkout .xs-btn-wraper {
  text-align: right;
}

.checkout_info .cart_table_wraper {
  width: calc(100% - 25px);
}

.checkout_info .table tbody + tbody {
  border: 0px;
}

/*--------------------------------------------------============================================================
										37. VPS Slider
=================================================--------------------------------------------------------------*/

.vps-slider-wraper {
  background-color: #FFFFFF;
  box-shadow: 0px 20px 45px 0px rgba(0, 0, 0, 0.08);
}

.vps-slider {
  background-color: rgba(255, 255, 255, 0.102);
  height: 7px;
  border-radius: 0;
  border: 0;
  width: calc(100% - 15px);
  margin: 0 auto;
}

.vps-slider .ui-widget-header {
  background-color: #FFFFFF;
  border-radius: 0px;
}

.vps-slider .ui-slider-handle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  border: 0px;
}

.vps-slider .ui-slider-handle::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #4455CC;
}

.slider-group {
  background-color: #4455CC;
  padding: 50px;
}

.slider-range {
  margin-top: 36px;
}

.slider-tigger {
  position: relative;
  padding-top: 40px;
  margin: 0 auto;
  float: left;
  display: inline-block;
  width: calc(calc(100% / 3) - 15px);
  overflow: hidden;
  cursor: pointer;
}

.slider-tigger:not(:last-child) {
  margin-right: 15px;
}

.slider-tigger::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(./../images/vps/range.png);
  background-repeat: no-repeat;
}

.slider-tigger h5 {
  color: #5e73cc;
  transition: all 0.4s ease;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  margin-bottom: 0px;
}

.slider-tigger.current h5 {
  color: #FFFFFF;
}

.slider-content-group {
  padding: 80px 60px;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.slider-container .title {
  font-style: italic;
  font-weight: 700;
  margin-bottom: 0px;
}

.slider-container .price {
  color: #4455CC;
  font-size: 3.73333333rem;
  font-weight: 700;
  margin-bottom: 24px;
}

.slider-container .desc {
  font-size: 1rem;
  margin-bottom: 36px;
}

.slider-container .btn {
  border-radius: 0px;
  padding: 21px 47px;
  font-size: 18px;
  font-weight: 500;
  background-color: #4455CC;
  font-family: "Rubik", sans-serif;
}

.slider-container .btn span {
  position: relative;
  top: 1px;
  padding-right: 16px;
}

.vps-pricing-list > li {
  max-width: calc(33.33333% - 60px);
  width: 100%;
  float: left;
  display: inline-block;
}

.vps-pricing-list > li:not(:last-child) {
  margin-right: 60px;
}

.vps-pricing-list > li > h4 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.vps-pricing-list > li > p {
  margin-bottom: 0px;
  font-size: 1rem;
}

.vps-pricing-list:not(:last-child) > li {
  padding-bottom: 26px;
  margin-bottom: 26px;
  border-bottom: 1px solid #e7e7e7;
}

/*--------------------------------------------------============================================================
										38. Dedicated Server
=================================================--------------------------------------------------------------*/

.server-price-wraper .xs-title {
  font-size: 1.86666667rem;
  color: #4455CC;
  margin-bottom: 32px;
}

.server-price-table thead tr {
  background-color: #4455CC;
  color: #FFFFFF;
  position: relative;
  background-image: url(./../images/dedicated-server/1.png);
  background-repeat: no-repeat;
}

.server-price-table thead tr th {
  border-bottom: 0px;
}

.server-price-table thead tr th,
.server-price-table thead tr td,
.server-price-table tbody tr th,
.server-price-table tbody tr td {
  font-size: 0.86666667rem;
  padding: 22px;
  font-weight: 400;
}

.server-price-table tbody {
  border: 1px solid #f7f7f7;
}

.server-price-table tbody td,
.server-price-table tbody th {
  border-right: 1px solid #f7f7f7;
  border-top: 0px;
  vertical-align: middle;
  color: #000000;
}

.server-price-table tbody .price {
  color: #fa6700;
}

.server-price-table tbody .price del {
  color: #a3a3a3;
  padding-right: 6px;
}

.server-price-table tbody a.featured {
  color: #fa6700;
}

.server-price-table tbody a.featured.disabled {
  pointer-events: none;
  color: #9d9d9d;
}

.server-price-table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f7f7f7;
}

.server-price-table .tag {
  color: #FFFFFF;
  height: 18px;
  line-height: 18px;
  background-color: #fa6700;
  padding: 0 5px;
  font-size: 0.73333333rem;
  display: inline-block;
  margin-right: 15px;
}

.server-price-table .tag.featured {
  background-color: #4455CC;
}

/*--------------------------------------------------============================================================
										39. Flags
=================================================--------------------------------------------------------------*/

.flag {
  width: 16px;
  height: 11px;
  background: url(./../images/worldflags.png) no-repeat;
  display: inline-block;
  margin-right: 5px;
}

.flag.flag-ad {
  background-position: -16px 0;
}

.flag.flag-ae {
  background-position: -32px 0;
}

.flag.flag-af {
  background-position: -48px 0;
}

.flag.flag-ag {
  background-position: -64px 0;
}

.flag.flag-ai {
  background-position: -80px 0;
}

.flag.flag-al {
  background-position: -96px 0;
}

.flag.flag-am {
  background-position: -112px 0;
}

.flag.flag-an {
  background-position: -128px 0;
}

.flag.flag-ao {
  background-position: -144px 0;
}

.flag.flag-ar {
  background-position: -160px 0;
}

.flag.flag-as {
  background-position: -176px 0;
}

.flag.flag-at {
  background-position: -192px 0;
}

.flag.flag-au {
  background-position: -208px 0;
}

.flag.flag-aw {
  background-position: -224px 0;
}

.flag.flag-az {
  background-position: -240px 0;
}

.flag.flag-ba {
  background-position: 0 -11px;
}

.flag.flag-bb {
  background-position: -16px -11px;
}

.flag.flag-bd {
  background-position: -32px -11px;
}

.flag.flag-be {
  background-position: -48px -11px;
}

.flag.flag-bf {
  background-position: -64px -11px;
}

.flag.flag-bg {
  background-position: -80px -11px;
}

.flag.flag-bh {
  background-position: -96px -11px;
}

.flag.flag-bi {
  background-position: -112px -11px;
}

.flag.flag-bj {
  background-position: -128px -11px;
}

.flag.flag-bm {
  background-position: -144px -11px;
}

.flag.flag-bn {
  background-position: -160px -11px;
}

.flag.flag-bo {
  background-position: -176px -11px;
}

.flag.flag-br {
  background-position: -192px -11px;
}

.flag.flag-bs {
  background-position: -208px -11px;
}

.flag.flag-bt {
  background-position: -224px -11px;
}

.flag.flag-bv {
  background-position: -240px -11px;
}

.flag.flag-bw {
  background-position: 0 -22px;
}

.flag.flag-by {
  background-position: -16px -22px;
}

.flag.flag-bz {
  background-position: -32px -22px;
}

.flag.flag-ca {
  background-position: -48px -22px;
}

.flag.flag-catalonia {
  background-position: -64px -22px;
}

.flag.flag-cd {
  background-position: -80px -22px;
}

.flag.flag-cf {
  background-position: -96px -22px;
}

.flag.flag-cg {
  background-position: -112px -22px;
}

.flag.flag-ch {
  background-position: -128px -22px;
}

.flag.flag-ci {
  background-position: -144px -22px;
}

.flag.flag-ck {
  background-position: -160px -22px;
}

.flag.flag-cl {
  background-position: -176px -22px;
}

.flag.flag-cm {
  background-position: -192px -22px;
}

.flag.flag-cn {
  background-position: -208px -22px;
}

.flag.flag-co {
  background-position: -224px -22px;
}

.flag.flag-cr {
  background-position: -240px -22px;
}

.flag.flag-cu {
  background-position: 0 -33px;
}

.flag.flag-cv {
  background-position: -16px -33px;
}

.flag.flag-cw {
  background-position: -32px -33px;
}

.flag.flag-cy {
  background-position: -48px -33px;
}

.flag.flag-cz {
  background-position: -64px -33px;
}

.flag.flag-de {
  background-position: -80px -33px;
}

.flag.flag-dj {
  background-position: -96px -33px;
}

.flag.flag-dk {
  background-position: -112px -33px;
}

.flag.flag-dm {
  background-position: -128px -33px;
}

.flag.flag-do {
  background-position: -144px -33px;
}

.flag.flag-dz {
  background-position: -160px -33px;
}

.flag.flag-ec {
  background-position: -176px -33px;
}

.flag.flag-ee {
  background-position: -192px -33px;
}

.flag.flag-eg {
  background-position: -208px -33px;
}

.flag.flag-eh {
  background-position: -224px -33px;
}

.flag.flag-england {
  background-position: -240px -33px;
}

.flag.flag-er {
  background-position: 0 -44px;
}

.flag.flag-es {
  background-position: -16px -44px;
}

.flag.flag-et {
  background-position: -32px -44px;
}

.flag.flag-eu {
  background-position: -48px -44px;
}

.flag.flag-fi {
  background-position: -64px -44px;
}

.flag.flag-fj {
  background-position: -80px -44px;
}

.flag.flag-fk {
  background-position: -96px -44px;
}

.flag.flag-fm {
  background-position: -112px -44px;
}

.flag.flag-fo {
  background-position: -128px -44px;
}

.flag.flag-fr {
  background-position: -144px -44px;
}

.flag.flag-ga {
  background-position: -160px -44px;
}

.flag.flag-gb {
  background-position: -176px -44px;
}

.flag.flag-gd {
  background-position: -192px -44px;
}

.flag.flag-ge {
  background-position: -208px -44px;
}

.flag.flag-gf {
  background-position: -224px -44px;
}

.flag.flag-gg {
  background-position: -240px -44px;
}

.flag.flag-gh {
  background-position: 0 -55px;
}

.flag.flag-gi {
  background-position: -16px -55px;
}

.flag.flag-gl {
  background-position: -32px -55px;
}

.flag.flag-gm {
  background-position: -48px -55px;
}

.flag.flag-gn {
  background-position: -64px -55px;
}

.flag.flag-gp {
  background-position: -80px -55px;
}

.flag.flag-gq {
  background-position: -96px -55px;
}

.flag.flag-gr {
  background-position: -112px -55px;
}

.flag.flag-gs {
  background-position: -128px -55px;
}

.flag.flag-gt {
  background-position: -144px -55px;
}

.flag.flag-gu {
  background-position: -160px -55px;
}

.flag.flag-gw {
  background-position: -176px -55px;
}

.flag.flag-gy {
  background-position: -192px -55px;
}

.flag.flag-hk {
  background-position: -208px -55px;
}

.flag.flag-hm {
  background-position: -224px -55px;
}

.flag.flag-hn {
  background-position: -240px -55px;
}

.flag.flag-hr {
  background-position: 0 -66px;
}

.flag.flag-ht {
  background-position: -16px -66px;
}

.flag.flag-hu {
  background-position: -32px -66px;
}

.flag.flag-ic {
  background-position: -48px -66px;
}

.flag.flag-id {
  background-position: -64px -66px;
}

.flag.flag-ie {
  background-position: -80px -66px;
}

.flag.flag-il {
  background-position: -96px -66px;
}

.flag.flag-im {
  background-position: -112px -66px;
}

.flag.flag-in {
  background-position: -128px -66px;
}

.flag.flag-io {
  background-position: -144px -66px;
}

.flag.flag-iq {
  background-position: -160px -66px;
}

.flag.flag-ir {
  background-position: -176px -66px;
}

.flag.flag-is {
  background-position: -192px -66px;
}

.flag.flag-it {
  background-position: -208px -66px;
}

.flag.flag-je {
  background-position: -224px -66px;
}

.flag.flag-jm {
  background-position: -240px -66px;
}

.flag.flag-jo {
  background-position: 0 -77px;
}

.flag.flag-jp {
  background-position: -16px -77px;
}

.flag.flag-ke {
  background-position: -32px -77px;
}

.flag.flag-kg {
  background-position: -48px -77px;
}

.flag.flag-kh {
  background-position: -64px -77px;
}

.flag.flag-ki {
  background-position: -80px -77px;
}

.flag.flag-km {
  background-position: -96px -77px;
}

.flag.flag-kn {
  background-position: -112px -77px;
}

.flag.flag-kp {
  background-position: -128px -77px;
}

.flag.flag-kr {
  background-position: -144px -77px;
}

.flag.flag-kurdistan {
  background-position: -160px -77px;
}

.flag.flag-kw {
  background-position: -176px -77px;
}

.flag.flag-ky {
  background-position: -192px -77px;
}

.flag.flag-kz {
  background-position: -208px -77px;
}

.flag.flag-la {
  background-position: -224px -77px;
}

.flag.flag-lb {
  background-position: -240px -77px;
}

.flag.flag-lc {
  background-position: 0 -88px;
}

.flag.flag-li {
  background-position: -16px -88px;
}

.flag.flag-lk {
  background-position: -32px -88px;
}

.flag.flag-lr {
  background-position: -48px -88px;
}

.flag.flag-ls {
  background-position: -64px -88px;
}

.flag.flag-lt {
  background-position: -80px -88px;
}

.flag.flag-lu {
  background-position: -96px -88px;
}

.flag.flag-lv {
  background-position: -112px -88px;
}

.flag.flag-ly {
  background-position: -128px -88px;
}

.flag.flag-ma {
  background-position: -144px -88px;
}

.flag.flag-mc {
  background-position: -160px -88px;
}

.flag.flag-md {
  background-position: -176px -88px;
}

.flag.flag-me {
  background-position: -192px -88px;
}

.flag.flag-mg {
  background-position: -208px -88px;
}

.flag.flag-mh {
  background-position: -224px -88px;
}

.flag.flag-mk {
  background-position: -240px -88px;
}

.flag.flag-ml {
  background-position: 0 -99px;
}

.flag.flag-mm {
  background-position: -16px -99px;
}

.flag.flag-mn {
  background-position: -32px -99px;
}

.flag.flag-mo {
  background-position: -48px -99px;
}

.flag.flag-mp {
  background-position: -64px -99px;
}

.flag.flag-mq {
  background-position: -80px -99px;
}

.flag.flag-mr {
  background-position: -96px -99px;
}

.flag.flag-ms {
  background-position: -112px -99px;
}

.flag.flag-mt {
  background-position: -128px -99px;
}

.flag.flag-mu {
  background-position: -144px -99px;
}

.flag.flag-mv {
  background-position: -160px -99px;
}

.flag.flag-mw {
  background-position: -176px -99px;
}

.flag.flag-mx {
  background-position: -192px -99px;
}

.flag.flag-my {
  background-position: -208px -99px;
}

.flag.flag-mz {
  background-position: -224px -99px;
}

.flag.flag-na {
  background-position: -240px -99px;
}

.flag.flag-nc {
  background-position: 0 -110px;
}

.flag.flag-ne {
  background-position: -16px -110px;
}

.flag.flag-nf {
  background-position: -32px -110px;
}

.flag.flag-ng {
  background-position: -48px -110px;
}

.flag.flag-ni {
  background-position: -64px -110px;
}

.flag.flag-nl {
  background-position: -80px -110px;
}

.flag.flag-no {
  background-position: -96px -110px;
}

.flag.flag-np {
  background-position: -112px -110px;
}

.flag.flag-nr {
  background-position: -128px -110px;
}

.flag.flag-nu {
  background-position: -144px -110px;
}

.flag.flag-nz {
  background-position: -160px -110px;
}

.flag.flag-om {
  background-position: -176px -110px;
}

.flag.flag-pa {
  background-position: -192px -110px;
}

.flag.flag-pe {
  background-position: -208px -110px;
}

.flag.flag-pf {
  background-position: -224px -110px;
}

.flag.flag-pg {
  background-position: -240px -110px;
}

.flag.flag-ph {
  background-position: 0 -121px;
}

.flag.flag-pk {
  background-position: -16px -121px;
}

.flag.flag-pl {
  background-position: -32px -121px;
}

.flag.flag-pm {
  background-position: -48px -121px;
}

.flag.flag-pn {
  background-position: -64px -121px;
}

.flag.flag-pr {
  background-position: -80px -121px;
}

.flag.flag-ps {
  background-position: -96px -121px;
}

.flag.flag-pt {
  background-position: -112px -121px;
}

.flag.flag-pw {
  background-position: -128px -121px;
}

.flag.flag-py {
  background-position: -144px -121px;
}

.flag.flag-qa {
  background-position: -160px -121px;
}

.flag.flag-re {
  background-position: -176px -121px;
}

.flag.flag-ro {
  background-position: -192px -121px;
}

.flag.flag-cs {
  background-position: -208px -121px;
}

.flag.flag-ru {
  background-position: -224px -121px;
}

.flag.flag-rw {
  background-position: -240px -121px;
}

.flag.flag-sa {
  background-position: 0 -132px;
}

.flag.flag-sb {
  background-position: -16px -132px;
}

.flag.flag-sc {
  background-position: -32px -132px;
}

.flag.flag-scotland {
  background-position: -48px -132px;
}

.flag.flag-sd {
  background-position: -64px -132px;
}

.flag.flag-se {
  background-position: -80px -132px;
}

.flag.flag-sg {
  background-position: -96px -132px;
}

.flag.flag-sh {
  background-position: -112px -132px;
}

.flag.flag-si {
  background-position: -128px -132px;
}

.flag.flag-sk {
  background-position: -144px -132px;
}

.flag.flag-sl {
  background-position: -160px -132px;
}

.flag.flag-sm {
  background-position: -176px -132px;
}

.flag.flag-sn {
  background-position: -192px -132px;
}

.flag.flag-so {
  background-position: -208px -132px;
}

.flag.flag-somaliland {
  background-position: -224px -132px;
}

.flag.flag-sr {
  background-position: -240px -132px;
}

.flag.flag-ss {
  background-position: 0 -143px;
}

.flag.flag-st {
  background-position: -16px -143px;
}

.flag.flag-sv {
  background-position: -32px -143px;
}

.flag.flag-sx {
  background-position: -48px -143px;
}

.flag.flag-sy {
  background-position: -64px -143px;
}

.flag.flag-sz {
  background-position: -80px -143px;
}

.flag.flag-tc {
  background-position: -96px -143px;
}

.flag.flag-td {
  background-position: -112px -143px;
}

.flag.flag-tf {
  background-position: -128px -143px;
}

.flag.flag-tg {
  background-position: -144px -143px;
}

.flag.flag-th {
  background-position: -160px -143px;
}

.flag.flag-tj {
  background-position: -176px -143px;
}

.flag.flag-tk {
  background-position: -192px -143px;
}

.flag.flag-tl {
  background-position: -208px -143px;
}

.flag.flag-tm {
  background-position: -224px -143px;
}

.flag.flag-tn {
  background-position: -240px -143px;
}

.flag.flag-to {
  background-position: 0 -154px;
}

.flag.flag-tr {
  background-position: -16px -154px;
}

.flag.flag-tt {
  background-position: -32px -154px;
}

.flag.flag-tv {
  background-position: -48px -154px;
}

.flag.flag-tw {
  background-position: -64px -154px;
}

.flag.flag-tz {
  background-position: -80px -154px;
}

.flag.flag-ua {
  background-position: -96px -154px;
}

.flag.flag-ug {
  background-position: -112px -154px;
}

.flag.flag-um {
  background-position: -128px -154px;
}

.flag.flag-us {
  background-position: -144px -154px;
}

.flag.flag-uy {
  background-position: -160px -154px;
}

.flag.flag-uz {
  background-position: -176px -154px;
}

.flag.flag-va {
  background-position: -192px -154px;
}

.flag.flag-vc {
  background-position: -208px -154px;
}

.flag.flag-ve {
  background-position: -224px -154px;
}

.flag.flag-vg {
  background-position: -240px -154px;
}

.flag.flag-vi {
  background-position: 0 -165px;
}

.flag.flag-vn {
  background-position: -16px -165px;
}

.flag.flag-vu {
  background-position: -32px -165px;
}

.flag.flag-wales {
  background-position: -48px -165px;
}

.flag.flag-wf {
  background-position: -64px -165px;
}

.flag.flag-ws {
  background-position: -80px -165px;
}

.flag.flag-xk {
  background-position: -96px -165px;
}

.flag.flag-ye {
  background-position: -112px -165px;
}

.flag.flag-yt {
  background-position: -128px -165px;
}

.flag.flag-za {
  background-position: -144px -165px;
}

.flag.flag-zanzibar {
  background-position: -160px -165px;
}

.flag.flag-zm {
  background-position: -176px -165px;
}

.flag.flag-zw {
  background-position: -192px -165px;
}

.easy-autocomplete-container > ul > li {
  cursor: pointer;
  width: 90%;
  padding: 1px 5px 2px 5px;
}

.easy-autocomplete-container > ul > li:focus,
.easy-autocomplete-container > ul > li:hover,
.easy-autocomplete-container > ul > li:active {
  background: #0032BA;
  color: #FFFFFF;
}

/*--------------------------------------------------============================================================
										1. Element
=================================================--------------------------------------------------------------*/

.watermark-icon {
  position: absolute;
  right: -48px;
  top: -30px;
  color: rgba(255, 255, 255, 0.1);
  font-size: 11.33333333rem;
  z-index: -1;
}

/* round avatar */

.round-avatar > img {
  border-radius: 100%;
}

/*--------------------------------------------------============================================================
										2. Buttons
=================================================--------------------------------------------------------------*/

.btn:not([class*=btn-outline]) {
  border: 0px;
}

.btn-primary {
  background-color: #1169DA;
}

.btn-primary:hover::before {
  background-color: #4455CC;
}

.btn-secondary {
  background-color: #FFFFFF;
  color: #1169DA;
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
}

.btn-secondary:hover {
  background-color: #1169DA;
}

.btn-secondary:hover::before {
  background-color: #1169DA;
}

.btn-outline-secondary {
  border-color: #1169DA;
  color: #1169DA;
}

.btn-outline-secondary:hover {
  border-color: #4455CC;
}

.btn-outline-secondary:hover::before {
  background-color: #4455CC;
}

.btn {
  border-radius: 1.83333333rem;
  padding: 14px 45px;
  position: relative;
  letter-spacing: -.4px;
  overflow: hidden;
  transition: all 0.6s;
  z-index: 1;
}

.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.6s;
  transform: scale(0, 1);
}

.btn:hover::before {
  transform: scale(1, 1);
}

.btn.icon-right > i,
.btn.icon-left > i {
  position: relative;
  top: 4px;
}

.btn.icon-right > i {
  padding-left: 10px;
  float: right;
}

.btn.icon-left > i {
  padding-right: 10px;
  float: left;
}

.xs-btn-wraper .btn {
  margin-right: 15px;
}

.xs-btn-wraper .btn:last-child {
  margin-right: 0px;
}

.btn-outline-primary {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-outline-primary:hover {
  border-color: #1169DA;
}

.btn-outline-primary:hover::before {
  background-color: #1169DA;
}

.join-with-us-content .btn:hover::before {
  background-color: #4455CC;
}

input.btn-primary:hover {
  background-color: #4455CC;
}

input.btn-secondary:hover {
  background-color: #1169DA;
}

input.btn-outline-secondary:hover {
  background-color: #1169DA;
  border-color: #1169DA;
}

.xs-feature-group .btn-secondary:hover::before {
  background-color: #4455CC;
}

.preloader-cancel-btn .btn-secondary:hover::before {
  background-color: #4455CC;
}

.simple-btn {
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

.simple-btn i {
  font-size: 0.66666667rem;
  margin-top: 4px;
}

.simple-btn.icon-right i {
  float: right;
  padding-left: 8px;
}

.simple-btn.icon-left i {
  float: left;
  padding-right: 8px;
}

.expand-btn {
  min-width: 180px;
  padding-left: 40px;
  padding-right: 0px;
  text-align: left;
}

.expand-btn > i {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: center center;
  width: 39px;
  height: 39px;
  line-height: 38px;
  background-color: #2379f6;
  text-align: center;
  border-radius: 100%;
  font-size: 15px;
}

.gloosy-btn {
  display: block;
  width: 75px;
  height: 75px;
  line-height: 78px;
  border-radius: 100%;
  background-color: #FFFFFF;
  text-align: center;
  font-size: 1.33333333rem;
  position: relative;
  z-index: 1;
}

.gloosy-btn::before {
  position: absolute;
  content: "";
  top: 0%;
  left: 0%;
  transform-origin: center center;
  border-radius: 100%;
  height: 100%;
  width: 100%;
  animation: pulse 1.5s linear .5s infinite both;
  background-color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
  z-index: -1;
}

.gloosy-btn > i {
  background: #103dbe;
  background: -webkit-linear-gradient(45deg, #103dbe 0%, #13238e 32%, #15095e 99%);
  background: linear-gradient(45deg, #103dbe 0%, #13238e 32%, #15095e 99%);
  filter: drop-shadow(5px 0px 10px rgba(42, 95, 230, 0.35));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #1169DA;
  margin-left: 4px;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

/*--------------------------------------------------============================================================
										3. Social list
=================================================--------------------------------------------------------------*/

.social-list li {
  display: inline-block;
  margin-right: 10px;
}

.social-list li:last-child {
  margin-right: 0px;
}

.social-list li a {
  display: block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 100%;
  border: 1px solid #3399FA;
  text-align: center;
  font-size: 0.93333333rem;
  color: #23293A;
}

.social-list li a.facebook {
  color: #4969a8;
}

.social-list li a.facebook:hover {
  color: #FFFFFF;
  background: #4969a8;
}

.social-list li a.twitter {
  color: #2aa3ef;
}

.social-list li a.twitter:hover {
  color: #FFFFFF;
  background: #2aa3ef;
}

.social-list li a.linkedin {
  color: #1178b3;
}

.social-list li a.linkedin:hover {
  color: #FFFFFF;
  background: #1178b3;
}

.social-list li a.instagram {
  color: #e8715c;
}

.social-list li a.instagram:hover {
  color: #FFFFFF;
  background: #e8715c;
}

.social-list li a.googlePlus {
  color: #dc4a38;
}

.social-list li a.googlePlus:hover {
  color: #FFFFFF;
  background: #dc4a38;
}

.social-list li a.vimeo {
  color: #3399FA;
}

.social-list li a.vimeo:hover {
  color: #FFFFFF;
  background: #3399FA;
}

.social-list.version-2 li a {
  color: #FFFFFF;
  font-size: 0.79533333rem;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 0px;
}

.social-list.version-2 li a.facebook {
  background-color: #4969a8;
}

.social-list.version-2 li a.twitter {
  background-color: #2aa3ef;
}

.social-list.version-2 li a.linkedin {
  background-color: #1178b3;
}

.social-list.version-2 li a.instagram {
  background-color: #e8715c;
}

.social-list.version-2 li a.googlePlus {
  background-color: #dc4a38;
}

.social-list.version-2 li a.vimeo {
  background-color: #1ab7ea;
}

.social-list.version-3 li a {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
  transition: all 0.4s ease;
}

.social-list.version-3 li a:hover {
  color: #FFFFFF;
  background-color: #2aa3ef;
  border-color: #2aa3ef;
}

.simple-social-list li {
  margin-right: 32px;
}

.simple-social-list li:last-child {
  margin-right: 0px;
}

.simple-social-list li a {
  color: #FFFFFF;
  font-size: 1.2rem;
}

/*--------------------------------------------------============================================================
										4. Select
=================================================--------------------------------------------------------------*/

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #7b7b7b;
  width: 220px;
  height: 40px;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  padding: 8px 15px;
  transition: all 0.2s ease-in;
}

.select-styled:after {
  content: "\f107";
  position: absolute;
  top: 0px;
  right: 10px;
  font-family: "FontAwesome";
  transition: all 0.4s ease;
  transform-origin: center center;
  transform: rotateX(0deg);
}

.select-styled:hover {
  background-color: rgba(0, 0, 0, 0);
}

.select-styled:active,
.select-styled.active {
  background-color: rgba(0, 0, 0, 0);
}

.select-styled:active:after,
.select-styled.active:after {
  transform: rotateX(180deg);
  color: #1169DA;
}

.select-styled.active {
  color: #1169DA;
}

.select-options {
  display: none;
  position: absolute;
  top: calc(100% + 20px);
  left: -50%;
  min-width: 200px;
  z-index: 999;
}

.select-options::before {
  position: absolute;
  content: "";
  top: -8px;
  left: 50%;
  width: 16px;
  height: 16px;
  transform: translateX(-50%) rotate(45deg);
  z-index: -1;
}

.select-options li {
  padding: 8px 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.35);
  transition: all 0.4s ease;
  color: #FFFFFF;
}

.select-options li:hover {
  background: #0d52ab;
  border-top: 1px solid rgba(255, 255, 255, 0.35);
}

.select-options li[rel="hide"] {
  display: none;
}

.select-options,
.select-options::before {
  background-color: #1169DA;
}

/*--------------------------------------------------============================================================
										5. Section title
=================================================--------------------------------------------------------------*/

.xs-heading {
  margin-bottom: 75px;
  text-align: center;
}

.xs-heading .heading-sub-title {
  color: #8c8c8c;
  font-size: 1.33333333rem;
  font-weight: 400;
  font-family: "Poppins", Roboto, Helvetica Neue, Calibri, sans-serif;
}

.xs-heading .heading-title {
  font-size: 2rem;
  font-weight: 700;
}

.xs-heading .heading-title span {
  color: #4455CC;
}

.xs-heading.heading-v2 .heading-title {
  font-weight: 500;
}

/* content title */

.content-title {
  font-size: 2rem;
  font-weight: 700;
}

.content-title-wraper {
  margin-bottom: 40px;
}

.content-title-wraper .content-title {
  margin-bottom: 0px;
}

/*--------------------------------------------------============================================================
										6. nav tab
=================================================--------------------------------------------------------------*/

.main-nav-tab {
  box-shadow: 0px 20px 45px 0px rgba(0, 0, 0, 0.08);
  border-radius: 1.83333333rem;
  max-width: 260px;
  margin: 0 auto;
  background-color: #FFFFFF;
  justify-content: center;
  padding: 5px 0;
  z-index: 1;
  position: relative;
}

.main-nav-tab li {
  display: inline-block;
}

.main-nav-tab li a {
  display: block;
  color: #5f5f5f;
  min-width: 125px;
  transition: all 0.4s ease;
  padding: 8px 31px;
  text-align: center;
}

.main-nav-tab li a.active {
  color: #FFFFFF;
  background-color: #1169DA;
}

.main-nav-tab.tab-v2 {
  background-color: #7733FF;
}

.main-nav-tab.tab-v2 li a {
  color: #FFFFFF;
}

.main-nav-tab.tab-v2 li a.active {
  background-color: #FFFFFF;
  color: #7733FF;
}

.main-nav-tab.tab-v3 {
  background-color: #00eaff;
}

.main-nav-tab.tab-v3 li a {
  color: #24146d;
}

.main-nav-tab.tab-v3 li a.active {
  color: #00eaff;
  background-color: #24146d;
}

.main-nav-tab li a,
.tab-swipe .indicator {
  border-radius: 1.53333333rem;
}

.tab-swipe {
  position: relative;
}

.tab-swipe .indicator {
  background-color: #1169DA;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all .6s ease;
  height: calc(100% - 10px);
}

.tab-swipe li a.active {
  background-color: transparent;
}

.tab-swipe.tab-v2 .indicator {
  background-color: #FFFFFF;
}

.tab-swipe.tab-v2 li a.active {
  background-color: transparent;
}

.tab-swipe.tab-v3 .indicator {
  background-color: #24146d;
}

.tab-swipe.tab-v3 li a.active {
  background-color: transparent;
}

.main-nav-tab-2 {
  margin-bottom: 50px;
}

.main-nav-tab-2.nav-tabs {
  border-bottom: 1px solid rgba(9, 208, 201, 0.25);
}

.main-nav-tab-2 li {
  width: calc(100% / 4);
}

.main-nav-tab-2 li a {
  display: block;
  text-align: center;
  color: #FFFFFF;
  font-family: "Rubik", sans-serif;
  font-size: 1.33333333rem;
  font-weight: 500;
  padding-bottom: 40px;
}

.main-nav-tab-2 li a > p {
  min-height: 46px;
  display: block;
  margin-bottom: 15px;
}

.main-nav-tab-2.tab-swipe .indicator {
  top: inherit;
  transform: none;
  bottom: -1px;
  border-radius: 0px;
  height: 2px;
  background-color: #09d0c9;
}

/*--------------------------------------------------============================================================
										7. unorder list
=================================================--------------------------------------------------------------*/

.list-inline > li {
  display: inline-block;
}

.xs-list li {
  letter-spacing: -0.1px;
}

.xs-list li i {
  padding-right: 5px;
}

.xs-list.check li {
  position: relative;
}

.xs-list.check li::before {
  padding-right: 16px;
  content: "\ea71";
  font-family: "iconfont";
  color: #1169DA;
  position: relative;
  top: 3px;
  font-size: 1rem;
}

.xs-list.bullet li {
  position: relative;
}

.xs-list.bullet li::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  margin-right: 10px;
}

/*--------------------------------------------------============================================================
										8. breadCumb
=================================================--------------------------------------------------------------*/

.breadcrumbs > li > a {
  color: #FFFFFF;
}

.breadcrumbs > li:not(:first-child) {
  position: relative;
}

.breadcrumbs > li:not(:first-child)::before {
  content: "/";
  padding: 0 3px;
  display: inline-block;
}

/*--------------------------------------------------============================================================
										9. accordion
=================================================--------------------------------------------------------------*/

.xs-accordion {
  margin-bottom: 75px;
}

.xs-accordion > .card {
  border: 0;
  background-color: #FFFFFF;
  margin-bottom: 20px;
  transition: all 0.4s ease;
  border-radius: 0px;
}

.xs-accordion > .card:last-child {
  margin-bottom: 0px;
}

.xs-accordion > .card > .card-header {
  border-bottom: 0;
  background-color: transparent;
  border-radius: 0px;
  padding: 0;
}

.xs-accordion > .card > .card-header > a {
  display: block;
  color: #292929;
  font-family: "Rubik", sans-serif;
  background-color: #f8fafe;
  font-weight: 500;
  padding: 19px 0;
  position: relative;
  transition: all 0.4s ease;
}

.xs-accordion > .card > .card-header > a > i {
  position: absolute;
  right: 30px;
  top: 25px;
  transform: rotateX(0deg);
  transition: all 0.4s ease;
  font-size: 0.8rem;
  transform-origin: center center;
  color: #000000;
}

.xs-accordion > .card > .card-header > a[aria-expanded="true"] {
  background-color: transparent;
}

.xs-accordion > .card > .card-header > a[aria-expanded="true"] > i {
  transform: rotateX(-180deg);
}

.xs-accordion > .card.isActive {
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.06);
}

.xs-accordion > .card.isActive > .card-header > a {
  color: #4455CC;
}

.xs-accordion .card-body {
  padding-top: 0px;
  padding-bottom: 30px;
}

.xs-accordion .card-body p {
  margin-bottom: 0px;
}

.xs-accordion > .card > .card-header > a,
.xs-accordion .card-body {
  padding-left: 30px;
  padding-right: 30px;
}

.xs-accordion > .small {
  font-weight: 500;
  margin-bottom: 35px;
}

.xs-accordion:last-child {
  margin-bottom: 0px;
}

.xs-bg-gray .xs-accordion > .card > .card-header > a {
  background-color: #FFFFFF;
}

/*--------------------------------------------------============================================================
										10. map
=================================================--------------------------------------------------------------*/

.xs-map {
  height: 500px;
}

.xs-map + .xs-footer-section {
  margin-top: -150px;
}

/*--------------------------------------------------============================================================
										11. custom control
=================================================--------------------------------------------------------------*/

/* custom control */

.custom-control {
  line-height: 1;
  min-height: 100%;
}

.custom-control .custom-control-label::after,
.custom-control .custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 2px;
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.custom-control .custom-control-label::after {
  transform: scale(0);
}

.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  transform: scale(1);
}

.custom-control .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1169DA;
  background-color: #1169DA;
}

.custom-control .custom-control-label::before {
  background-color: #FFFFFF;
  border: 1px solid #afb6d2;
  pointer-events: visible;
  cursor: pointer;
  border-radius: 0px;
}

.custom-control .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control .custom-control-label {
  margin-bottom: 0;
  font-size: 1rem;
  color: #70799f;
  user-select: none;
  cursor: pointer;
  line-height: 1;
}

.custom_number {
  position: relative;
  height: 37px;
  width: 162px;
  user-select: none;
  margin: 0 auto;
}

.custom_number .sub,
.custom_number .add {
  float: left;
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .4s ease;
}

.custom_number .add {
  float: right;
}

.custom_number input[type="number"] {
  height: 100%;
  width: auto;
  background-color: #FFFFFF;
  text-align: center;
  pointer-events: none;
  border: 1px solid #E0E1E3;
  width: 62px;
  color: #1A2A3A;
}

.custom_number input[type="number"]::-webkit-inner-spin-button,
.custom_number input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*--------------------------------------------------============================================================
										12. pagination
=================================================--------------------------------------------------------------*/

/* xs pagination */

.pagination li {
  display: inline-block;
  margin: 0 6px 0 0;
}

.pagination li:last-child {
  margin: 0;
}

.pagination li a {
  width: 40px;
  height: 40px;
  line-height: 38px;
  transition: all 0.4s ease;
  color: #626c84;
  border: 1px solid;
  padding: 0;
  border-radius: 100%;
  text-align: center;
  font-size: 1.06666667rem;
}

.pagination li a:hover,
.pagination li a.current {
  background-color: #1169DA;
  color: #FFFFFF;
  border-color: #1169DA;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  border-radius: 100%;
}

.pagination .page-item:last-child .page-link {
  font-size: 0.8rem;
}

.pagination .page-item.disabled a,
.pagination li a {
  border-color: #f1f1f1;
}

/*--------------------------------------------------============================================================
										13. prelaoder
=================================================--------------------------------------------------------------*/

#preloader {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 9999999999;
  background-color: #1169DA;
  transition: all 1s ease-in;
}

#preloader .preloader-cancel-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 40px;
  transition: all 1s ease-in;
}

#preloader.loaded {
  top: -150%;
}

#preloader.loaded .preloader-cancel-btn {
  bottom: 150%;
}

.prelaoder-btn {
  padding: 10px 30px;
}

.preloader-wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/*--------------------------------------------------============================================================
										14. Order list
=================================================--------------------------------------------------------------*/

.terms-wraper:not(:last-child) {
  margin-bottom: 30px;
}

.order-list {
  padding-left: 0px;
  margin-bottom: 0px;
}

.order-list li {
  list-style: decimal;
  list-style-position: inside;
}

.order-list li:not(:last-child) {
  margin-bottom: 10px;
}

/*--------------------------------------------------============================================================
										15. sticky nav
=================================================--------------------------------------------------------------*/

.nav-sticky {
  transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
  background-color: rgba(0, 0, 0, 0.1);
}

.nav-sticky.sticky-header {
  position: fixed;
  transform: translateY(-150%);
  background: #0f0466;
  background: linear-gradient(-45deg, #1045db 0%, #4426cf 6%, #33139d 20%, #25097e 48%, #15095e 92%, #0A0046 99%);
  background-color: linear-gradient(-45deg, #1045db 0%, #4426cf 6%, #33139d 20%, #25097e 48%, #15095e 92%, #0A0046 99%);
  width: 100%;
  z-index: 100;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.nav-sticky.sticky-header .xs-logo {
  padding: 0;
}

.nav-sticky.sticky-header .xs-logo img {
  max-width: 215px;
}

.nav-sticky.sticky-header .xs-menu-tools {
  padding: 26px 0;
}

.nav-sticky.sticky-header .xs-menu-tools > li > a {
  color: #FFFFFF;
}

.nav-sticky.sticky-header .xs-menus .nav-menu > li > a {
  padding: 30px 0px;
  color: #FFFFFF;
}

.nav-sticky.sticky-header .xs-menus .nav-menu > li > a .submenu-indicator-chevron {
  border-color: transparent #FFFFFF #FFFFFF transparent;
}

.nav-sticky.sticky-header.sticky {
  transform: translateY(0%);
}

.nav-sticky.sticky-header.nav-light {
  background-color: #FFFFFF;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.nav-sticky.sticky-header .xs-top-bar {
  transform: translateY(-100%);
  margin-top: -60px;
}

.nav-sticky.sticky-header .header-boxed > .container::before {
  background-color: transparent;
  box-shadow: none;
}

.nav-sticky.sticky-header .sticky-logo {
  display: block;
}

.nav-sticky.sticky-header .main-logo {
  display: none;
}

.nav-sticky .xs-top-bar {
  transform: translateY(0%);
  margin-top: 0px;
  transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.nav-sticky .nav-menu > li > a {
  transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.nav-sticky .xs-logo {
  transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.nav-sticky .xs-menu-tools > li > a {
  transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.nav-sticky .xs-menus .megamenu-panel:not(.xs-icon-menu):not(.xs-service-menu) {
  overflow-y: scroll;
  height: 500px;
}

.sticky-logo {
  display: none;
}

/*--------------------------------------------------============================================================
										16. location map
=================================================--------------------------------------------------------------*/

.location {
  display: inline-block;
}

.location_indicator {
  position: relative;
  width: 15px;
  height: 15px;
  background-color: #1169DA;
  border-radius: 50%;
}

.location_indicator::before,
.location_indicator::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background-color: #1169DA;
  border-radius: 50%;
  animation: pulse 3s infinite cubic-bezier(0.4, 0, 1, 1) both;
}

.location_indicator::after {
  animation-delay: .5s;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(12, 90, 219, 0.2);
  }

  70% {
    box-shadow: 0 0 0 30px rgba(12, 90, 219, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(12, 90, 219, 0);
  }
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-inner {
  padding: 15px;
  width: 100%;
  max-width: 300px;
  text-align: left;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.location-name {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.location-des {
  margin-bottom: 0;
  color: #000000;
}

.tooltip .arrow::before {
  color: #FFFFFF;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: currentColor;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: currentColor;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: currentColor;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: currentColor;
}

.location-groups {
  position: relative;
}

.location-groups .location {
  position: absolute;
}

.location-groups .location.location-1 {
  top: 29%;
  left: 15%;
}

.location-groups .location.location-2 {
  top: 51%;
  left: 50%;
}

.location-groups .location.location-3 {
  right: 20%;
  top: 20%;
}

.location-groups .location.location-4 {
  top: 10%;
  left: 35%;
}

.location-groups .location.location-5 {
  left: 30%;
  bottom: 24%;
}

.location-groups .location.location-6 {
  right: 9%;
  bottom: 16%;
}

/*--------------------------------------------------============================================================
										17. shuffle letter
=================================================--------------------------------------------------------------*/

.shuufle-letter-title {
  font-size: 6.8rem;
  font-weight: 700;
  margin-bottom: 0px;
  font-family: "Poppins", Roboto, Helvetica Neue, Calibri, sans-serif;
  line-height: 1;
}

.pricing-section .shuffle-letter-title-wraper + .container {
  margin-top: -17px;
}

/*--------------------------------------------------============================================================
										18. owl dots
=================================================--------------------------------------------------------------*/

.owl-dots {
  text-align: center;
  margin-top: 15px;
}

.owl-dots .owl-dot {
  background-color: transparent;
  border: 0px;
  outline: none;
}

.owl-dots .owl-dot > span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #7733FF;
  opacity: .15;
  transition: all 0.4s ease;
}

.owl-dots .owl-dot.active > span {
  opacity: .60;
  transform: scale(2);
}

/*--------------------------------------------------============================================================
										19. menu highlight badge
=================================================--------------------------------------------------------------*/

.single-menu-panle {
  position: relative;
}

.single-menu-panle .menu-highlight-badge {
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #4455CC;
  color: #FFFFFF;
  font-size: 0.86666667rem;
  text-align: center;
  top: 5px;
  right: 5px;
}

/*--------------------------------------------------============================================================
										20. inheritance
=================================================--------------------------------------------------------------*/

.xs-section-padding {
  padding: 75px 15px;
}

.xs-section-padding-bottom {
  padding-bottom: 75px;
}

.xs-section-padding-top {
  padding-top: 75px;
}

.xs-mb-0 {
  margin-bottom: 0px;
}

.xs-pb-0 {
  padding-bottom: 0px;
}

.xs-mb-1 {
  margin-bottom: 10px;
}

.xs-pb-1 {
  padding-bottom: 10px;
}

.xs-mb-2 {
  margin-bottom: 20px;
}

.xs-pb-2 {
  padding-bottom: 20px;
}

.xs-mb-3 {
  margin-bottom: 30px;
}

.xs-pb-3 {
  padding-bottom: 30px;
}

.xs-mb-4 {
  margin-bottom: 40px;
}

.xs-pb-4 {
  padding-bottom: 40px;
}

.xs-mb-5 {
  margin-bottom: 50px;
}

.xs-pb-5 {
  padding-bottom: 50px;
}

.xs-mb-6 {
  margin-bottom: 60px;
}

.xs-pb-6 {
  padding-bottom: 60px;
}

.xs-mb-7 {
  margin-bottom: 70px;
}

.xs-pb-7 {
  padding-bottom: 70px;
}

.xs-mb-8 {
  margin-bottom: 80px;
}

.xs-pb-8 {
  padding-bottom: 80px;
}

.xs-mb-9 {
  margin-bottom: 90px;
}

.xs-pb-9 {
  padding-bottom: 90px;
}

.xs-mb-10 {
  margin-bottom: 100px;
}

.xs-pb-10 {
  padding-bottom: 100px;
}

.xs-margin-0 {
  margin: 0;
}

.xs-padding-0 {
  padding: 0;
}

.xs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .5;
  z-index: 0;
}

.parallax-window {
  background-attachment: fixed;
}

/* color */

.xs-color-black {
  color: #000000;
}

/* background color */

.xs-bg-black {
  background-color: #000000;
}

/* color */

.xs-color-white {
  color: #FFFFFF;
}

/* background color */

.xs-bg-white {
  background-color: #FFFFFF;
}

/* color */

.xs-color-primary {
  color: #1169DA;
}

/* background color */

.xs-bg-primary {
  background-color: #1169DA;
}

/* color */

.xs-color-secondary {
  color: #4455CC;
}

/* background color */

.xs-bg-secondary {
  background-color: #4455CC;
}

/* color */

.xs-color-gray {
  color: #F0EEEF;
}

/* background color */

.xs-bg-gray {
  background-color: #F0EEEF;
}

/* remove from control focus box shadow */

.form-control:focus {
  box-shadow: none;
}

p,
span,
small,
strong,
a {
  letter-spacing: -0.1px;
}

h1,
h2,
h3,
h4 {
  font-family: "Rubik", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #23293A;
  font-family: "Rubik", sans-serif;
}

a {
  color: #3080ff;
}

.xs-title,
h4 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 16px;
}

.xs-title.small,
h4.small {
  font-size: 1.33333333rem;
}

.xs-title a,
h4 a {
  color: #23293A;
  display: inline-block;
  transition: all 0.4s ease;
}

.xs-title a:hover,
h4 a:hover {
  color: #1169DA;
}

/* bootstrap nav style reset */

.nav-tabs {
  border-bottom: 0px;
}

/* owl image preset */

.owl-carousel .owl-item img {
  width: auto;
}

/* gradinet bg */

.bg-gradient {
  background: #1045db;
  background: -webkit-linear-gradient(135deg, #1045db 0%, #15095e 60%, #15095e 99%);
  background: linear-gradient(-45deg, #1045db 0%, #15095e 60%, #15095e 99%);
}

.bg-gradient .heading-sub-title,
.bg-gradient .heading-title,
.bg-gradient .heading-title span {
  color: #FFFFFF;
}

/* tab pane animation */

.tab-pane.animated {
  animation-duration: 1.5s;
}

/* remove iframe box shadow and background */

.mfp-iframe-scaler iframe {
  box-shadow: none;
  background: transparent;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/*
Author: XpeedStudio
Author URI: http://themeforest.net/user/XpeedStudio/portfolio
*/

/* Portrait tablets and medium desktops */

@media (min-width: 992px) and (max-width: 1199px) {
  html {
    font-size: 14px;
  }

  /* pricing table */

  .pricing-feature-group {
    padding: 20px;
  }

  .xs-menu-tools > li {
    margin-right: 15px;
  }

  /* footer */

  .footer-bottom .xs-list li {
    margin-right: 20px;
  }

  /* inner banner */

  .inner-welcome-image-group .banner-ico.banner-ico-1 {
    left: 44.5%;
  }

  .inner-welcome-image-group .banner-ico.banner-ico-2 {
    right: 12%;
  }

  .xs-menus.xs_nav-landscape .nav-menu {
    padding-right: 4px;
  }

  /* nav */

  .xs-menus .nav-menu > li {
    margin-right: 30px;
  }

  .xs-header .xs-logo {
    margin-left: -8px;
  }

  /* index version 6 banner */

  .xs-banner.banner-v6 .xs-banner-image {
    margin-left: 0;
    margin-right: 0;
    left: 0;
    margin-top: 0;
  }

  #rev_slider_17_1_forcefullwidth + .xs-domain-search {
    margin-top: -270px;
  }

  /* index version 9 */

  /* banner */

  .xs-banner.banner-v7 .xs-banner-image {
    bottom: -306px;
    margin-right: 0;
  }

  .banner-6-content.xs-banner-content .banner-title {
    font-size: 2.32353rem;
  }

  .xs-banner.banner-tj {
    min-height: 80vh;
    max-height: 1150px;
  }

  .xs-domain-search.domainSearch-v2 .domain-search-form-group {
    width: 100%;
    padding: 26px;
  }
}

/* Portrait tablets and small desktops */

@media (max-width: 991px) {
  .xs-banner-content {
    margin-left: -20px;
  }

  html {
    font-size: 90%;
  }

  /* margin bottom list */

  .why-choose-us-block,
  .xs-single-pricing,
  .xs-feature-group,
  .hosting-info-img,
  .hosting-info-wraper-2,
  .xs-blog-post,
  .footer-widget {
    margin-bottom: 30px;
  }

  /* header */

  .xs-header {
    padding-bottom: 0;
  }

  .nav-toggle {
    padding-top: 5px;
  }

  .sticky-header.sticky .xs_nav-portrait {
    height: 65px;
  }

  .sticky-header.sticky .xs_nav-portrait .nav-header {
    margin-top: 0;
  }

  .sticky-header.sticky .xs_nav-portrait .nav-header .nav-brand img {
    max-height: 65px;
    max-width: 185px;
  }

  .sticky-header.sticky .xs_nav-portrait .nav-toggle {
    padding-top: 5px;
  }

  .xs-header .xs-logo {
    display: none;
  }

  .xs-header .xs-menus .nav-menu > li > a,
  .xs-header.header-boxed .xs-menus .nav-menu > li > a,
  .xs-header.header-v3 .xs-menus .nav-menu > li > a,
  .nav-sticky.sticky-header .xs-menus .nav-menu > li > a {
    color: #1e1e1e;
    padding: 15px;
  }

  /* header */

  .xs-menu-tools {
    display: none;
  }

  .xs-menus .nav-menu li > .nav-dropdown {
    box-shadow: none;
  }

  .xs-menus .nav-menu > li > a .submenu-indicator-chevron,
  .nav-sticky.sticky-header .xs-menus .nav-menu > li > a .submenu-indicator-chevron {
    border-color: transparent #1e1e1e #1e1e1e transparent;
  }

  .nav-toggle:before {
    background-color: #FFFFFF;
    box-shadow: 0 0.5em 0 0 #FFFFFF, 0 1em 0 0 #FFFFFF;
  }

  .xs-header.header-boxed .nav-toggle:before {
    background-color: #1e1e1e;
    box-shadow: 0 0.5em 0 0 #1e1e1e, 0 1em 0 0 #1e1e1e;
  }

  .xs-header.header-boxed > .container::before {
    display: none;
  }

  /* domain search */

  .domain-search-form {
    width: calc(100% + 10px);
  }

  /* section title */

  .xs-heading .heading-title {
    font-size: 1.3rem;
  }

  /* footer */

  .footer-bottom .xs-list {
    text-align: center;
  }

  .footer-bottom .xs-list li {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  /* index version 3 */

  .pricing-feature-item > .pricing-feature:first-child {
    height: 30px;
    padding: 7px 10px;
    font-weight: 700;
  }

  .pricing-feature {
    padding: 10px 40px;
    height: 40px;
  }

  /* inner welcome */

  .inner-welcome-image-group {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    max-height: 50vh;
    margin-top: 50px;
  }

  /* inner welcome service banner */

  .xs-banner.service-banner-2 {
    min-height: 85vh;
    padding-top: 85px;
  }

  .social-list li {
    margin-bottom: 10px;
  }

  .service-banner .banner-title {
    font-size: 2.76471rem;
  }

  .xs-banner.service-banner {
    min-height: 85vh;
    padding-top: 85px;
  }

  /* inner banner */

  .inner-banner .inner-welcome-image-group {
    display: none;
  }

  /* team */

  .xs-single-team {
    margin-bottom: 30px;
  }

  .xs-single-team img {
    width: 100%;
  }

  /* contact */

  .contact-info-group {
    margin-bottom: 30px;
  }

  /* accordion */

  .xs-accordion,
  .xs-accordion:last-child {
    margin-bottom: 60px;
  }

  /* checkout */

  .checkout-form-group {
    width: calc(100% - 45px);
  }

  .checkout_info .cart_table_wraper {
    width: 100%;
  }

  .cart-checkout-wraper {
    padding: 50px;
  }

  .wc-proceed-to-checkout {
    width: 100%;
  }

  .wc-proceed-to-checkout .btn {
    padding: 15px 29px;
    font-size: .8rem;
  }

  /* vps hosting */

  .slider-content-group {
    padding: 30px;
  }

  .slider-content-group .slider-container {
    margin-bottom: 30px;
  }

  /* sticky nav */

  .nav-sticky .xs_nav-portrait .nav-menus-wrapper,
  .nav-sticky .nav-overlay-panel {
    height: 110vh;
  }

  /* index verison 6 */

  /* banner */

  .xs-banner.banner-v6 .xs-banner-image {
    display: none;
  }

  #rev_slider_17_1_forcefullwidth + .xs-domain-search {
    margin-top: 0;
    margin-bottom: 79px;
  }

  .tp-caption .banner-6-content.xs-banner-content {
    padding-top: 130px !important;
  }

  /* index version 9 */

  /* banner */

  .xs-banner.banner-v7 .xs-banner-image {
    display: none;
  }

  .xs-banner.skew-bg::after {
    border-bottom: 150px solid #FFFFFF;
  }

  /* index version 10 */

  .xs-banner.banner-tj > .container {
    padding-top: 220px;
  }

  .xs-banner.banner-tj .xs-banner-image {
    margin-left: auto;
    margin-right: -30px;
    left: 5px;
    width: 100%;
    padding: 0;
    margin-top: -100px;
  }

  .xs-footer-section.footer-v2 .footer-group {
    background-position: center center;
  }

  .xs-footer-section.footer-v2 .footer-main {
    padding-bottom: 60px;
    padding-top: 50px;
  }

  .xs-banner.curve-bg {
    padding-top: 60px;
  }

  .banner-title {
    font-size: 56px;
    letter-spacing: -1.4px;
  }

  .xs-domain-search.domainSearch-v2 .domain-search-form-group {
    width: 100%;
    padding: 20px;
  }

  .xs-domain-info {
    width: 90%;
    padding-left: 15px;
  }
}

/* Landscape phones and portrait tablets */

@media (max-width: 767px) {
  .xs-domain-info {
    width: calc(100% + 40px);
    margin-left: -15px;
  }

  .xs-banner-content {
    margin-left: 0;
    text-align: center;
    padding-bottom: 10px;
  }

  /* section padding */

  .xs-section-padding {
    padding: 50px 0;
  }

  /* heading tilte margin bottom */

  .xs-heading {
    margin-bottom: 40px;
  }

  /* section tilte */

  .xs-heading .heading-title {
    font-size: 1.4rem;
    font-weight: 700;
  }

  /* list inline */

  .list-inline > li {
    display: block;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .list-inline > li:last-child {
    margin-bottom: 0px;
  }

  /* header */

  .xs-logo-wraper {
    display: none;
  }

  .xs-top-bar-info,
  .top-menu {
    text-align: center;
    padding: 5px 0;
  }

  .xs-top-bar-info li {
    margin-right: 15px;
  }

  /* welcome */

  .banner-title {
    font-size: 42px;
    line-height: 1;
  }

  .banner-sub-title {
    font-size: 32px;
    line-height: 1.4;
  }

  .xs-banner .xs-banner-image {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
  }

  .xs-banner .xs-banner-image .banner-ico {
    width: 16px;
  }

  .xs-banner-content p {
    margin-bottom: 20px;
    line-height: 1.2;
  }

  /* domain search */

  .xs-domain-info li {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid rgba(75, 100, 225, 0.25);
    text-align: left;
  }

  .xs-domain-info li img {
    margin-right: 3px;
  }

  /* domain search */

  .domain-search-form {
    height: 100%;
  }

  .domain-search-form-group {
    padding: 10px;
  }

  .domain-search-form .select-group {
    margin-top: -4px;
    margin-right: -6px;
  }

  .domain-search-form .select-group .select {
    right: -2px;
  }

  .domain-search-form input:not([type="submit"]) {
    height: 60px;
    padding: 0 16px;
  }

  .xs-domain-info li:last-child {
    margin-bottom: 0px;
  }

  /* all buttons */

  .xs-btn-wraper .btn {
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .btn {
    font-size: .9rem;
    padding: 10px 25px;
  }

  /* why choose us */

  .why-choose-us-block {
    padding: 0;
  }

  /* pricing */

  .pricing-info .xs-list li {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .pricing-info .xs-list li:last-child {
    margin-bottom: 0px;
  }

  /* service section */

  .service-section .xs-mb-8 {
    margin-bottom: 0px;
  }

  .xs-service-block {
    margin-bottom: 40px;
  }

  .xs-service-block {
    padding: 0;
  }

  /* call to action */

  .call-to-action-section {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 30px;
  }

  .call-to-action-section + .xs-feature-section {
    margin-top: 0;
  }

  /* feature group */

  .xs-feature-group .media {
    flex-wrap: wrap;
  }

  .xs-feature-group .feature-img {
    margin-bottom: 15px;
  }

  .xs-feature-group .watermark-icon {
    display: none;
  }

  .xs-feature-group {
    padding: 20px;
  }

  /* hosting info */

  .hosting-info-img .info-icon {
    width: 30px;
  }

  .hosting-info-img .info-icon.icon-2 {
    left: 0;
  }

  .hosting-info-wraper {
    padding-left: 0;
    margin-bottom: 50px;
  }

  .hosting-info-wraper-2 .content-title {
    margin-bottom: 30px;
  }

  .xs-hosting-info .row {
    margin-bottom: 45px;
  }

  /* content tilte */

  .content-title {
    font-size: 1.2rem;
  }

  /* testimonial */

  .xs-testimonial-item,
  .xs-testimonial-slider .owl-item.center .xs-testimonial-item {
    width: 100%;
    padding: 30px;
  }

  /* footer */

  .footer-bottom-info {
    margin-bottom: 20px;
  }

  .footer-group {
    background-size: cover;
  }

  /* copyright */

  .copyright-text {
    padding: 0px;
  }

  .footer-logo-wraper {
    padding: 15px 0;
  }

  .copyright-text p,
  .social-list {
    text-align: center;
  }

  /* index version 2 */

  .hosting-info-wraper-2 {
    margin-bottom: 50px;
  }

  /* index verison 3 */

  .xs-banner.banner-v3 {
    min-height: 900px;
  }

  .main-nav-tab-2 li {
    width: calc(100% / 2);
  }

  .main-nav-tab-2 li a {
    font-size: .9647rem;
  }

  /* inner banner */

  .service-banner .banner-title {
    font-size: 1.76471rem;
  }

  .xs-banner.service-banner {
    min-height: 700px;
  }

  .xs-banner.inner-banner .banner-title {
    font-size: 1.5882rem;
  }

  .breadcrumbs.list-inline > li {
    display: inline-block;
  }

  /* about */

  .gloosy-btn {
    width: 60px;
    height: 60px;
    line-height: 63px;
  }

  .join-with-us-wraper {
    margin-bottom: 30px;
    margin-top: 0;
    z-index: 2;
    padding: 20px 15px;
  }

  .office-interface-group-content.content-1 {
    margin-top: 0;
  }

  .office-interface-group-content.content-2 {
    margin-right: 0;
  }

  /* team */

  .simple-social-list li {
    display: inline-block;
    margin-right: 15px;
  }

  /* blog list */

  .post:not(.format-audio):not(.post-details) .post-media + .post-body {
    width: 100%;
    margin-top: 0;
  }

  .post-body .entry-title {
    font-size: 1.26471rem;
  }

  .post-body .post-meta > span:not(:last-child) {
    margin-bottom: 15px;
  }

  .xs-blog-list {
    margin-bottom: 50px;
  }

  .blog-lsit-group {
    margin-bottom: 50px;
  }

  .widget:not(.widget-search) {
    padding: 20px;
  }

  .widget:not(:last-child) {
    margin-bottom: 30px;
  }

  .sidebar-widget.sidebar-right {
    margin-left: 0px;
  }

  .sidebar-widget.sidebar-left {
    margin-right: 0px;
  }

  /* blog post */

  .xs-comments-area .comment:not(:last-child) .children {
    margin-left: 50px;
  }

  .simple-social-list.list-inline {
    text-align: left;
  }

  .author-info {
    padding: 30px;
    display: block;
  }

  .author-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .post-navigation [class^=col-]:first-child .post-navigation-title {
    margin-bottom: 20px;
  }

  .blog-post-group {
    margin-bottom: 30px;
  }

  .post-details .post-footer .tag-lists {
    margin-bottom: 20px;
  }

  /* contact */

  .xs-form-group {
    padding: 0;
  }

  /* pricing */

  .content-title-wraper {
    margin-bottom: 80px;
  }

  /* cart */

  .coupon {
    max-width: 100%;
    margin-left: 0;
    padding: 0 15px;
    margin-bottom: 20px;
  }

  /* checkout */

  .checkout-form-group {
    width: 100%;
  }

  .cart-checkout-wraper {
    padding: 20px;
  }

  .sign_in_form.xs-form .form-control:last-child,
  .sign_in_form.xs-form .select:last-child,
  .billing-form.xs-form .form-control:last-child,
  .billing-form.xs-form .select:last-child {
    margin-bottom: 30px;
  }

  .sign_in_form .simple-btn {
    margin-top: 12px;
  }

  .checkout_info .cart_table_wraper {
    width: 100%;
  }

  /* sign up */

  .xs-form > .row > [class^=col-]:first-child .form-group {
    padding-left: 0;
  }

  .xs-form > .row > [class^=col-]:last-child .form-group {
    padding-right: 0;
  }

  .xs-form > .row > [class^=col-] .form-group {
    margin-bottom: 20px;
  }

  /* 404 */

  .xs-banner.banner-404 .banner-title {
    font-size: 1.96471rem;
  }

  .xs-banner.banner-404 .xs-banner-image {
    width: 80%;
    margin: 0 auto;
  }

  .xs-banner.banner-404 {
    min-height: 750px;
  }

  /* index 5 */

  .xs-banner.banner-v4 .banner-title {
    font-size: 2rem;
  }

  .home5-banner-image {
    text-align: right;
    width: 70%;
    margin: 0 auto;
  }

  .wave_animation_wraper {
    display: none;
  }

  /* index 4 */

  .xs-banner.banner-v5 .xs-banner-content .banner-title {
    font-size: 1.82647rem;
  }

  .domain-search-form.version-2 .select-group {
    width: 120px;
    position: absolute;
    margin-top: 0;
  }

  .domain-search-form.version-2 input:not([type="submit"]) {
    padding-left: 133px;
  }

  .domain-search-form .search-btn {
    padding: 22px;
  }

  .domain-box-list li {
    margin-bottom: 12px;
  }

  /* location map */

  .location_indicator {
    display: none;
  }

  .location-groups .location {
    display: block;
    position: static;
    margin-bottom: 30px;
  }

  .location-groups .map-image {
    display: none;
  }

  /* sticky nav */

  .nav-sticky.sticky-header .xs-top-bar {
    margin-top: -90px;
  }

  /* location map */

  .location-wraper .location {
    float: left;
    width: 50%;
    display: inline-block;
  }

  /* index version 6 */

  /* banner */

  .xs-banner.banner-v6 {
    min-height: 500px;
  }

  .xs-banner.banner-v6 .xs-banner-content .banner-title {
    font-size: 1.82353rem;
  }

  .xs-banner.banner-v6 .xs-banner-content {
    padding-top: 180px;
  }

  .xs-banner.banner-v6 {
    margin-bottom: 50px;
  }

  /* blog */

  .post-image > img {
    width: 100%;
  }

  /* footer */

  .xs-footer-section.footer-v2 .social-list {
    text-align: left;
  }

  /* index version 9 */

  /* banner */

  .banner-6-content.xs-banner-content .banner-title {
    font-size: 1.82353rem;
    margin-bottom: 30px;
  }

  .xs-banner.banner-v7 {
    min-height: 650px;
  }

  /* index version 10 */

  .xs-banner.curve-bg::after {
    background-size: contain;
  }

  .xs-banner.banner-tj + .pricing-section {
    margin-top: -300px;
  }

  .xs-banner.banner-tj > .container {
    padding-top: 180px;
  }

  .xs-banner.banner-tj .xs-banner-image {
    width: 60%;
    left: 0;
    margin: 0 20%;
    padding: 0 20px;
  }

  .testimonial-v7 .owl-carousel .owl-item.center .xs-testimonial-item {
    box-shadow: 1px 6px 17px 0px rgba(0, 0, 0, 0.1);
  }

  .xs-footer-section.footer-v2 .footer-bottom .xs-list {
    text-align: left;
  }

  /* index version 11 */

  .xs-domain-search.version-primary .select-styled {
    color: #FFFFFF;
  }

  .xs-domain-search.version-primary .select-styled::after {
    color: #FFFFFF;
  }

  .xs-domain-search.version-primary .domain-search-form input[type="submit"] {
    background: #FFFFFF;
    color: #1351f4;
  }
}

/* Landscape phones and smaller */

@media (max-width: 480px) {
  .xs-domain-search.domainSearch-v2 .domain-search-form-group {
    width: calc(100% + 5px);
    padding: 0;
  }

  .xs-domain-search.domainSearch-v2 .domain-search-form-group .domain-search-form .select-group {
    max-width: 75px;
  }

  .xs-domain-search.domainSearch-v2 .domain-search-form-group .domain-search-form .select-options {
    left: -150px;
  }

  .domain-search-form input[type="submit"] {
    display: none;
  }

  .xs-domain-info {
    display: none;
  }

  html {
    font-size: 65%;
  }

  .xs-comments-area .comment:not(:last-child) .children {
    margin-left: 20px;
  }

  /* vps hosting */

  .vps-pricing-list > li {
    max-width: 100%;
  }

  .vps-pricing-list:last-child > li:not(:last-child) {
    padding-bottom: 26px;
    margin-bottom: 26px;
    border-bottom: 1px solid #e7e7e7;
  }

  /* locationi map */

  .location-wraper .location {
    width: 100%;
  }

  .tp-caption .banner-6-content.xs-banner-content .xs-list > li:not(:last-child) {
    margin-bottom: 5px !important;
  }

  .tp-caption .banner-6-content .xs-list {
    margin-bottom: 5px !important;
  }

  .tp-caption .banner-6-content.xs-banner-content {
    padding-top: 90px !important;
  }

  /* index version 9 */

  /* banner */

  .xs-banner.skew-bg::after,
  .xs-banner.banner-v7 .xs-banner-image {
    display: none;
  }

  .xs-banner.banner-v7 {
    min-height: 600px;
  }

  /* index version 10 */

  .shuufle-letter-title {
    font-size: 4rem;
  }

  .xs-banner.banner-tj + .pricing-section {
    margin-top: -250px;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 60.5%;
  }

  .tp-caption .banner-6-content.xs-banner-content .banner-title {
    font-size: 22px !important;
    margin-bottom: 15px !important;
  }

  .tp-caption .banner-6-content.xs-banner-content .banner-title span {
    font-size: inherit !important;
  }

  .tp-caption .banner-6-content.xs-banner-content {
    padding-top: 80px !important;
  }

  .tp-caption .banner-6-content.xs-banner-content .banner-title span::before {
    display: none;
  }

  /* index version 10 */

  .xs-banner.banner-tj + .pricing-section {
    margin-top: -350px;
  }

  .shuufle-letter-title {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  .hidden-xlg {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (max-width: 575px) {
  .hidden-xs {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (max-width: 1199px) {
  .hidden--lg {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (max-width: 991px) {
  .hidden--md {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .hidden--sm {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

html,
body {
  font-family: "Poppins", Roboto, Helvetica Neue, Calibri, sans-serif;
  font-weight: 400;
  line-height: 1.48;
  color: #1A2A3A;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  text-shadow: rgba(247, 252, 255, 0.2) 0 0.4px 0.4px;
}

.banner-icon {
  position: absolute;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: bounce 1s ease-in-out 0s infinite alternate;
  animation: bounce 1s ease-in-out 0s infinite alternate;
}

.banner-icon.banner-ico-1 {
  left: 24.5%;
  top: 6%;
}

.banner-icon.banner-ico-2 {
  left: inherit;
  right: 39.5%;
  top: 0px;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.inner-welcome-image-group .banner-icon.banner-ico-1 {
  left: 52.5%;
  top: 1%;
}

.inner-welcome-image-group .banner-icon.banner-ico-2 {
  right: 10%;
  top: 10%;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.inner-welcome-image-group.faq-icon .banner-icon.banner-ico-1 {
  left: 42.5%;
}

.inner-welcome-image-group.faq-icon .banner-icon.banner-ico-2 {
  right: 12.5%;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

.row.m0 {
  margin: 0px;
}

.p0 {
  padding: 0px;
}

i:before {
  margin-left: 0px !important;
}

body {
  letter-spacing: 0px;
  padding: 0px;
  overflow-x: hidden;
  z-index: 0;
}

.wrapper {
  position: relative;
  z-index: 20;
  overflow-x: hidden;
}

a,
.btn,
button {
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  box-shadow: none;
}

@media (min-width: 1250px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.container.custom_container {
  max-width: 1520px;
}

#btnScrollTo {
  border: 2px solid rgba(24, 112, 224, 0.3);
  box-shadow: 0 0 3px 1px rgba(80, 160, 248, 0.3) inset, 0 0 2px 0 rgba(75, 125, 225, 0.15);
  padding: 4px 0 6px 7px;
  width: 38px;
  display: block;
  margin: 0 8px 10px 0 !important;
  opacity: 0.75;
  text-align: center;
  transition-delay: 0.1s;
  transition-duration: 0.35s;
  font-weight: 700;
}

#btnScrollTo:hover {
  border: 2px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 4px 1px rgba(8, 24, 48, 0.4);
  opacity: 0.95;
  color: #ffffff;
}

.form-group label,
label {
  font-size: 14.5px;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  color: #3b444f;
  margin-bottom: 7px;
  margin-top: 3px;
  font-weight: 600;
}

.form-group label .required {
  color: #df3b42;
}

header.xs-header .xs-menus .nav-menus-wrapper .nav-menu .media img.menu-icon {
  display: block;
  width: auto;
  height: auto;
  max-width: 42px;
  max-height: 40px;
}

header.xs-header .xs-menus .nav-menus-wrapper .nav-menu .media img.menu-icon.lg-icon {
  max-width: 50px;
  max-height: 48px;
}

.hosting-info-wraper .seo-list.xs-list li,
.hosting-info-wraper .seo-list.xs-list li p {
  line-height: 1.54 !important;
  letter-spacing: -0.3px !important;
  word-spacing: -0.6px !important;
}

.owl-carousel-2 .owl-item {
  height: 175px !important;
  max-height: 175px !important;
  min-height: 175px !important;
}

#portfolio div.col-md-3 h2,
#portfolio div.col-md-3 h3 {
  font-size: 0.93333333rem;
  line-height: 0.8rem;
  font-variant: petite-caps;
  text-shadow: 2px 1px rgba(30, 40, 50, 0.1);
  letter-spacing: 0.10666667rem;
  word-spacing: 0.06666667rem;
}

