$color: #fa6700;

.server-price-wraper {
    .xs-title {
        font-size: rem(28px);
        color: $color-secondary;
        margin-bottom: 32px;
    }
}

.server-price-table {
    thead {
        tr {
            background-color: $color-secondary;
            color: $color-white;
            position: relative;
            background-image: url(../images/dedicated-server/1.png);
            background-repeat: no-repeat;
            th {
                border-bottom: 0px;
            }
        }
    }
    thead, tbody {
        tr {
            th , td {
                font-size: rem(13px);
                padding: 22px;
                font-weight: 400;
            }
        }
    }
    tbody {
        border: 1px solid #f7f7f7;
        td, th {
            border-right: 1px solid #f7f7f7;
            border-top: 0px;
            vertical-align: middle;
            color: $color-black;
        }
        .price {
            color: $color;
            del {
                color: #a3a3a3;
                padding-right: 6px;
            }
        }
        a.featured {
            color: $color;
            &.disabled {
                pointer-events: none;
                color: #9d9d9d;
            }
        }
    }
    &.table-striped {
        tbody {
            tr {
                &:nth-of-type(odd) {
                    background-color: #f7f7f7;
                }
            }
        }
    }
    .tag {
        color: $color-white;
        height:  18px;
        line-height: 18px;
        background-color: $color;
        padding: 0 5px;
        font-size: rem(11px);
        display: inline-block;
        margin-right: 15px;
        &.featured {
            background-color: $color-secondary;
        }
    }
}