@charset "UTF-8";
@import "variable/variable";
@import "mixing/mixins";
/* Table of contents */
/*
1. predefine
2. nav
3. banner
4. modal
5. modal search
6. modal flag
7. offset sidebar
8. offset cart
9. offset info bar
10. domain search
11. why choose us
12. pricing
13. service
14. call to action
15. feature
16. clients
17. hosting information
18. testimonial
19. blog
20. footer
21. pricing feature
22. included feature
23. inner banner
24. contact info
25. form filed
26. intro video
27. office
28. team
29. included feature list
30. domain price
31. blog list
32. side bar
33. post single
34. comments area
35. cart
36. cart check out
37. VPS Slider
38. Dedicated Server

========================== element list start ========================
1. Element
2. Buttons
3. Social list
4. Select
5. Section title
6. nav tab
7. unorder list
8. breadCumb
9. accordion
10. map
11. custom control
12. pagination
13. prelaoder
14. Order list
15. sticky nav
16. location map
17. shuffle letter
18. owl dots
19. menu highlight badge
20. inheritance

*/

/* var list */
@import 'variable/variable';
@import 'fonts.scss';

/* mixing */
@import 'mixing/mixing';

/* function */
@import 'function/function';

/*--------------------------------------------------============================================================
										1. predefine
=================================================--------------------------------------------------------------*/
@import 'reset/predefine';

/*--------------------------------------------------============================================================
										2. nav
=================================================--------------------------------------------------------------*/
@import 'component/nav';

/*--------------------------------------------------============================================================
										3. banner
=================================================--------------------------------------------------------------*/
@import 'component/banner';

/*--------------------------------------------------============================================================
										4. modal
=================================================--------------------------------------------------------------*/
@import 'component/modal';

/*--------------------------------------------------============================================================
										5. modal search
=================================================--------------------------------------------------------------*/
@import 'component/modal-search';

/*--------------------------------------------------============================================================
										6. modal flag
=================================================--------------------------------------------------------------*/
@import 'component/modal-flag';

/*--------------------------------------------------============================================================
										7. offset sidebar
=================================================--------------------------------------------------------------*/
@import 'component/offsetSidebar';

/*--------------------------------------------------============================================================
										8. offset cart
=================================================--------------------------------------------------------------*/
@import 'component/offsetCart';

/*--------------------------------------------------============================================================
										9. offset info bar
=================================================--------------------------------------------------------------*/
@import 'component/offsetInfoBar';

/*--------------------------------------------------============================================================
										10. domain search
=================================================--------------------------------------------------------------*/
@import 'component/domainSearch';

/*--------------------------------------------------============================================================
										11. why choose us
=================================================--------------------------------------------------------------*/
@import 'component/whyChooseUs';

/*--------------------------------------------------============================================================
										12. pricing
=================================================--------------------------------------------------------------*/
@import 'component/pricing';

/*--------------------------------------------------============================================================
										13. service
=================================================--------------------------------------------------------------*/
@import 'component/service';

/*--------------------------------------------------============================================================
										14. call to action
=================================================--------------------------------------------------------------*/
@import 'component/callToAction';

/*--------------------------------------------------============================================================
										15. feature
=================================================--------------------------------------------------------------*/
@import 'component/feature';

/*--------------------------------------------------============================================================
										16. clients
=================================================--------------------------------------------------------------*/
@import 'component/client';

/*--------------------------------------------------============================================================
										17. hosting information
=================================================--------------------------------------------------------------*/
@import 'component/hostingInfo';

/*--------------------------------------------------============================================================
										18. testimonial
=================================================--------------------------------------------------------------*/
@import 'component/testimonial';

/*--------------------------------------------------============================================================
										19. blog
=================================================--------------------------------------------------------------*/
//@import 'component/blog';

/*--------------------------------------------------============================================================
										20. footer
=================================================--------------------------------------------------------------*/
@import 'component/footer';

/*--------------------------------------------------============================================================
										21. pricing feature
=================================================--------------------------------------------------------------*/
@import 'component/pricingFeature';

/*--------------------------------------------------============================================================
										22. included feature
=================================================--------------------------------------------------------------*/
@import 'component/includedFeature';

/*--------------------------------------------------============================================================
										23. inner banner
=================================================--------------------------------------------------------------*/
@import 'component/innerBanner';

/*--------------------------------------------------============================================================
										24. contact info
=================================================--------------------------------------------------------------*/
@import 'component/contactInfo';

/*--------------------------------------------------============================================================
										25. form filed
=================================================--------------------------------------------------------------*/
@import 'component/form';

/*--------------------------------------------------============================================================
										26. intro video
=================================================--------------------------------------------------------------*/
@import 'component/introVideo';

/*--------------------------------------------------============================================================
										27. office
=================================================--------------------------------------------------------------*/
@import 'component/office';

/*--------------------------------------------------============================================================
										28. team
=================================================--------------------------------------------------------------*/
@import 'component/team';

/*--------------------------------------------------============================================================
										29. included feature list
=================================================--------------------------------------------------------------*/
@import 'component/includedFeature-list';

/*--------------------------------------------------============================================================
										30. domain price
=================================================--------------------------------------------------------------*/
@import 'component/_domainPrice';

/*--------------------------------------------------============================================================
										31. blog list
=================================================--------------------------------------------------------------*/
@import 'component/blogList';

/*--------------------------------------------------============================================================
										32. side bar
=================================================--------------------------------------------------------------*/
@import 'component/sidebar';

/*--------------------------------------------------============================================================
										33. post single
=================================================--------------------------------------------------------------*/
@import 'component/postSingle';

/*--------------------------------------------------============================================================
										34. comments area
=================================================--------------------------------------------------------------*/
@import 'component/_commentsArea';

/*--------------------------------------------------============================================================
										35. cart
=================================================--------------------------------------------------------------*/
@import 'component/_cart';

/*--------------------------------------------------============================================================
										36. cart check out
=================================================--------------------------------------------------------------*/
@import 'component/_checkout';

/*--------------------------------------------------============================================================
										37. VPS Slider
=================================================--------------------------------------------------------------*/
@import 'component/_vpsSlider.scss';

/*--------------------------------------------------============================================================
										38. Dedicated Server
=================================================--------------------------------------------------------------*/
@import 'component/_dedicatedServer.scss';

/*--------------------------------------------------============================================================
										39. Flags
=================================================--------------------------------------------------------------*/
@import 'component/_flags.scss';

/*--------------------------------------------------============================================================
										1. Element
=================================================--------------------------------------------------------------*/
@import 'element/element';

/*--------------------------------------------------============================================================
										2. Buttons
=================================================--------------------------------------------------------------*/
@import 'element/buttons';

/*--------------------------------------------------============================================================
										3. Social list
=================================================--------------------------------------------------------------*/
@import 'element/socialList';

/*--------------------------------------------------============================================================
										4. Select
=================================================--------------------------------------------------------------*/
@import 'element/select';

/*--------------------------------------------------============================================================
										5. Section title
=================================================--------------------------------------------------------------*/
@import 'element/sectionTitle';

/*--------------------------------------------------============================================================
										6. nav tab
=================================================--------------------------------------------------------------*/
@import 'element/tab';

/*--------------------------------------------------============================================================
										7. unorder list
=================================================--------------------------------------------------------------*/
@import 'element/list';

/*--------------------------------------------------============================================================
										8. breadCumb
=================================================--------------------------------------------------------------*/
@import 'element/breadCumb';

/*--------------------------------------------------============================================================
										9. accordion
=================================================--------------------------------------------------------------*/
@import 'element/accordion';

/*--------------------------------------------------============================================================
										10. map
=================================================--------------------------------------------------------------*/
@import 'element/map';

/*--------------------------------------------------============================================================
										11. custom control
=================================================--------------------------------------------------------------*/
@import 'element/customControl';

/*--------------------------------------------------============================================================
										12. pagination
=================================================--------------------------------------------------------------*/
@import 'element/pagination';

/*--------------------------------------------------============================================================
										13. prelaoder
=================================================--------------------------------------------------------------*/
@import 'element/preloader';

/*--------------------------------------------------============================================================
										14. Order list
=================================================--------------------------------------------------------------*/
@import './element/_orderList';

/*--------------------------------------------------============================================================
										15. sticky nav
=================================================--------------------------------------------------------------*/
@import 'element/_stickyNav';

/*--------------------------------------------------============================================================
										16. location map
=================================================--------------------------------------------------------------*/
@import 'element/_locationMap';

/*--------------------------------------------------============================================================
										17. shuffle letter
=================================================--------------------------------------------------------------*/
@import 'element/shuffleLetter';

/*--------------------------------------------------============================================================
										18. owl dots
=================================================--------------------------------------------------------------*/
@import 'element/_owlDots';

/*--------------------------------------------------============================================================
										19. menu highlight badge
=================================================--------------------------------------------------------------*/
@import 'element/_menuHighlightBadge';

/*--------------------------------------------------============================================================
										20. inheritance
=================================================--------------------------------------------------------------*/
@import 'reset/inheritance';
@import "component/responsive";
@import "custom";
