.modal-searchPanel {
	.xs-search-group {
		position: relative;
		input:not([type="submit"]) {
			height: 70px;
			background-color: transparent;
			border-radius: 50px;
			border: 2px solid $color-white;
			color: $color-white;
			padding: 0 30px;
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: $color-white;
			}
			&::-moz-placeholder { /* Firefox 19+ */
				color: $color-white;
			}
			&:-ms-input-placeholder { /* IE 10+ */
				color: $color-white;
			}
			&:-moz-placeholder { /* Firefox 18- */
				color: $color-white;
			}
		}
		.search-button {
			background-color: transparent;
			border: 0px;
			padding: 0;
			color: $color-white;
			position: absolute;
			right: 0;
			cursor: pointer;
			top: 50%;
			transform: translateY(-50%);
			height: 100%;
			width: 70px;
			border-radius: 0 50px 50px 0;
		}
	}
}