.xs-heading {
    margin-bottom: 75px;
    text-align: center;
    .heading-sub-title {
        color: #8c8c8c;
        font-size: rem(20px);
        font-weight: 400;
        font-family: $bodyFont;
    }
    .heading-title {
        font-size: rem(30px);
        font-weight: 700;
        span {
            color: $color-secondary;
        }
    }
    &.heading-v2 {
        .heading-title {
            font-weight: 500;
        }
    }
}

/* content title */
.content-title {
    font-size: rem(30px);
    font-weight: 700;
}
.content-title-wraper {
    margin-bottom: 40px;
    .content-title {
        margin-bottom: 0px;
    }
}
