@import 'variable/variable';

html, body {
    font-family: $bodyFont;
    font-weight: $bodyFontWeight;
    line-height: $blineHeight;
    color: $bColor;
    background-color: $color-white;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    text-shadow: rgba(247,252,255,0.2) 0 0.4px 0.4px;
}

.banner-icon {
    position: absolute;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: bounce 1s ease-in-out 0s infinite alternate;
    animation: bounce 1s ease-in-out 0s infinite alternate;
}
.banner-icon.banner-ico-1 {
    left: 24.5%;
    top: 6%;
}
.banner-icon.banner-ico-2 {
    left: inherit;
    right: 39.5%;
    top: 0px;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}
.inner-welcome-image-group .banner-icon.banner-ico-1 {
    left: 52.5%;
    top: 1%;
}
.inner-welcome-image-group .banner-icon.banner-ico-2 {
    right: 10%;
    top: 10%;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.inner-welcome-image-group.faq-icon .banner-icon.banner-ico-1 {
    left: 42.5%;
}
.inner-welcome-image-group.faq-icon .banner-icon.banner-ico-2 {
    right: 12.5%;
}
a{
    text-decoration:none;
    &:hover, &:focus{
        text-decoration:none;
    }
}
.row.m0{
    margin: 0px;
}
.p0{
    padding: 0px;
}
i{
    &:before{
        margin-left: 0px !important;
    }
}

h1, h2, h3, h4, h5, h6{

}
body{
    letter-spacing: 0px;
    padding: 0px;
    overflow-x: hidden;
    z-index: 0;
}
.wrapper{
    position: relative;
    z-index: 20;
    overflow-x: hidden;
}

a,.btn,button{
    text-decoration: none;
    outline: none;
    &:hover,&:focus{
        text-decoration: none;
        outline: none;
    }
}
.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus{
    outline: none;
    box-shadow: none;
}


.container{
    @media (min-width:1250px){
        max-width: 1200px;
    }
    @media (max-width:767px){
        max-width: 100%;
    }
    &.custom_container{
        max-width: 1520px;
    }
}

.pricing-matrix .pricing-matrix-slider .owl-nav {
    //display: none !important;
}
#btnScrollTo {
    border: 2px solid rgba(24, 112, 224, 0.3);
    box-shadow: 0 0 3px 1px rgba(80, 160, 248, 0.3) inset, 0 0 2px 0 rgba(75, 125, 225, 0.15);
    padding: 4px 0 6px 7px;
    width: 38px;
    display: block;
    margin: 0 8px 10px 0 !important;
    opacity: 0.75;
    text-align: center;
    transition-delay: 0.1s;
    transition-duration: 0.35s;
    font-weight: 700;
}
#btnScrollTo:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 4px 1px rgba(8, 24, 48, 0.4);
    opacity: 0.95;
    color: #ffffff;
}

.form-group label, label {
    font-size: 14.5px;
    text-transform: uppercase;
    font-family: $titleFont;
    color: rgb(59, 68, 79);
    margin-bottom: 7px;
    margin-top: 3px;
    font-weight: 600;
}
.form-group label .required {
    color: rgb(223, 59, 66);
}

header.xs-header .xs-menus .nav-menus-wrapper .nav-menu .media img.menu-icon {
    display: block;
    width: auto;
    height: auto;
    max-width: 42px;
    max-height: 40px;
}
header.xs-header .xs-menus .nav-menus-wrapper .nav-menu .media img.menu-icon.lg-icon {
     max-width: 50px;
     max-height: 48px;
 }

.hosting-info-wraper .seo-list.xs-list li,
.hosting-info-wraper .seo-list.xs-list li p {
    line-height: 1.54 !important;
    letter-spacing: -0.3px !important;
    word-spacing: -0.6px !important;
}
.owl-carousel-2 .owl-item {
    height: 175px !important;
    max-height: 175px !important;
    min-height: 175px !important;
}

#portfolio div.col-md-3 h2, #portfolio div.col-md-3 h3 {

    font-size: rem(14px);
    line-height: rem(12px);
    font-variant: petite-caps;
    text-shadow: 2px 1px rgba(30,40,50, 0.1);
    letter-spacing: rem(1.6px);
    word-spacing: rem(1px);
}
