.social-list {
    li {
        display: inline-block;
        margin-right: 10px;
        &:last-child {
            margin-right: 0px;
        }
        a {
            display: block;
            @include equal-size-lineHeight(42px);
            border-radius: 100%;
            border: 1px solid $color-light;
            text-align: center;
            font-size: rem(14px);
            color: $titleColor;
            &.facebook {
                color: $color-facebook;
                &:hover{
                    color: $color-white;
                    background: $color-facebook;
                }
            }
            &.twitter {
                color: $color-twitter;
                &:hover{
                    color: $color-white;
                    background: $color-twitter;
                }
            }
            &.linkedin {
                color: $color-linkedin;
                &:hover{
                    color: $color-white;
                    background: $color-linkedin;
                }
            }
            &.instagram {
                color: $color-instagram;
                &:hover{
                    color: $color-white;
                    background: $color-instagram;
                }
            }
            &.googlePlus {
                color: $color-google-plus;
                &:hover{
                    color: $color-white;
                    background: $color-google-plus;
                }
            }
            &.vimeo {
                color: $color-light;
                &:hover{
                    color: $color-white;
                    background: $color-light;
                }
            }
        }
    }
    &.version-2 {
        li {
            a {
                color: $color-white;
                font-size: rem(11.93px);
                @include equal-size-lineHeight(35px);
                border: 0px;
                &.facebook {
                    background-color: $color-facebook;
                }
                &.twitter {
                    background-color: $color-twitter;
                }
                &.linkedin {
                    background-color: $color-linkedin;
                }
                &.instagram {
                    background-color: $color-instagram;
                }
                &.googlePlus {
                    background-color: $color-google-plus;
                }
                &.vimeo {
                    background-color: #1ab7ea;
                }
            }
        }
    }
    &.version-3 {
        li {
            a {
                border: 1px solid rgba($color: $color-white, $alpha: .2);
                color: $color-white;
                @include transition;
                &:hover {
                    color: $color-white;
                    background-color: #2aa3ef;
                    border-color: #2aa3ef;
                }
            }
        }
    }
}
  
.simple-social-list {
    li {
        margin-right: 32px;
        &:last-child {
            margin-right: 0px;
        }
        a {
            color: $color-white;
            font-size: rem(18px);
        }
    }
}