@import '../variable/variable';

.nav-brand,
.nav-brand:focus,
.nav-brand:hover,
.nav-menu>li>a {
  color: #70798b
}
.xs_nav {
  width: 100%;
  height: 70px;
  display: table;
  position: relative;
  font-family: inherit;
  background-color: #fff
}
.xs_nav * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent
}
//.xs_nav-portrait {
//  height: 48px
//}
.xs_nav-fixed-wrapper {
  width: 100%;
  left: 0;
  z-index: 19998;
  will-change: opacity
}
.xs_nav-fixed-wrapper.fixed {
  position: fixed!important;
  -webkit-animation: fade .5s;
  animation: fade .5s
}
@keyframes fade {
  from {
    opacity: .999
  }
  to {
    opacity: 1
  }
}
.xs_nav-fixed-wrapper .xs_nav {
  margin-right: auto;
  margin-left: auto
}
.xs_nav-fixed-placeholder {
  width: 100%;
  display: none
}
.xs_nav-fixed-placeholder.xs_nav-fixed-placeholder.visible {
  display: block
}
.nav-toggle,
.xs_nav-hidden .nav-header {
  display: none
}
.xs_nav-hidden {
  width: 0!important;
  height: 0!important;
  margin: 0!important;
  padding: 0!important;
  position: absolute;
  top: -9999px
}
.align-to-right {
  float: right
}
.nav-header {
  float: left
}
.nav-brand {
  line-height: 70px;
  padding: 0 15px;
  font-size: 24px;
  text-decoration: none!important
}
.xs_nav-portrait .nav-brand {
  font-size: 18px;
  line-height: 48px
}
.nav-logo>img {
  height: 48px;
  margin: 11px auto;
  padding: 0 15px;
  float: left
}
.nav-logo:focus>img {
  outline: initial
}
.xs_nav-portrait .nav-logo>img {
  height: 36px;
  margin: 6px auto 6px 15px;
  padding: 0
}
.nav-toggle {
  width: 30px;
  height: 30px;
  padding: 6px 1px 0;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 18px;
  cursor: pointer
}
.nav-toggle:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 2px;
  background-color: #70798b;
  border-radius: 10px;
  box-shadow: 0 .5em 0 0 #70798b, 0 1em 0 0 #70798b
}
.xs_nav-portrait .nav-toggle {
  display: block
}
.xs_nav-portrait .nav-menus-wrapper {
  width: 320px;
  height: 100%;
  top: 0;
  left: -400px;
  position: fixed;
  background-color: #fff;
  z-index: 20000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition-duration: .8s;
  transition-duration: .8s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease
}
.xs_nav-portrait .nav-menus-wrapper.nav-menus-wrapper-right {
  left: auto;
  right: -400px
}
.xs_nav-portrait .nav-menus-wrapper.nav-menus-wrapper-open {
  left: 0;
  &::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 5px;
    left: 12px;
    max-height: 52px;
    height: 100%;
    width: calc(100% - 75px);
    background: url('../../images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.xs_nav-portrait .nav-menus-wrapper.nav-menus-wrapper-right.nav-menus-wrapper-open {
  left: auto;
  right: 0
}
.nav-menus-wrapper-close-button {
  width: 30px;
  height: 40px;
  margin: 10px 7px;
  display: none;
  float: right;
  color: #70798b;
  font-size: 26px;
  cursor: pointer
}
.xs_nav-portrait .nav-menus-wrapper-close-button {
  display: block
}
.nav-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: normal;
  font-size: 0
}
.xs_nav-portrait .nav-menu {
  width: 100%
}
.nav-menu.nav-menu-centered {
  text-align: center
}
.nav-menu.nav-menu-centered>li {
  float: none
}
.nav-menu>li {
  display: inline-block;
  float: none;
  text-align: left
}
.xs_nav-portrait .nav-menu>li {
  width: 100%;
  position: relative;
  border-top: solid 1px #f0f0f0
}
.xs_nav-portrait .nav-menu>li:last-child {
  border-bottom: solid 1px #f0f0f0
}
.nav-menu+.nav-menu>li:first-child {
  border-top: none
}
.nav-menu>li>a {
  height: 100%;
  padding: 26px 15px;
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 14px;
  -webkit-transition: color .3s, background .3s;
  transition: color .3s, background .3s
}
.xs_nav-portrait .nav-menu>li>a {
  width: 100%;
  height: auto;
  padding: 12px 15px 12px 26px
}
.nav-menu>li.active>a,
.nav-menu>li.focus>a,
.nav-menu>li:hover>a {
  color: #967ADC
}
.nav-menu>li>a>[class*=ion-],
.nav-menu>li>a>i {
  width: 18px;
  height: 16px;
  line-height: 16px;
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4)
}
.nav-menu>li>a>[class*=ion-] {
  width: 16px;
  display: inline-block;
  -webkit-transform: scale(1.8);
  -ms-transform: scale(1.8);
  transform: scale(1.8)
}
.xs_nav-portrait .nav-menu.nav-menu-social {
  width: 100%;
  text-align: center
}
.nav-menu.nav-menu-social>li {
  text-align: center;
  float: none;
  border: none!important
}
.xs_nav-portrait .nav-menu.nav-menu-social>li {
  width: auto
}
.nav-menu.nav-menu-social>li>a>[class*=ion-] {
  font-size: 12px
}
.nav-menu.nav-menu-social>li>a>.fa {
  font-size: 14px
}
.xs_nav-portrait .nav-menu.nav-menu-social>li>a {
  padding: 15px
}
.submenu-indicator {
  margin-left: 6px;
  margin-top: 6px;
  float: right;
  -webkit-transition: all .3s;
  transition: all .3s
}
.xs_nav-portrait .submenu-indicator {
  width: 54px;
  height: 44px;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  z-index: 20000
}
.submenu-indicator-chevron {
  height: 6px;
  width: 6px;
  display: block;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: transparent #70798b #70798b transparent;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: border .3s;
  transition: border .3s
}
.xs_nav-portrait .submenu-indicator-chevron {
  position: absolute;
  top: 18px;
  left: 24px
}
.nav-menu>.active>a .submenu-indicator-chevron,
.nav-menu>.focus>a .submenu-indicator-chevron,
.nav-menu>li:hover>a .submenu-indicator-chevron {
  border-color: transparent #967ADC #967ADC transparent
}
.xs_nav-portrait .submenu-indicator.submenu-indicator-up {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg)
}
.nav-overlay-panel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: none;
  z-index: 19999
}
.no-scroll {
  touch-action: none;
  overflow-x: hidden
}
.nav-search {
  height: 70px;
  float: right;
  z-index: 19998
}
.xs_nav-portrait .nav-search {
  height: 48px;
  padding: 0 10px;
  margin-right: 52px
}
.xs_nav-hidden .nav-search {
  display: none
}
.nav-search-button {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  background-color: #fbfcfd
}
.xs_nav-portrait .nav-search-button {
  width: 50px;
  height: 48px;
  line-height: 46px;
  font-size: 22px
}
.nav-search-icon {
  width: 14px;
  height: 14px;
  margin: 2px 8px 8px 4px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: #70798b;
  text-align: left;
  text-indent: -9999px;
  border: 2px solid;
  border-radius: 50%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: color .3s;
  transition: color .3s
}
.nav-search-icon:after,
.nav-search-icon:before {
  content: '';
  pointer-events: none
}
.nav-search-icon:before {
  width: 2px;
  height: 11px;
  top: 11px;
  position: absolute;
  left: 50%;
  border-radius: 0 0 1px 1px;
  box-shadow: inset 0 0 0 32px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}
.nav-search-button:hover .nav-search-icon {
  color: #967ADC
}
.nav-search>form {
  width: 100%;
  height: 100%;
  padding: 0 auto;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 99
}
.nav-search-inner {
  width: 70%;
  height: 70px;
  margin: auto;
  display: table
}
.xs_nav-portrait .nav-search-inner {
  height: 48px
}
.nav-search-inner input[type=search],
.nav-search-inner input[type=text] {
  height: 70px;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  font-size: 26px;
  text-align: center;
  color: #70798b;
  outline: 0;
  line-height: 70px;
  border: none;
  background-color: transparent;
  -webkit-transition: all .3s;
  transition: all .3s
}
.xs_nav-portrait .nav-search-inner input[type=search],
.xs_nav-portrait .nav-search-inner input[type=text] {
  height: 48px;
  font-size: 18px;
  line-height: 48px
}
.nav-search-close-button {
  width: 28px;
  height: 28px;
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: normal;
  color: #70798b;
  font-size: 20px;
  cursor: pointer;
  text-align: center
}
.nav-button,
.nav-text {
  display: inline-block;
  font-size: 14px
}
.xs_nav-portrait .nav-search-close-button {
  top: 10px;
  right: 14px
}
.nav-button {
  margin: 18px 15px 0;
  padding: 8px 14px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  background-color: #967ADC;
  -webkit-transition: opacity .3s;
  transition: opacity .3s
}
.nav-button:focus,
.nav-button:hover {
  color: #fff;
  text-decoration: none;
  opacity: .85
}
.xs_nav-portrait .nav-button {
  width: calc(100% - 52px);
  margin: 17px 26px
}
.nav-text {
  margin: 25px 15px;
  color: #70798b
}
.xs_nav-portrait .nav-text {
  width: calc(100% - 52px);
  margin: 12px 26px 0
}
.xs_nav-portrait .nav-text+ul {
  margin-top: 15px
}
.nav-dropdown {
  min-width: 200px;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  list-style: none;
  z-index: 98;
  white-space: nowrap
}
.xs_nav-portrait .nav-dropdown {
  width: 100%;
  position: static;
  left: 0
}
.nav-dropdown .nav-dropdown {
  left: 100%
}
.nav-menu>li>.nav-dropdown {
  border-top: solid 1px #f0f0f0
}
.nav-dropdown>li {
  width: 100%;
  float: left;
  clear: both;
  position: relative;
  text-align: left
}
.nav-dropdown>li>a {
  width: 100%;
  padding: 16px 20px;
  display: inline-block;
  text-decoration: none;
  float: left;
  font-size: 13px;
  color: #70798b;
  background-color: #fdfdfd;
  -webkit-transition: color .3s, background .3s;
  transition: color .3s, background .3s
}
.nav-dropdown>li.focus>a,
.nav-dropdown>li:hover>a {
  color: #967ADC
}
.nav-dropdown.nav-dropdown-left {
  right: 0
}
.nav-dropdown>li>.nav-dropdown-left {
  left: auto;
  right: 100%
}
.xs_nav-landscape .nav-dropdown.nav-dropdown-left>li>a {
  text-align: right
}
.xs_nav-portrait .nav-dropdown>li>a {
  padding: 12px 20px 12px 30px
}
.xs_nav-portrait .nav-dropdown>li>ul>li>a {
  padding-left: 50px
}
.xs_nav-portrait .nav-dropdown>li>ul>li>ul>li>a {
  padding-left: 70px
}
.xs_nav-portrait .nav-dropdown>li>ul>li>ul>li>ul>li>a {
  padding-left: 90px
}
.xs_nav-portrait .nav-dropdown>li>ul>li>ul>li>ul>li>ul>li>a {
  padding-left: 110px
}
.nav-dropdown .submenu-indicator {
  right: 15px;
  top: 16px;
  position: absolute
}
.xs_nav-portrait .nav-dropdown .submenu-indicator {
  right: 0;
  top: 0
}
.nav-dropdown .submenu-indicator .submenu-indicator-chevron {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}
.xs_nav-portrait .nav-dropdown .submenu-indicator .submenu-indicator-chevron {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}
.nav-dropdown>.focus>a .submenu-indicator-chevron,
.nav-dropdown>li:hover>a .submenu-indicator-chevron {
  border-color: transparent #967ADC #967ADC transparent
}
.xs_nav-landscape .nav-dropdown.nav-dropdown-left .submenu-indicator {
  left: 10px
}
.xs_nav-landscape .nav-dropdown.nav-dropdown-left .submenu-indicator .submenu-indicator-chevron {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg)
}
.nav-dropdown-horizontal {
  width: 100%;
  left: 0;
  background-color: #fdfdfd;
  border-top: solid 1px #f0f0f0
}
.nav-dropdown-horizontal .nav-dropdown-horizontal {
  width: 100%;
  top: 100%;
  left: 0
}
.xs_nav-portrait .nav-dropdown-horizontal .nav-dropdown-horizontal {
  border-top: none
}
.nav-dropdown-horizontal>li {
  width: auto;
  clear: none;
  position: static
}
.megamenu-panel [class*=container],
.xs_nav-portrait .nav-dropdown-horizontal>li {
  width: 100%
}
.nav-dropdown-horizontal>li>a {
  position: relative
}
.nav-dropdown-horizontal .submenu-indicator {
  height: 18px;
  top: 11px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg)
}
.xs_nav-portrait .nav-dropdown-horizontal .submenu-indicator {
  height: 42px;
  top: 0;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0)
}
.xs_nav-portrait .nav-dropdown-horizontal .submenu-indicator.submenu-indicator-up {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg)
}
.megamenu-panel {
  width: 100%;
  padding: 15px;
  display: none;
  position: absolute;
  font-size: 14px;
  z-index: 98;
  text-align: left;
  color: inherit;
  border-top: solid 1px #f0f0f0;
  background-color: #fdfdfd
}
.megamenu-tabs-nav>li>a,
.megamenu-tabs-pane {
  border: 1px solid #eff0f2;
  color: #70798b;
  font-size: 13px
}
.xs_nav-portrait .megamenu-panel {
  padding: 25px;
  position: static;
  display: block
}
.megamenu-panel [class*=container] [class*=col-] {
  padding: 0
}
.megamenu-panel-half {
  width: 50%
}
.megamenu-panel-quarter {
  width: 25%
}
.megamenu-panel-row,
.xs_nav-portrait .megamenu-panel-half,
.xs_nav-portrait .megamenu-panel-quarter {
  width: 100%
}
.megamenu-panel-row:after,
.megamenu-panel-row:before {
  display: table;
  content: "";
  line-height: 0
}
.megamenu-panel-row:after {
  clear: both
}
.megamenu-panel-row [class*=col-] {
  display: block;
  min-height: 20px;
  float: left;
  margin-left: 3%
}
.megamenu-panel-row [class*=col-]:first-child {
  margin-left: 0
}
.xs_nav-portrait .megamenu-panel-row [class*=col-] {
  float: none;
  display: block;
  width: 100%!important;
  margin-left: 0;
  margin-top: 15px
}
.xs_nav-portrait .megamenu-panel-row:first-child [class*=col-]:first-child {
  margin-top: 0
}
.megamenu-panel-row .col-1 {
  width: 5.583333333333%
}
.megamenu-panel-row .col-2 {
  width: 14.166666666666%
}
.megamenu-panel-row .col-3 {
  width: 22.75%
}
.megamenu-panel-row .col-4 {
  width: 31.333333333333%
}
.megamenu-panel-row .col-5 {
  width: 39.916666666667%
}
.megamenu-panel-row .col-6 {
  width: 48.5%
}
.megamenu-panel-row .col-7 {
  width: 57.083333333333%
}
.megamenu-panel-row .col-8 {
  width: 65.666666666667%
}
.megamenu-panel-row .col-9 {
  width: 74.25%
}
.megamenu-panel-row .col-10 {
  width: 82.833333333334%
}
.megamenu-panel-row .col-11 {
  width: 91.416666666667%
}
.megamenu-panel-row .col-12 {
  width: 100%
}
.megamenu-tabs {
  width: 100%;
  float: left;
  display: block
}
.megamenu-tabs-nav {
  width: 20%;
  margin: 0;
  padding: 0;
  float: left;
  list-style: none
}
.xs_nav-portrait .megamenu-tabs-nav {
  width: 100%
}
.megamenu-tabs-nav>li>a {
  width: 100%;
  padding: 10px 16px;
  float: left;
  text-decoration: none;
  outline: 0;
  background-color: #fff;
  -webkit-transition: background .3s;
  transition: background .3s
}
.megamenu-tabs-nav>li.active a,
.megamenu-tabs-nav>li:hover a {
  background-color: #f5f5f5
}
.megamenu-tabs-pane {
  width: 80%;
  min-height: 30px;
  padding: 20px;
  float: right;
  display: none;
  opacity: 0;
  background-color: #fff;
  -webkit-transition: opacity .5s;
  transition: opacity .5s
}
.megamenu-tabs-pane.active {
  display: block;
  opacity: 1
}
.xs_nav-portrait .megamenu-tabs-pane {
  width: 100%
}
.megamenu-lists {
  width: 100%;
  display: table
}
.megamenu-list {
  width: 100%;
  margin: 0 0 15px;
  padding: 0;
  display: inline-block;
  float: left;
  list-style: none
}
.megamenu-list:last-child {
  margin: 0;
  border: none
}
.xs_nav-landscape .megamenu-list {
  margin: -15px 0;
  padding: 20px 0;
  border-right: solid 1px #f0f0f0
}
.xs_nav-landscape .megamenu-list:last-child {
  border: none
}
.megamenu-list>li>a {
  width: 100%;
  padding: 10px 15px;
  display: inline-block;
  color: #70798b;
  text-decoration: none;
  font-size: 13px;
  -webkit-transition: all .3s;
  transition: all .3s
}
.megamenu-list>li>a:hover {
  color: #fff;
  background-color: #967ADC
}
.megamenu-list>li.megamenu-list-title>a {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: .8;
  color: #70798b
}
.megamenu-list>li.megamenu-list-title>a:hover {
  opacity: 1;
  background-color: transparent
}
.xs_nav-landscape .list-col-2 {
  width: 50%
}
.xs_nav-landscape .list-col-3 {
  width: 33%
}
.xs_nav-landscape .list-col-4 {
  width: 25%
}
