.nav-sticky {
    transition: all .6s cubic-bezier(0.65, 0.05, 0.36, 1);
    background-color: rgba(0, 0, 0, 0.1);
    &.sticky-header {
        position: fixed;
        transform: translateY(-150%);
        background: #0f0466;
        background: linear-gradient(-45deg, #1045db 0%, #4426cf 6%, #33139d 20%, #25097e 48%, #15095e 92%, #0A0046 99%);
        background-color: linear-gradient(-45deg, #1045db 0%, #4426cf 6%, #33139d 20%, #25097e 48%, #15095e 92%, #0A0046 99%);
        width: 100%;
        z-index: 100;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .1);
        .xs-logo {
            padding: 0;
            img {
                max-width: 215px;
            }
        }
        .xs-menu-tools {
            padding: 26px 0;
            >li {
                >a {
                    color: $color-white;
                }
            }
        }
        .xs-menus {
            .nav-menu {
                >li {
                    >a {
                        padding: 30px 0px;
                        color: $color-white;
                        .submenu-indicator-chevron {
                            border-color: transparent $color-white $color-white transparent;
                        }
                    }
                }
            }
        }
        &.sticky {
            transform: translateY(0%);
        }
        &.nav-light {
            background-color: $color-white;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .1);
        }
        .xs-top-bar {
            transform: translateY(-100%);
            margin-top: -60px;
        }
        .header-boxed {
            >.container {
                &::before {
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }
        .sticky-logo {
            display: block;
        }
        .main-logo {
            display: none;
        }
    }
    .xs-top-bar {
        transform: translateY(0%);
        margin-top: 0px;
        transition: all .6s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    .nav-menu {
        >li {
            >a {
                transition: all .6s cubic-bezier(0.65, 0.05, 0.36, 1);
            }
        }
    }
    .xs-logo {
        transition: all .6s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    .xs-menu-tools {
        >li {
            >a {
                transition: all .6s cubic-bezier(0.65, 0.05, 0.36, 1);
            }
        }
    }
}

.nav-sticky {
    .xs-menus {
        .megamenu-panel {
            &:not(.xs-icon-menu):not(.xs-service-menu) {
                overflow-y: scroll;
                height: 500px;
            }
        }
    }
}

.sticky-logo {
    display: none;
}
