.xs-accordion {
    > .card {
        border: 0;
        background-color: $color-white;
        margin-bottom: 20px;
        @include transition;
        border-radius: 0px;
        &:last-child {
            margin-bottom: 0px;
        }
        > .card-header {
            border-bottom: 0;
            background-color: transparent;
            border-radius: 0px;
            padding: 0;
            > a {
                display: block;
                color: #292929;
                font-family: $titleFont;
                background-color: #f8fafe;
                font-weight: 500;
                padding: 19px 0;
                position: relative;
                @include transition;
                > i {
                    position: absolute;
                    right: 30px;
                    top: 25px;
                    transform: rotateX(0deg);
                    @include transition;
                    font-size: rem(12px);
                    transform-origin: center center;
                    color: #000000;
                }
                &[aria-expanded="true"] {
                    background-color: transparent;
                    > i {
                        transform: rotateX(-180deg);
                    }
                }
            }
        }
        &.isActive {
            box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.06);
            > .card-header {
                > a {
                    color: $color-secondary;
                }
            }
        }
    }
    .card-body {
        padding-top: 0px;
        padding-bottom: 30px;
        p {
            margin-bottom: 0px;
        }
    }
    > .card > .card-header > a,
    .card-body {
        padding-left: 30px;
        padding-right: 30px;
    }
    > .small {
        font-weight: 500;
        margin-bottom: 35px;
    }
    margin-bottom: 75px;
    &:last-child {
        margin-bottom: 0px;
    }
}

.xs-bg-gray {
    .xs-accordion > .card > .card-header > a {
        background-color: $color-white;
    }
}