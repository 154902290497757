@font-face {
    font-family: 'iconfont';
    src:  url('../fonts/icomoon.eot?uaoskn');
    src:  url('../fonts/icomoon.eot?uaoskn#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?uaoskn') format('truetype'),
    url('../fonts/icomoon.woff?uaoskn') format('woff'),
    url('../fonts/icomoon.svg?uaoskn#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iconfont' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
    content: "\e800";
}
.icon-apartment:before {
    content: "\e801";
}
.icon-pencil:before {
    content: "\e802";
}
.icon-magic-wand:before {
    content: "\e803";
}
.icon-drop:before {
    content: "\e804";
}
.icon-lighter:before {
    content: "\e805";
}
.icon-poop:before {
    content: "\e806";
}
.icon-sun:before {
    content: "\e807";
}
.icon-moon:before {
    content: "\e808";
}
.icon-cloud1:before {
    content: "\e809";
}
.icon-cloud-upload:before {
    content: "\e80a";
}
.icon-cloud-download:before {
    content: "\e80b";
}
.icon-cloud-sync:before {
    content: "\e80c";
}
.icon-cloud-check:before {
    content: "\e80d";
}
.icon-database:before {
    content: "\e80e";
}
.icon-lock:before {
    content: "\e80f";
}
.icon-cog:before {
    content: "\e810";
}
.icon-trash:before {
    content: "\e811";
}
.icon-dice:before {
    content: "\e812";
}
.icon-heart:before {
    content: "\e813";
}
.icon-star:before {
    content: "\e814";
}
.icon-star-half:before {
    content: "\e815";
}
.icon-star-empty:before {
    content: "\e816";
}
.icon-flag:before {
    content: "\e817";
}
.icon-envelope:before {
    content: "\e818";
}
.icon-paperclip:before {
    content: "\e819";
}
.icon-inbox:before {
    content: "\e81a";
}
.icon-eye:before {
    content: "\e81b";
}
.icon-printer:before {
    content: "\e81c";
}
.icon-file-empty:before {
    content: "\e81d";
}
.icon-file-add:before {
    content: "\e81e";
}
.icon-enter:before {
    content: "\e81f";
}
.icon-exit:before {
    content: "\e820";
}
.icon-graduation-hat:before {
    content: "\e821";
}
.icon-license:before {
    content: "\e822";
}
.icon-music-note:before {
    content: "\e823";
}
.icon-film-play:before {
    content: "\e824";
}
.icon-camera-video:before {
    content: "\e825";
}
.icon-camera:before {
    content: "\e826";
}
.icon-picture:before {
    content: "\e827";
}
.icon-book:before {
    content: "\e828";
}
.icon-bookmark:before {
    content: "\e829";
}
.icon-user:before {
    content: "\e82a";
}
.icon-users:before {
    content: "\e82b";
}
.icon-shirt:before {
    content: "\e82c";
}
.icon-store:before {
    content: "\e82d";
}
.icon-cart:before {
    content: "\e82e";
}
.icon-tag:before {
    content: "\e82f";
}
.icon-phone-handset:before {
    content: "\e830";
}
.icon-phone:before {
    content: "\e831";
}
.icon-pushpin:before {
    content: "\e832";
}
.icon-map-marker:before {
    content: "\e833";
}
.icon-map:before {
    content: "\e834";
}
.icon-location:before {
    content: "\e835";
}
.icon-calendar-full:before {
    content: "\e836";
}
.icon-keyboard:before {
    content: "\e837";
}
.icon-spell-check:before {
    content: "\e838";
}
.icon-screen:before {
    content: "\e839";
}
.icon-smartphone:before {
    content: "\e83a";
}
.icon-tablet:before {
    content: "\e83b";
}
.icon-laptop:before {
    content: "\e83c";
}
.icon-laptop-phone:before {
    content: "\e83d";
}
.icon-power-switch:before {
    content: "\e83e";
}
.icon-bubble:before {
    content: "\e83f";
}
.icon-heart-pulse:before {
    content: "\e840";
}
.icon-construction:before {
    content: "\e841";
}
.icon-pie-chart:before {
    content: "\e842";
}
.icon-chart-bars:before {
    content: "\e843";
}
.icon-gift:before {
    content: "\e844";
}
.icon-diamond:before {
    content: "\e845";
}
.icon-dinner:before {
    content: "\e847";
}
.icon-coffee-cup:before {
    content: "\e848";
}
.icon-leaf:before {
    content: "\e849";
}
.icon-paw:before {
    content: "\e84a";
}
.icon-rocket:before {
    content: "\e84b";
}
.icon-briefcase:before {
    content: "\e84c";
}
.icon-bus:before {
    content: "\e84d";
}
.icon-car:before {
    content: "\e84e";
}
.icon-train:before {
    content: "\e84f";
}
.icon-bicycle:before {
    content: "\e850";
}
.icon-wheelchair:before {
    content: "\e851";
}
.icon-select:before {
    content: "\e852";
}
.icon-earth:before {
    content: "\e853";
}
.icon-smile:before {
    content: "\e854";
}
.icon-sad:before {
    content: "\e855";
}
.icon-neutral:before {
    content: "\e856";
}
.icon-mustache:before {
    content: "\e857";
}
.icon-alarm:before {
    content: "\e858";
}
.icon-bullhorn:before {
    content: "\e859";
}
.icon-volume-high:before {
    content: "\e85a";
}
.icon-volume-medium:before {
    content: "\e85b";
}
.icon-volume-low:before {
    content: "\e85c";
}
.icon-volume:before {
    content: "\e85d";
}
.icon-mic:before {
    content: "\e85e";
}
.icon-hourglass:before {
    content: "\e85f";
}
.icon-undo:before {
    content: "\e860";
}
.icon-redo:before {
    content: "\e861";
}
.icon-sync:before {
    content: "\e862";
}
.icon-history:before {
    content: "\e863";
}
.icon-clock:before {
    content: "\e864";
}
.icon-download:before {
    content: "\e865";
}
.icon-upload:before {
    content: "\e866";
}
.icon-enter-down:before {
    content: "\e867";
}
.icon-exit-up:before {
    content: "\e868";
}
.icon-bug:before {
    content: "\e869";
}
.icon-code:before {
    content: "\e86a";
}
.icon-link:before {
    content: "\e86b";
}
.icon-unlink:before {
    content: "\e86c";
}
.icon-thumbs-up:before {
    content: "\e86d";
}
.icon-thumbs-down:before {
    content: "\e86e";
}
.icon-magnifier:before {
    content: "\e86f";
}
.icon-cross:before {
    content: "\e870";
}
.icon-menu:before {
    content: "\e871";
}
.icon-list:before {
    content: "\e872";
}
.icon-chevron-up:before {
    content: "\e873";
}
.icon-chevron-down:before {
    content: "\e874";
}
.icon-chevron-left:before {
    content: "\e875";
}
.icon-chevron-right:before {
    content: "\e876";
}
.icon-arrow-up:before {
    content: "\e877";
}
.icon-arrow-down:before {
    content: "\e878";
}
.icon-arrow-left:before {
    content: "\e879";
}
.icon-arrow-right:before {
    content: "\e87a";
}
.icon-move:before {
    content: "\e87b";
}
.icon-warning:before {
    content: "\e87c";
}
.icon-question-circle:before {
    content: "\e87d";
}
.icon-menu-circle:before {
    content: "\e87e";
}
.icon-checkmark-circle:before {
    content: "\e87f";
}
.icon-cross-circle:before {
    content: "\e880";
}
.icon-plus-circle:before {
    content: "\e881";
}
.icon-circle-minus:before {
    content: "\e882";
}
.icon-arrow-up-circle:before {
    content: "\e883";
}
.icon-arrow-down-circle:before {
    content: "\e884";
}
.icon-arrow-left-circle:before {
    content: "\e885";
}
.icon-arrow-right-circle:before {
    content: "\e886";
}
.icon-chevron-up-circle:before {
    content: "\e887";
}
.icon-chevron-down-circle:before {
    content: "\e888";
}
.icon-chevron-left-circle:before {
    content: "\e889";
}
.icon-chevron-right-circle:before {
    content: "\e88a";
}
.icon-crop:before {
    content: "\e88b";
}
.icon-frame-expand:before {
    content: "\e88c";
}
.icon-frame-contract:before {
    content: "\e88d";
}
.icon-layers:before {
    content: "\e88e";
}
.icon-funnel:before {
    content: "\e88f";
}
.icon-text-format:before {
    content: "\e890";
}
.icon-text-size:before {
    content: "\e892";
}
.icon-bold:before {
    content: "\e893";
}
.icon-italic:before {
    content: "\e894";
}
.icon-underline:before {
    content: "\e895";
}
.icon-strikethrough:before {
    content: "\e896";
}
.icon-highlight:before {
    content: "\e897";
}
.icon-text-align-left:before {
    content: "\e898";
}
.icon-text-align-center:before {
    content: "\e899";
}
.icon-text-align-right:before {
    content: "\e89a";
}
.icon-text-align-justify:before {
    content: "\e89b";
}
.icon-line-spacing:before {
    content: "\e89c";
}
.icon-indent-increase:before {
    content: "\e89d";
}
.icon-indent-decrease:before {
    content: "\e89e";
}
.icon-page-break:before {
    content: "\e8a2";
}
.icon-hand:before {
    content: "\e8a5";
}
.icon-pointer-up:before {
    content: "\e8a6";
}
.icon-pointer-right:before {
    content: "\e8a7";
}
.icon-pointer-down:before {
    content: "\e8a8";
}
.icon-pointer-left:before {
    content: "\e8a9";
}
.icon-vplay:before {
    content: "\e900";
}
.icon-newsletter:before {
    content: "\e901";
}
.icon-coins-2:before {
    content: "\e902";
}
.icon-commerce-2:before {
    content: "\e903";
}
.icon-monitor:before {
    content: "\e904";
}
.icon-facebook:before {
    content: "\e905";
}
.icon-business:before {
    content: "\e906";
}
.icon-graphic-2:before {
    content: "\e907";
}
.icon-commerce-1:before {
    content: "\e908";
}
.icon-hammer:before {
    content: "\e909";
}
.icon-twitter:before {
    content: "\e90a";
}
.icon-dribbble:before {
    content: "\e90b";
}
.icon-justice-1:before {
    content: "\e90c";
}
.icon-line:before {
    content: "\e90d";
}
.icon-money-3:before {
    content: "\e90e";
}
.icon-linkedin:before {
    content: "\e90f";
}
.icon-commerce:before {
    content: "\e910";
}
.icon-agenda:before {
    content: "\e911";
}
.icon-youtube:before {
    content: "\e912";
}
.icon-justice:before {
    content: "\e913";
}
.icon-twitter1:before {
    content: "\e914";
}
.icon-pinterest:before {
    content: "\e915";
}
.icon-technology:before {
    content: "\e916";
}
.icon-coins-1:before {
    content: "\e917";
}
.icon-vimeo:before {
    content: "\e918";
}
.icon-left-arrow:before {
    content: "\e919";
}
.icon-bank:before {
    content: "\e91a";
}
.icon-calculator:before {
    content: "\e91b";
}
.icon-soundcloud:before {
    content: "\e91c";
}
.icon-chart2:before {
    content: "\e91d";
}
.icon-checked:before {
    content: "\e91e";
}
.icon-clock1:before {
    content: "\e91f";
}
.icon-comment:before {
    content: "\e920";
}
.icon-comments:before {
    content: "\e921";
}
.icon-consult:before {
    content: "\e922";
}
.icon-consut2:before {
    content: "\e923";
}
.icon-deal:before {
    content: "\e924";
}
.icon-youtube-v:before {
    content: "\e925";
}
.icon-envelope1:before {
    content: "\e926";
}
.icon-behance:before {
    content: "\e927";
}
.icon-folder:before {
    content: "\e928";
}
.icon-invest:before {
    content: "\e929";
}
.icon-loan:before {
    content: "\e92a";
}
.icon-map-marker1:before {
    content: "\e92b";
}
.icon-mutual-fund:before {
    content: "\e92c";
}
.icon-google-plus:before {
    content: "\e92d";
}
.icon-phone1:before {
    content: "\e92e";
}
.icon-pie-chart1:before {
    content: "\e92f";
}
.icon-play:before {
    content: "\e930";
}
.icon-left-arrow2:before {
    content: "\e931";
}
.icon-savings:before {
    content: "\e932";
}
.icon-search:before {
    content: "\e933";
}
.icon-tag1:before {
    content: "\e934";
}
.icon-tags:before {
    content: "\e935";
}
.icon-instagram:before {
    content: "\e936";
}
.icon-quote:before {
    content: "\e937";
}
.icon-right-arrow2:before {
    content: "\e938";
}
.icon-double-left-chevron:before {
    content: "\e939";
}
.icon-double-angle-pointing-to-right:before {
    content: "\e93a";
}
.icon-arrow-point-to-down:before {
    content: "\e93b";
}
.icon-play-button:before {
    content: "\e93c";
}
.icon-minus:before {
    content: "\e93d";
}
.icon-plus:before {
    content: "\e93e";
}
.icon-tick:before {
    content: "\e93f";
}
.icon-download-arrow:before {
    content: "\e940";
}
.icon-edit:before {
    content: "\e941";
}
.icon-reply:before {
    content: "\e942";
}
.icon-cogwheel-outline:before {
    content: "\e943";
}
.icon-abacus:before {
    content: "\e944";
}
.icon-abacus1:before {
    content: "\e945";
}
.icon-agenda1:before {
    content: "\e946";
}
.icon-shopping-basket:before {
    content: "\e947";
}
.icon-users1:before {
    content: "\e948";
}
.icon-man:before {
    content: "\e949";
}
.icon-support1:before {
    content: "\e94a";
}
.icon-favorites:before {
    content: "\e94b";
}
.icon-calendar:before {
    content: "\e94c";
}
.icon-paper-plane:before {
    content: "\e94d";
}
.icon-placeholder:before {
    content: "\e94e";
}
.icon-phone-call:before {
    content: "\e94f";
}
.icon-contact:before {
    content: "\e950";
}
.icon-email1:before {
    content: "\e951";
}
.icon-internet:before {
    content: "\e952";
}
.icon-quote1:before {
    content: "\e953";
}
.icon-medical:before {
    content: "\e954";
}
.icon-eye1:before {
    content: "\e955";
}
.icon-full-screen:before {
    content: "\e956";
}
.icon-tools:before {
    content: "\e957";
}
.icon-pie-chart2:before {
    content: "\e958";
}
.icon-diamond1:before {
    content: "\e959";
}
.icon-valentines-heart:before {
    content: "\e95a";
}
.icon-like:before {
    content: "\e95b";
}
.icon-team:before {
    content: "\e95c";
}
.icon-tshirt:before {
    content: "\e95d";
}
.icon-cancel:before {
    content: "\e95e";
}
.icon-drink:before {
    content: "\e95f";
}
.icon-home1:before {
    content: "\e960";
}
.icon-music:before {
    content: "\e961";
}
.icon-rich:before {
    content: "\e962";
}
.icon-brush:before {
    content: "\e963";
}
.icon-opposite-way:before {
    content: "\e964";
}
.icon-cloud-computing:before {
    content: "\e965";
}
.icon-technology-1:before {
    content: "\e966";
}
.icon-rotate:before {
    content: "\e967";
}
.icon-medical1:before {
    content: "\e968";
}
.icon-flash-1:before {
    content: "\e969";
}
.icon-flash:before {
    content: "\e96a";
}
.icon-uturn:before {
    content: "\e96b";
}
.icon-down-arrow:before {
    content: "\e96c";
}
.icon-hours-support:before {
    content: "\e96d";
}
.icon-bag:before {
    content: "\e96e";
}
.icon-photo-camera:before {
    content: "\e96f";
}
.icon-school:before {
    content: "\e970";
}
.icon-settings:before {
    content: "\e971";
}
.icon-smartphone1:before {
    content: "\e972";
}
.icon-technology-11:before {
    content: "\e973";
}
.icon-tool:before {
    content: "\e974";
}
.icon-business1:before {
    content: "\e975";
}
.icon-shuffle-arrow:before {
    content: "\e976";
}
.icon-van-1:before {
    content: "\e977";
}
.icon-van:before {
    content: "\e978";
}
.icon-vegetables:before {
    content: "\e979";
}
.icon-women:before {
    content: "\e97a";
}
.icon-vintage:before {
    content: "\e97b";
}
.icon-up-arrow:before {
    content: "\e97c";
}
.icon-arrows:before {
    content: "\e97d";
}
.icon-medal:before {
    content: "\e97e";
}
.icon-team-1:before {
    content: "\e97f";
}
.icon-team1:before {
    content: "\e980";
}
.icon-apple-1:before {
    content: "\e981";
}
.icon-apple:before {
    content: "\e982";
}
.icon-watch:before {
    content: "\e983";
}
.icon-cogwheel:before {
    content: "\e984";
}
.icon-light-bulb:before {
    content: "\e985";
}
.icon-light-bulb-1:before {
    content: "\e986";
}
.icon-heart-shape-outline:before {
    content: "\e987";
}
.icon-online-shopping-cart:before {
    content: "\e988";
}
.icon-shopping-cart:before {
    content: "\e989";
}
.icon-star1:before {
    content: "\e98a";
}
.icon-star-1:before {
    content: "\e98b";
}
.icon-favorite:before {
    content: "\e98c";
}
.icon-search-minus:before {
    content: "\e98d";
}
.icon-search1:before {
    content: "\e98e";
}
.icon-down-arrow1:before {
    content: "\e98f";
}
.icon-up-arrow1:before {
    content: "\e990";
}
.icon-right-arrow:before {
    content: "\e991";
}
.icon-left-arrows:before {
    content: "\e992";
}
.icon-agenda2:before {
    content: "\e993";
}
.icon-agenda-1:before {
    content: "\e994";
}
.icon-alarm-clock:before {
    content: "\e995";
}
.icon-alarm-clock1:before {
    content: "\e996";
}
.icon-atomic:before {
    content: "\e997";
}
.icon-auction:before {
    content: "\e998";
}
.icon-balance:before {
    content: "\e999";
}
.icon-balance1:before {
    content: "\e99a";
}
.icon-bank1:before {
    content: "\e99b";
}
.icon-bar-chart:before {
    content: "\e99c";
}
.icon-barrier:before {
    content: "\e99d";
}
.icon-battery:before {
    content: "\e99e";
}
.icon-battery-1:before {
    content: "\e99f";
}
.icon-bell:before {
    content: "\e9a0";
}
.icon-bluetooth:before {
    content: "\e9a1";
}
.icon-book1:before {
    content: "\e9a2";
}
.icon-briefcase1:before {
    content: "\e9a3";
}
.icon-briefcase-1:before {
    content: "\e9a4";
}
.icon-briefcase-2:before {
    content: "\e9a5";
}
.icon-calculator1:before {
    content: "\e9a6";
}
.icon-calculator2:before {
    content: "\e9a7";
}
.icon-calculator-1:before {
    content: "\e9a8";
}
.icon-calendar1:before {
    content: "\e9a9";
}
.icon-calendar2:before {
    content: "\e9aa";
}
.icon-calendar-1:before {
    content: "\e9ab";
}
.icon-car1:before {
    content: "\e9ac";
}
.icon-carrier:before {
    content: "\e9ad";
}
.icon-cash:before {
    content: "\e9ae";
}
.icon-chat:before {
    content: "\e9af";
}
.icon-chat-1:before {
    content: "\e9b0";
}
.icon-checked1:before {
    content: "\e9b1";
}
.icon-clip:before {
    content: "\e9b2";
}
.icon-clip1:before {
    content: "\e9b3";
}
.icon-clipboard:before {
    content: "\e9b4";
}
.icon-clipboard1:before {
    content: "\e9b5";
}
.icon-clock2:before {
    content: "\e9b6";
}
.icon-clock-1:before {
    content: "\e9b7";
}
.icon-cloud2:before {
    content: "\e9b8";
}
.icon-cloud-computing1:before {
    content: "\e9b9";
}
.icon-cloud-computing-1:before {
    content: "\e9ba";
}
.icon-cogwheel1:before {
    content: "\e9bb";
}
.icon-coins:before {
    content: "\e9bc";
}
.icon-compass:before {
    content: "\e9bd";
}
.icon-contract:before {
    content: "\e9be";
}
.icon-conversation:before {
    content: "\e9bf";
}
.icon-crane:before {
    content: "\e9c0";
}
.icon-crane-2:before {
    content: "\e9c1";
}
.icon-credit-card:before {
    content: "\e9c2";
}
.icon-credit-card1:before {
    content: "\e9c3";
}
.icon-cursor:before {
    content: "\e9c4";
}
.icon-customer-service:before {
    content: "\e9c5";
}
.icon-cutlery:before {
    content: "\e9c6";
}
.icon-dart-board:before {
    content: "\e9c7";
}
.icon-decision-making:before {
    content: "\e9c8";
}
.icon-desk-chair:before {
    content: "\e9c9";
}
.icon-desk-lamp:before {
    content: "\e9ca";
}
.icon-diamond2:before {
    content: "\e9cb";
}
.icon-direction:before {
    content: "\e9cc";
}
.icon-document:before {
    content: "\e9cd";
}
.icon-dollar-bill:before {
    content: "\e9ce";
}
.icon-download1:before {
    content: "\e9cf";
}
.icon-edit1:before {
    content: "\e9d0";
}
.icon-email2:before {
    content: "\e9d1";
}
.icon-envelope2:before {
    content: "\e9d2";
}
.icon-envelope3:before {
    content: "\e9d3";
}
.icon-eraser:before {
    content: "\e9d4";
}
.icon-eye2:before {
    content: "\e9d5";
}
.icon-factory:before {
    content: "\e9d6";
}
.icon-fast-forward:before {
    content: "\e9d7";
}
.icon-favorites1:before {
    content: "\e9d8";
}
.icon-file:before {
    content: "\e9d9";
}
.icon-file-1:before {
    content: "\e9da";
}
.icon-file-2:before {
    content: "\e9db";
}
.icon-file-3:before {
    content: "\e9dc";
}
.icon-filter:before {
    content: "\e9dd";
}
.icon-finance-book:before {
    content: "\e9de";
}
.icon-flag1:before {
    content: "\e9df";
}
.icon-folder1:before {
    content: "\e9e0";
}
.icon-folder-1:before {
    content: "\e9e1";
}
.icon-folders:before {
    content: "\e9e2";
}
.icon-folders1:before {
    content: "\e9e3";
}
.icon-gamepad:before {
    content: "\e9e4";
}
.icon-gift1:before {
    content: "\e9e5";
}
.icon-growth:before {
    content: "\e9e6";
}
.icon-heart1:before {
    content: "\e9e7";
}
.icon-home2:before {
    content: "\e9e8";
}
.icon-house:before {
    content: "\e9e9";
}
.icon-house-1:before {
    content: "\e9ea";
}
.icon-house-2:before {
    content: "\e9eb";
}
.icon-id-card:before {
    content: "\e9ec";
}
.icon-id-card1:before {
    content: "\e9ed";
}
.icon-id-card-1:before {
    content: "\e9ee";
}
.icon-idea:before {
    content: "\e9ef";
}
.icon-image:before {
    content: "\e9f0";
}
.icon-improvement:before {
    content: "\e9f1";
}
.icon-inbox1:before {
    content: "\e9f2";
}
.icon-information:before {
    content: "\e9f3";
}
.icon-key:before {
    content: "\e9f4";
}
.icon-key1:before {
    content: "\e9f5";
}
.icon-laptop1:before {
    content: "\e9f6";
}
.icon-layers1:before {
    content: "\e9f7";
}
.icon-light-bulb1:before {
    content: "\e9f8";
}
.icon-like1:before {
    content: "\e9f9";
}
.icon-line-chart:before {
    content: "\e9fa";
}
.icon-loupe:before {
    content: "\e9fb";
}
.icon-mail:before {
    content: "\e9fc";
}
.icon-manager:before {
    content: "\e9fd";
}
.icon-map1:before {
    content: "\e9fe";
}
.icon-medal1:before {
    content: "\e9ff";
}
.icon-megaphone:before {
    content: "\ea00";
}
.icon-megaphone1:before {
    content: "\ea01";
}
.icon-message:before {
    content: "\ea02";
}
.icon-message-1:before {
    content: "\ea03";
}
.icon-message-2:before {
    content: "\ea04";
}
.icon-microphone:before {
    content: "\ea05";
}
.icon-money:before {
    content: "\ea06";
}
.icon-money-bag:before {
    content: "\ea07";
}
.icon-monitor1:before {
    content: "\ea08";
}
.icon-music1:before {
    content: "\ea09";
}
.icon-next:before {
    content: "\ea0a";
}
.icon-open-book:before {
    content: "\ea0b";
}
.icon-padlock:before {
    content: "\ea0c";
}
.icon-padlock-1:before {
    content: "\ea0d";
}
.icon-paint-brush:before {
    content: "\ea0e";
}
.icon-pause:before {
    content: "\ea0f";
}
.icon-pen:before {
    content: "\ea10";
}
.icon-pencil1:before {
    content: "\ea11";
}
.icon-percentage:before {
    content: "\ea12";
}
.icon-phone-call1:before {
    content: "\ea13";
}
.icon-phone-call2:before {
    content: "\ea14";
}
.icon-photo-camera1:before {
    content: "\ea15";
}
.icon-pie-chart3:before {
    content: "\ea16";
}
.icon-pipe:before {
    content: "\ea17";
}
.icon-placeholder1:before {
    content: "\ea18";
}
.icon-placeholder2:before {
    content: "\ea19";
}
.icon-planet-earth:before {
    content: "\ea1a";
}
.icon-play-button1:before {
    content: "\ea1b";
}
.icon-power-button:before {
    content: "\ea1c";
}
.icon-presentation:before {
    content: "\ea1d";
}
.icon-presentation1:before {
    content: "\ea1e";
}
.icon-printer1:before {
    content: "\ea1f";
}
.icon-push-pin:before {
    content: "\ea20";
}
.icon-push-pin1:before {
    content: "\ea21";
}
.icon-refresh:before {
    content: "\ea22";
}
.icon-reload:before {
    content: "\ea23";
}
.icon-return:before {
    content: "\ea24";
}
.icon-rocket-ship:before {
    content: "\ea25";
}
.icon-rss:before {
    content: "\ea26";
}
.icon-safebox:before {
    content: "\ea27";
}
.icon-safebox1:before {
    content: "\ea28";
}
.icon-search2:before {
    content: "\ea29";
}
.icon-search-1:before {
    content: "\ea2a";
}
.icon-settings1:before {
    content: "\ea2b";
}
.icon-settings-1:before {
    content: "\ea2c";
}
.icon-settings-2:before {
    content: "\ea2d";
}
.icon-sewing-machine:before {
    content: "\ea2e";
}
.icon-share:before {
    content: "\ea2f";
}
.icon-shield:before {
    content: "\ea30";
}
.icon-shield1:before {
    content: "\ea31";
}
.icon-shopping:before {
    content: "\ea32";
}
.icon-shopping-bag:before {
    content: "\ea33";
}
.icon-shopping-bag-1:before {
    content: "\ea34";
}
.icon-shopping-bag-2:before {
    content: "\ea35";
}
.icon-shopping-cart1:before {
    content: "\ea36";
}
.icon-shopping-cart2:before {
    content: "\ea37";
}
.icon-shopping-cart-1:before {
    content: "\ea38";
}
.icon-shopping-cart-2:before {
    content: "\ea39";
}
.icon-shopping-cart-3:before {
    content: "\ea3a";
}
.icon-smartphone2:before {
    content: "\ea3b";
}
.icon-speaker:before {
    content: "\ea3c";
}
.icon-speakers:before {
    content: "\ea3d";
}
.icon-stats:before {
    content: "\ea3e";
}
.icon-stats-1:before {
    content: "\ea3f";
}
.icon-stats-2:before {
    content: "\ea40";
}
.icon-stats-3:before {
    content: "\ea41";
}
.icon-stats-4:before {
    content: "\ea42";
}
.icon-stats-5:before {
    content: "\ea43";
}
.icon-stats-6:before {
    content: "\ea44";
}
.icon-sticky-note:before {
    content: "\ea45";
}
.icon-store1:before {
    content: "\ea46";
}
.icon-store-1:before {
    content: "\ea47";
}
.icon-suitcase:before {
    content: "\ea48";
}
.icon-suitcase-1:before {
    content: "\ea49";
}
.icon-tag2:before {
    content: "\ea4a";
}
.icon-target:before {
    content: "\ea4b";
}
.icon-team2:before {
    content: "\ea4c";
}
.icon-tie:before {
    content: "\ea4d";
}
.icon-trash1:before {
    content: "\ea4e";
}
.icon-trolley:before {
    content: "\ea4f";
}
.icon-trolley-1:before {
    content: "\ea50";
}
.icon-trolley-2:before {
    content: "\ea51";
}
.icon-trophy:before {
    content: "\ea52";
}
.icon-truck:before {
    content: "\ea53";
}
.icon-truck-1:before {
    content: "\ea54";
}
.icon-truck-2:before {
    content: "\ea55";
}
.icon-umbrella:before {
    content: "\ea56";
}
.icon-upload1:before {
    content: "\ea57";
}
.icon-user1:before {
    content: "\ea58";
}
.icon-user-1:before {
    content: "\ea59";
}
.icon-user-2:before {
    content: "\ea5a";
}
.icon-user-3:before {
    content: "\ea5b";
}
.icon-users2:before {
    content: "\ea5c";
}
.icon-video-camera:before {
    content: "\ea5d";
}
.icon-voucher:before {
    content: "\ea5e";
}
.icon-voucher-1:before {
    content: "\ea5f";
}
.icon-voucher-2:before {
    content: "\ea60";
}
.icon-voucher-3:before {
    content: "\ea61";
}
.icon-voucher-4:before {
    content: "\ea62";
}
.icon-wallet:before {
    content: "\ea63";
}
.icon-wallet1:before {
    content: "\ea64";
}
.icon-wifi:before {
    content: "\ea65";
}
.icon-worker:before {
    content: "\ea66";
}
.icon-zoom-in:before {
    content: "\ea67";
}
.icon-zoom-out:before {
    content: "\ea68";
}
.icon-squares:before {
    content: "\ea69";
}
.icon-options:before {
    content: "\ea6a";
}
.icon-apps:before {
    content: "\ea6b";
}
.icon-menu-1:before {
    content: "\ea6c";
}
.icon-menu1:before {
    content: "\ea6d";
}
.icon-arrow_down:before {
    content: "\ea6e";
}
.icon-back_up:before {
    content: "\ea6f";
}
.icon-cart1:before {
    content: "\ea70";
}
.icon-check:before {
    content: "\ea71";
}
.icon-checkmark:before {
    content: "\ea72";
}
.icon-dollar:before {
    content: "\ea73";
}
.icon-domian:before {
    content: "\ea74";
}
.icon-hosting:before {
    content: "\ea75";
}
.icon-key2:before {
    content: "\ea76";
}
.icon-migration:before {
    content: "\ea77";
}
.icon-play1:before {
    content: "\ea78";
}
.icon-quote2:before {
    content: "\ea79";
}
.icon-burger-menu:before {
    content: "\ea7a";
}
.icon-cloud:before {
    content: "\ea7b";
}
.icon-com:before {
    content: "\ea7c";
}
.icon-dns:before {
    content: "\ea7d";
}
.icon-email:before {
    content: "\ea7e";
}
.icon-ssl:before {
    content: "\ea7f";
}
.icon-support:before {
    content: "\ea80";
}
