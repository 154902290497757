@charset "UTF-8";

$pitsco-black: #211226;
$pitsco-slate-gray: #404454;
$pitsco-dark-cool-gray: #505567;
$pitsco-medium-gray: #606167;
$pitsco-medium-cool-gray: #606978;
$pitsco-light-gray: #E3E1E1;
$pitsco-off-white: #EEECEC;
$pitsco-white: #FCFAF9;
$all-white: #FFF;
$pitsco-blue-gray: #2E3648;
$pitsco-dark-gray: #1A1D2A;
$background-color: #EBE8E6;

/*Blue Shades*/
$pitsco-blue: #05559f;
$pitsco-normal-blue: #68a7c2;
$pitsco-darker-blue: #4F88AB;
$pitsco-active-blue: #44709F;
$pitsco-completed-blue-light: #36C6FF;
$pitsco-completed-blue: #08B0EB;
$pitsco-hover-blue: #99d2F1;
$pitsco-hover-blue-light: #b5e6ff;
$pitsco-blue-darker: #01366c;
$pitsco-blue-lighter: #0A6DDA;
$pitsco-blue-light-lti: #94c0d8;
$pitsco-baby-blue: #068FF2;

/*Accent Shades*/
$pitsco-red: #EF1E25;
$pitsco-orange: #F86620;

/*Text Shades*/
$text-black: #1F1F25;
$text-gray: #3F4F69;
$text-white: #FEFCFB;
$text-disabled: #c8c6c4;
$text-muted: #B8B6B5;
$text-muted-dark: #7D8DAD;
$text-muted-light: #CDCDD9;
$text-danger: #EF1E25;
$text-primary: #414653;

$color-primary: #6AA9C9;
$color-default: #3E4E5E;
$color-light: #DDE6EF;
$color-secondary: #F99909;
$color-success: #66C555;
$color-danger: #EF1E25;
$color-warning: #F86620;
$color-medium: #60636C;
$color-info: #7B8B8F;
$color-green: #2CCC4C;
$color-gold: #F2B242;
$color-black: #021222;

/*Font*/
$secondary-font: $bodyFont;
$condensed-font: $titleFont;
$base-font: $bodyFont;
$header-font: $titleFont;

/*Navigation*/
$navbar-bg-color: #fff;
$navbar-color: #123;
$navbar-color-disabled: rgba(200,200,200,0.75);
$navbar-li-border-color: rgba(165,172,185,0.45);

/*Modals*/
$default-modal-z-index: 1000;
$default-modal-container-width: 400px;
$default-modal-close-button-width: 25px;
$default-modal-close-button-height: 25px;

/* Pixel Values */
$p0: 0 !important;
$p1: 1px !important;
$p2: 2px !important;
$p3: 3px !important;
$p4: 4px !important;
$p5: 5px !important;
$p6: 6px !important;
$p7: 7px !important;
$p8: 8px !important;
$p9: 9px !important;
$p10: 10px !important;
$p15: 15px !important;
$p20: 20px !important;
$p25: 25px !important;
$p30: 30px !important;
$p35: 35px !important;
$p40: 40px !important;
$p45: 45px !important;
$p50: 50px !important;
$p60: 60px !important;
$p70: 70px !important;
$p80: 80px !important;
$p90: 90px !important;
$p100: 100px !important;
$p150: 150px !important;
$p200: 200px !important;
$p300: 300px !important;
