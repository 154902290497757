@import '../variable/variable';

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 8px;
    background-color: $color-gray;
}

::-webkit-scrollbar
{
    width: 12px;
    background-color: $color-gray;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.4);
    background-color: $color-primary-darken;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $color-primary-lighten;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:focus {
    background: $color-primary-lighten;
}
