
.btn_hover{
    overflow: hidden;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    font-family: $pop;
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover{
        color: #fff;
    }
}

//btn_get css
.btn_get{
    font: 500 14px/47px $pop;
    color: #6754e2;
    border: 2px solid rgb(204, 197, 250);
    border-radius: 4px;
    box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
    padding: 0px 23px;
    transform: perspective(1px) translateZ(0);
    transition-property: color;
    transition: all 0.3s ease;
    min-width: 120px;
    text-align: center;
    &:hover{
        color: #fff;
        background: #6754e2;
        border-color: #6754e2;
        box-shadow: none;
    }
}

.btn_get_two{
    box-shadow: none;
    background: #5e2ced;
    border-color: #5e2ced;
    color: #fff;
    &:hover{
        background: transparent;
        color: #5e2ced;
    }
}

.price_btn{
    font: 500 15px/53px $pop;
    color: #6754e2;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding: 0px 25px;
    display: inline-block;
    transition: background 0.2s linear;
    &:hover{
        color: #fff;
    }
}

.slider_btn{
    font: 500 14px/20px $pop;
    border-radius: 45px;
    padding: 15px 35px;
    background: #fff;
    &:hover{
        color: #fff;
    }
}

.btn_three{
    font: 500 14px $pop;
    color: #fff;
    background: #7444fd;
    border-radius: 3px;
    padding: 15px 30px;
    border:1px solid #7444fd;
    margin-top: 50px;
    transition: all 0.3s linear;
    cursor: pointer;
    display: inline-block;
    &:hover{
        color: #7444fd;
        background: transparent;
    }
}

.agency_banner_btn{
    font: 500 14px $pop;
    color: #fff;
    background: #5e2ced;
    box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
    border-radius: 3px;
    padding: 16px 38px;
    border:1px solid #5e2ced;
    transition: all 0.2s linear;
    cursor: pointer;
    &:hover{
        color: #5e2ced;
        box-shadow: none;
        background: transparent;
    }
}

.agency_banner_btn_two{
    font: 500 14px $pop;
    color: #222d39;
    position: relative;
    margin-left: 50px;
    transition: color 0.2s linear;
    &:before{
        content: "";
        width: 100%;
        height: 1px;
        background: #60656d;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: background 0.2s linear;
    }
    &:hover{
        color: #5e2ced;
        &:before{
            background: #5e2ced;
        }
    }
}


.software_banner_btn{
    font: 500 14px $pop;
    color: #fff;
    background: #3d64f4;
    box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
    display: inline-block;
    padding: 16px 42px;
    border-radius: 45px;
    border: 0px;
    transition: all 0.2s linear;
    &:hover{
        color: #3d64f4;
        background: #fff;
    }
}
.video_btn{
    font: 500 14px $pop;
    color: #fff;
    margin-left: 30px;
    .icon{
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: #fff;
        display: inline-block;
        color: #3d64f4;
        text-align: center;
        line-height: 46px;
        font-size: 18px;
        margin-right: 18px;
        vertical-align: middle;
        box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.19);

    }
    span{
        display: inline-block;
        position: relative;
        &:before{
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            background: #fff;
        }
    }
    &:hover{
        color: #fff;
    }
}

.btn_four{
    font: 500 14px $pop;
    padding: 16px 40px;
    border-radius: 45px;
    color: #fff;
    border: 1px solid #3d64f4;
    background: #3d64f4;
    cursor: pointer;
    &:hover{
        color: #3d64f4;
        background: transparent;
    }
}

.saas_banner_btn{
    padding: 16px 40px;
    background: #3d57f4;
    border-radius: 4px;
    color: #fff;
    &:hover{
        color: #3d57f4;
        background: #fff;
    }
}

.btn_five{
    border: 1px solid #fff;
    color: #fff;
    border-radius: 3px;
    padding: 12px 28px;
    &:hover{
        background: #3d57f4;
        border-color: #3d57f4;
    }
}

.app_btn{
    box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
    background-color: rgb(64, 105, 235);
    display: inline-block;
    padding: 14px 40px;
    color: #fff;
    border-radius: 45px;
    border: 1px solid rgb(64, 105, 235);
    &:hover{
        color: rgb(64, 105, 235);
        background: #fbfbfd;
        box-shadow: none;
    }
}


.gr_btn{
    font: 500 14px $pop;
    color: #23b1fe;
    display: inline-block;
    padding: 15px 31px;
    position: relative;
    min-width: 140px;
    border-radius: 4px;
    background-image: -moz-linear-gradient( -48deg, rgb(35,126,253) 0%, rgb(36,176,254) 46%, rgb(36,226,255) 100%);
    background-image: -webkit-linear-gradient( -48deg, rgb(35,126,253) 0%, rgb(36,176,254) 46%, rgb(36,226,255) 100%);
    background-image: -ms-linear-gradient( -48deg, rgb(35,126,253) 0%, rgb(36,176,254) 46%, rgb(36,226,255) 100%);
    z-index: 1;
    transition: color 0.2s linear;
    &:before{
        content: "";
        position: absolute;
        top: 1px;
        left: 50%;
        width: calc(100% - 2px);
        bottom: 1px;
        border-radius: 4px;
        background: #fff;
        z-index: 0;
        transition: opacity 0.2s linear;
        transform: translatex(-50%);
    }
    .text{
        position: relative;
        z-index: 1;
    }
    &:hover{
        color: #fff;
        &:before{
            opacity: 0;
        }
    }
}

.btn_six{
    font-size: 15px;
    border: 1px solid #17c7bd;
    padding: 13px 35px;
    transition: all 0.3s linear;
    display: inline-block;
    & + .btn_six{
        background: #ebfaf9;
        color: #17c7bd;
        margin-left: 20px;
        i{
            padding-right: 5px;
        }
        &:hover{
            background: #17c7bd;
            color: #fff;
        }
    }
}

.seo_btn{
    display: inline-block;
    padding: 11px 40px;
    border-radius: 4px;
}
.seo_btn_one{
    color: #5e2ced;
    background: #e8e1fc; 
    &:hover{
        background: #5e2ced;
        color: #fff;
    }
}
.seo_btn_two{
    color: #00c99c;
    background: #d4f6ee; 
    &:hover{
        background: #00c99c;
        color: #fff;
    }
}